import { ApolloQueryResult, gql } from '@apollo/client'
import { Box, Flex, Heading, TextLink } from '@weareberyl/design-system'
import { Card } from 'antd'

import Query from 'components/Query'
import { addMinutes, parseISO, subMinutes } from 'date-fns'
import {
  capitalize,
  formatDatetime,
  getLogsLink,
  isDockedScheme,
} from '../../utils'
import { End } from './actions'

import CardSection from 'components/shared/CardSection'
import { JourneyRewards } from './Rewards'

import { ParkingStatus } from '__generated__/globalTypes'
import {
  JOURNEY_SUMMARY,
  JOURNEY_SUMMARYVariables,
} from './__generated__/JOURNEY_SUMMARY'

const logsLink = (
  journeyId: string,
  moduleId: string,
  startedAt: string,
  completedAt: string,
) => {
  // Calculate the start and end filter times with 3 mins extra each side, in case clocks are a bit skewed
  const filterStart = subMinutes(parseISO(startedAt), 3)
  const today = new Date()
  const filterEnd = completedAt ? addMinutes(parseISO(completedAt), 3) : today
  return getLogsLink(
    `(@journey_id:${journeyId} OR @module_id:${moduleId} OR @variables.moduleId:${moduleId})`,
    filterStart,
    filterEnd,
  )
}

const PARKING_DESCRIPTION = {
  [ParkingStatus.valid]: 'In bay',
  [ParkingStatus.out_of_zone]: 'Out of bay',
  [ParkingStatus.out_of_service_area]: 'Out of service area',
  [ParkingStatus.vehicle_not_permitted]: "Bay doesn't allow vehicle",
  [ParkingStatus.zone_max_capacity]: 'Bay at max capacity',
}

const JourneySummary = ({
  data: { journey },
}: ApolloQueryResult<JOURNEY_SUMMARY>) => {
  if (!journey) {
    return null
  }
  const hasDistance = journey.distance_in_km && journey.distance_in_mi
  const hasSpeed = journey.speed_in_km && journey.speed_in_mi
  const dockedScheme = journey.scheme && isDockedScheme(journey.scheme.id)
  const hasVehicleDocked = dockedScheme && journey?.vehicle_docked !== null
  const hasDocksAvailable = dockedScheme && journey?.docks_available !== null
  const historyReady = journey.state.message === 'history_ready'
  const parkingStatus = journey.parking_status
    ? PARKING_DESCRIPTION[journey.parking_status] ?? 'Not implemented'
    : 'Unknown'
  const vehicleLockState = capitalize(journey.vehicle_lock_state) ?? 'Unknown'

  const primaryCardItems = [
    { key: 'State', value: capitalize(journey.state.message) },
  ]
  if (journey.duration !== null) {
    primaryCardItems.push({
      key: 'Duration',
      value: `${journey.duration} mins`,
    })
  }
  if (historyReady) {
    primaryCardItems.push(
      {
        key: 'Parking',
        value: parkingStatus,
      },
      { key: 'Lock state', value: vehicleLockState },
    )
  }
  if (journey.end_in_zone) {
    if (hasVehicleDocked) {
      primaryCardItems.push({
        key: 'Ended docked',
        value: journey.vehicle_docked ? 'Yes' : 'No',
      })
    }
    if (hasDocksAvailable) {
      primaryCardItems.push({
        key: 'Docks available in bay',
        value: journey.docks_available?.toString(),
      })
    }
  }

  const secondaryCardItems = [
    { key: 'Last state change', value: formatDatetime(journey.state.time) },
  ]
  if (hasDistance) {
    secondaryCardItems.push({
      key: 'Distance',
      value: `${journey.distance_in_km} km / ${journey.distance_in_mi} mi`,
    })
  }
  if (hasSpeed) {
    secondaryCardItems.push({
      key: 'Speed',
      value: `${journey.speed_in_km} km/h / ${journey.speed_in_mi} mph`,
    })
  }
  secondaryCardItems.push({
    key: 'Initiated by',
    value: journey.initiator ?? 'app',
  })

  // Assist config only exists on ModuleState type in the union, not ScooterModuleState
  const assistConfig =
    journey.module?.module_state?.__typename === 'ModuleState'
      ? journey.module?.module_state?.assist_config
      : null

  const hardwareType = journey.module.hardware_type

  return (
    <Card>
      <Flex width="100%" mb={5}>
        <Box flex="1" mr={5}>
          <Heading variant="h1">Journey {journey.id}</Heading>
        </Box>
        <Box flex="1" maxWidth={160}>
          {!journey.is_complete && journey.scheme && (
            <Box mb={4}>
              <End
                id={journey.id}
                moduleId={journey.module.id}
                currentAssistConfig={assistConfig}
                hardwareType={hardwareType}
                schemeId={journey.scheme.id}
                onHold={journey.state.message === 'on_hold'}
              />
            </Box>
          )}
          <Flex justifyContent="flex-end">
            <TextLink
              variant="gray"
              onPress={() =>
                window.open(
                  logsLink(
                    journey.id,
                    journey.module.id,
                    journey.started_at,
                    journey.completed_at,
                  ),
                  '_blank',
                )
              }
            >
              Server logs
            </TextLink>
          </Flex>
        </Box>
      </Flex>
      <Flex justifyContent="space-between" width="100%">
        <CardSection mr={5} items={primaryCardItems} />
        <CardSection items={secondaryCardItems} />
      </Flex>
      {journey.scheme?.config.is_vehicle_rewards_enabled &&
        journey.possible_rewards.length > 0 && (
          <JourneyRewards
            journeyErrored={journey.is_error}
            journeyHistoryReady={historyReady}
            possibleRewards={journey.possible_rewards}
            confirmedRewards={journey.confirmed_rewards}
          />
        )}
    </Card>
  )
}

const QUERY = gql`
  query JOURNEY_SUMMARY($id: ID!) {
    journey(journey_id: $id) {
      id
      module {
        id
        hardware_type
        module_state {
          id
          ... on ModuleState {
            assist_config
          }
        }
      }
      scheme {
        id
        config {
          is_vehicle_rewards_enabled
        }
      }
      started_at
      completed_at
      state {
        id
        time
        message
      }
      distance_in_km: distance(unit: kilometre)
      distance_in_mi: distance(unit: mile)
      speed_in_km: speed(unit: kilometre)
      speed_in_mi: speed(unit: mile)
      is_complete
      is_error
      end_in_zone
      duration
      initiator
      vehicle_docked
      docks_available
      parking_status
      possible_rewards {
        type
      }
      confirmed_rewards {
        type
      }
      vehicle_lock_state
    }
  }
`

type Props = {
  pollInterval?: number
} & JOURNEY_SUMMARYVariables

export default ({ id, pollInterval }: Props) => (
  <Query
    waitFor="data.journey"
    query={QUERY}
    variables={{ id }}
    pollInterval={pollInterval}
  >
    {JourneySummary}
  </Query>
)
