import { useState, useMemo, useCallback } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Alert, Modal, Switch, Input, Select, message } from 'antd'
import { sumBy } from 'lodash'
import {
  Card,
  Flex,
  Box,
  Button,
  Heading,
  Text,
  TextIcon,
  Icon,
} from '@weareberyl/design-system'
import Sentry from 'utils/sentry'

import {
  InvoiceDocument,
  useRefundInvoiceMutation,
  InvoiceQuery_invoice as Invoice,
} from 'gql/generated/graphql'
import { TransactionType } from '__generated__/globalTypes'
import { useScheduleRefresh } from 'hooks/useScheduleRefresh'

import AdjustTransaction from './AdjustTransaction'
import { useValidators } from '../hooks'

export const journeyDescriptionChoices = [
  'Front lock issue',
  'Locking issue',
  'GPS Parking issue',
  'Unlock failure',
  'Fault with the vehicle',
  'Scooter cut out',
  'Pause journey issue',
  'User involved in an accident',
  'Complaint',
  'Other',
].map(choice => ({ name: choice, key: choice }))

const productDescriptionChoices = [
  'Complaint',
  'Unintentional product purchase',
  'Other',
].map(choice => ({ name: choice, key: choice }))

export const generateResultMessage = (
  status: string,
  text: string,
  extraMessage: string | null,
) => {
  let messageText = `${text} | ${status}`
  if (extraMessage) {
    messageText = `${text}: ${extraMessage} | ${status}`
  }
  switch (status) {
    case 'succeeded':
      message.info(messageText, 10)
      return
    case 'failed':
      message.error(messageText, 10)
      return
    default:
      return
  }
}

const totalByTransactionType = (
  type: TransactionType,
  transactions: Record<string, { amount: number; type: TransactionType }>,
) =>
  sumBy(
    Object.values(transactions).filter(i => i.type === type),
    'amount',
  )

const CurrencyWarning = () => (
  <Box alignSelf="flex-start" mb={3} mx={2}>
    <TextIcon
      icon={
        <Icon color="grape" height={14} type="information" width={14} mt={1} />
      }
    >
      <Text variant="small" color="grape">
        Currency refunds will take a short time to display on the invoice as we
        need to receive the confirmation from payment platform
      </Text>
    </TextIcon>
  </Box>
)

type Props = {
  invoice: Invoice
  idsToRefund: string[]
  onComplete: () => void
}

const Refund = ({ invoice, onComplete, idsToRefund }: Props) => {
  const { transactions } = invoice
  const transactionsToRefund = useMemo(() => {
    return (transactions?.nodes ?? []).filter(({ id }) =>
      idsToRefund.includes(id),
    )
  }, [transactions, idsToRefund])

  const [visible, setVisible] = useState(false)
  const [showingConfirmation, setShowingConfirmation] = useState(false)

  const [revokeProduct, setRevokeProduct] = useState(true)
  const [description, setDescription] = useState<string | 'Please select'>(
    'Please select',
  )
  const [customDescription, setCustomDescription] = useState<string>('')
  // State to store the amount to be refunded for each transaction
  // Initial values is the full amount for each selected transaction
  const [refundTransactions, setRefundTransactions] = useState<
    Record<string, { amount: number; type: TransactionType }>
  >({})

  const scheduleRefresh = useScheduleRefresh([InvoiceDocument])

  const [submitRefund, { loading }] = useRefundInvoiceMutation({
    refetchQueries: [InvoiceDocument],
    onCompleted: result => {
      message.info('Refund request successful', 10)
      if (!result.refund_invoice_v2) {
        return
      }
      if (result.refund_invoice_v2.currency?.status) {
        generateResultMessage(
          result.refund_invoice_v2.currency.status,
          'Refund currency',
          result.refund_invoice_v2.currency.message ?? null,
        )
        if (result.refund_invoice_v2.currency.status === 'succeeded') {
          // Currency refund settling transaction takes a bit of
          // time to come through as it's added by the webhook from Stripe.
          // So schedule an update in a couple of seconds
          scheduleRefresh()
        }
      }
      if (result.refund_invoice_v2.minutes?.status) {
        generateResultMessage(
          result.refund_invoice_v2.minutes.status,
          'Refund minutes',
          result.refund_invoice_v2.minutes.message ?? null,
        )
      }

      onComplete()
    },
    onError: err => {
      Sentry.captureException(err)
      onComplete()
      message.error('Something went wrong', 10)
    },
  })

  const hideModal = () => {
    setVisible(false)
    setShowingConfirmation(false)
    onComplete()
  }
  const totalCurrencyToRefund = useMemo(
    () =>
      totalByTransactionType(
        TransactionType.currency_transaction,
        refundTransactions,
      ),
    [refundTransactions],
  )
  const totalMinutesToRefund = useMemo(
    () =>
      totalByTransactionType(
        TransactionType.minute_transaction,
        refundTransactions,
      ),
    [refundTransactions],
  )

  const isProductInvoice = invoice.__typename === 'ProductInvoice'
  let maxCurrencyRefundable = invoice.currency_refundable?.amount ?? 0

  if (isProductInvoice && invoice.minute_refund_calcs) {
    // Override max currency refundable if this is a minute bundle partial refund.
    // If some minutes have been used, we can only refund what hasn't been used yet
    maxCurrencyRefundable = Math.min(
      maxCurrencyRefundable,
      invoice.minute_refund_calcs.currency_amount.amount,
    )
  }

  // Product can only be revoked if all the refundable currency is being refunded
  const canRevoke =
    isProductInvoice && totalCurrencyToRefund === maxCurrencyRefundable

  const maxMinutesRefundable =
    invoice.__typename === 'JourneyInvoice'
      ? invoice.minute_refundable?.amount ?? 0
      : 0

  const descriptionChoices = isProductInvoice
    ? productDescriptionChoices
    : journeyDescriptionChoices

  const descriptionValue =
    description.toLowerCase() === 'other' ? customDescription : description

  const buttonAction = () => {
    if (showingConfirmation) {
      submitRefund({
        variables: {
          id: invoice.id,
          description: descriptionValue,
          reason: 'requested_by_customer',
          revoke_product: revokeProduct && canRevoke,
          refund_transactions: Object.entries(
            refundTransactions,
          ).map(([id, { amount }]) => ({ amount, id })),
        },
      })
    } else {
      setShowingConfirmation(true)
    }
  }

  // Update value for this transaction ID to be refunded
  // In AdjustTransaction this will be a dep of a useEffect, so thats why it's a useCallback
  const setTransactionAmount = useCallback(
    (transactionId: string, amount: number, type: TransactionType) => {
      setRefundTransactions(current => ({
        ...current,
        [transactionId]: { amount, type },
      }))
    },
    [],
  )

  const { isValid, errorMessage } = useValidators(
    [
      () =>
        descriptionValue === 'Please select' && 'Please select a description',
      () => descriptionValue.length === 0 && 'Please enter a description',
      () =>
        totalCurrencyToRefund === 0 &&
        totalMinutesToRefund === 0 &&
        'Nothing to refund',
      () =>
        totalCurrencyToRefund > maxCurrencyRefundable &&
        'Maximum currency refund exceeded',
      () =>
        totalMinutesToRefund > maxMinutesRefundable &&
        'Maximum minute refund exceeded',
    ],
    [descriptionValue, refundTransactions],
  )

  return (
    <>
      <Button
        testID="refund-btn"
        title="Refund"
        variant="primary"
        mt={2}
        mr={3}
        onPress={() => setVisible(true)}
        disabled={idsToRefund.length === 0}
      />
      <Modal
        open={visible}
        zIndex={1050}
        footer={null}
        onCancel={hideModal}
        bodyStyle={{ padding: 0 }}
        width={800}
      >
        <Form onSubmit={buttonAction} title="Refund form">
          <Box py={4} px={5} zIndex={2}>
            <Heading variant="callout" pt={2} pb={4}>
              {isProductInvoice
                ? 'Refund product invoice'
                : 'Refund journey invoice'}
            </Heading>

            {isProductInvoice && invoice.minute_refund_calcs && (
              <p>
                {invoice.minute_refund_calcs.minute_adjustment_quantity} minutes
                have been used, so the remaining balance can be refunded for{' '}
                {invoice.minute_refund_calcs.currency_amount.formatted_amount}
              </p>
            )}

            {transactionsToRefund.map(transaction => (
              <AdjustTransaction
                transaction={transaction}
                currency={invoice.scheme_product?.scheme_accounting.currency}
                key={transaction.id}
                onChange={setTransactionAmount}
              />
            ))}
            {isProductInvoice && (
              <Box p={3} pl={5} my={2}>
                <Box mb={2}>
                  <Heading>Product purchase</Heading>
                </Box>

                <Flex alignItems="center" style={{ gap: '10px' }}>
                  <label htmlFor="revoke-product" style={{ width: '150px' }}>
                    <Text>Revoke Product</Text>
                  </label>
                  <Switch
                    onChange={setRevokeProduct}
                    checked={revokeProduct && canRevoke}
                    disabled={!canRevoke}
                    title="Revoke product"
                  />
                  {!canRevoke && (
                    <Text variant="mini">
                      Refund full amount to revoke the product
                    </Text>
                  )}
                </Flex>
              </Box>
            )}

            <Box p={3} pl={5} my={2} style={{ gap: '10px' }}>
              <Box mb={1}>
                <label htmlFor="refund-description" style={{ width: '150px' }}>
                  <Heading>Description</Heading>
                </label>
              </Box>
              <Select
                id="refund-description"
                style={{ flex: 1 }}
                value={description}
                size="large"
                onChange={(selected: string) => {
                  setDescription(selected)
                }}
                showSearch
                placeholder="Please select"
              >
                {descriptionChoices.map(item => (
                  <Select.Option value={item.key} key={item.key}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              {description?.toLowerCase() === 'other' && (
                <Input
                  size="large"
                  value={customDescription ?? ''}
                  onChange={event => setCustomDescription(event.target.value)}
                  placeholder="Please enter a custom description"
                  title="Custom description"
                />
              )}
            </Box>
            {errorMessage && (
              <Alert
                message="Oops"
                description={errorMessage}
                type="error"
                showIcon
              />
            )}
          </Box>
          <Card
            p={5}
            borderTopLeftRadius={0}
            borderTopRightRadius={0}
            variant="gray"
            testID="refund-invoice-confirmation"
          >
            {totalCurrencyToRefund > 0 && <CurrencyWarning />}
            {showingConfirmation ? (
              <Flex mr={2}>
                <Flex
                  width="50%"
                  justifyContent="flex-start"
                  alignContent="center"
                >
                  <Text>Are you sure?</Text>
                </Flex>
                <Box width="50%">
                  <Button
                    title="Confirm"
                    onPress={buttonAction}
                    disabled={!isValid || loading}
                    loading={loading}
                    type="submit"
                  />
                </Box>
              </Flex>
            ) : (
              <Button
                testID="refund-invoice-confirmation-btn"
                title="Refund"
                onPress={buttonAction}
                disabled={!isValid}
                loading={loading}
                type="submit"
              />
            )}
          </Card>
        </Form>
      </Modal>
    </>
  )
}

export default Refund
