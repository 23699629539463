import { useState } from 'react'
import { ApolloError, gql, useMutation } from '@apollo/client'

import type { Awaited } from 'utils/types'
import {
  SUSPEND_ZONE,
  SUSPEND_ZONEVariables,
} from 'components/Zone/__generated__/SUSPEND_ZONE'
import {
  ENABLE_ZONE,
  ENABLE_ZONEVariables,
} from 'components/Zone/__generated__/ENABLE_ZONE'
import {
  SET_ZONE_PROMOTIONAL,
  SET_ZONE_PROMOTIONALVariables,
} from './__generated__/SET_ZONE_PROMOTIONAL'

const SUSPEND_ZONE_QUERY = gql`
  mutation SUSPEND_ZONE($id: ID!) {
    updated_zone: suspend_zone(zone_id: $id) {
      id
    }
  }
`

const ENABLE_ZONE_QUERY = gql`
  mutation ENABLE_ZONE($id: ID!) {
    updated_zone: enable_zone(zone_id: $id) {
      id
    }
  }
`

const SET_ZONE_PROMOTIONAL_QUERY = gql`
  mutation SET_ZONE_PROMOTIONAL($id: ID!, $is_promotional: Boolean!) {
    updated_zone: set_zone_promotional(
      zone_id: $id
      is_promotional: $is_promotional
    ) {
      id
    }
  }
`

export type ZoneSetPromotionalMutationProps = {
  variables?: SET_ZONE_PROMOTIONALVariables
  onCompleted?: (data: SET_ZONE_PROMOTIONAL) => void
  onError?: (error: ApolloError) => void
  refetchQueries?: string[]
}

export type ZoneStatusMutationProps = {
  variables?: SUSPEND_ZONEVariables | ENABLE_ZONEVariables
  onCompleted?: (data: SUSPEND_ZONE | ENABLE_ZONE) => void
  onError?: (error: ApolloError) => void
  refetchQueries?: string[]
}

export const useSuspendZoneMutation = ({
  variables,
  onCompleted,
  onError,
  refetchQueries,
}: ZoneStatusMutationProps) => {
  return useMutation<SUSPEND_ZONE, SUSPEND_ZONEVariables>(SUSPEND_ZONE_QUERY, {
    variables,
    onCompleted,
    onError,
    refetchQueries,
  })
}

export const useEnableZoneMutation = ({
  variables,
  onCompleted,
  onError,
  refetchQueries,
}: ZoneStatusMutationProps) => {
  return useMutation<ENABLE_ZONE, ENABLE_ZONEVariables>(ENABLE_ZONE_QUERY, {
    variables,
    onCompleted,
    onError,
    refetchQueries,
  })
}

export const useSetZonePromotionalMutation = ({
  variables,
  onCompleted,
  onError,
  refetchQueries,
}: ZoneSetPromotionalMutationProps) => {
  return useMutation<SET_ZONE_PROMOTIONAL, SET_ZONE_PROMOTIONALVariables>(
    SET_ZONE_PROMOTIONAL_QUERY,
    {
      variables,
      onCompleted,
      onError,
      refetchQueries,
    },
  )
}

type useBulkZoneStatusMutationProps = {
  suspend: boolean
  zoneIds: string[]
  onCompleted: (failedIds: string[]) => void
  onError: (err: any) => void
}

export function useBulkZoneStatusMutation({
  suspend,
  zoneIds,
  onCompleted,
  onError,
}: useBulkZoneStatusMutationProps): [() => void, boolean] {
  const [loading, setLoading] = useState(false)

  const [enableZone] = useEnableZoneMutation({ onError })
  const [suspendZone] = useSuspendZoneMutation({ onError })

  const updateFunction = async () => {
    if (zoneIds.length !== 0) {
      let failedZoneIds: string[] = []
      setLoading(true)

      const updateZoneStatus = suspend ? enableZone : suspendZone

      zoneIds.forEach(async (id, index) => {
        const isLastZone = index + 1 === zoneIds.length

        const refetchQueries = isLastZone ? ['ZONES_LIST'] : undefined

        let result:
          | Awaited<ReturnType<typeof enableZone>>
          | Awaited<ReturnType<typeof suspendZone>>
        try {
          result = await updateZoneStatus({ variables: { id }, refetchQueries })
        } finally {
          // Whether it worked or not, set loading false when it gets to the last zone
          if (isLastZone) {
            setLoading(false)
            onCompleted(failedZoneIds)
          }
        }
        if (!result || result?.errors) {
          failedZoneIds = [...failedZoneIds, id]
        }
      })
    }
  }

  return [updateFunction, loading]
}
