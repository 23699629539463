import { ApolloQueryResult } from '@apollo/client'
import { message } from 'antd'
import { Link } from 'react-router-dom'

import Query from 'components/Query'
import { capitalize, formatDatetime } from 'utils'
import { isPhone } from 'utils/mobileDetect'
import { withScheme } from 'components/Scheme'
import { hasPermission, withUser } from 'components/Session'
import Table from 'components/Table'
import { useQueryParams } from 'utils/useQueryParams'
import UserLink from '../../UserLink'
import * as routes from 'constants/routes'
import { End } from '../actions'
import ModuleLink from 'components/ModuleLink'
import { getModuleTitle } from 'components/ModuleHeader'
import { SchemeId } from '../../../constants/domain'

import {
  JOURNEY_LIST_table_nodes,
  JOURNEY_LIST_table_nodes_state,
} from './__generated__/JOURNEY_LIST'
import {
  JourneyListDocument,
  JourneyListQuery,
  JourneyListQuery_table_JourneyConnection_nodes_Journey,
} from 'gql/generated/graphql'
import { ColumnType } from 'antd/lib/table'

const hasActiveJourney = (msg: JOURNEY_LIST_table_nodes_state['message']) =>
  ![
    'ended',
    'ended_failed',
    'ended_success',
    'history_ready',
    'charge_ready',
    'docked',
  ].includes(msg)

const getColumns = ({ module_id: moduleId, is_mobile, scheme_id, omit_user }) =>
  [
    {
      title: 'Journey ID',
      key: 'id',
      render: ({
        id,
        state: { message: msg },
        scheme,
      }: JOURNEY_LIST_table_nodes) => {
        // Prepend the scheme in which this journey took place
        const journeyPath = `${routes.JOURNEYS}/${id}`
        const link = scheme
          ? routes.prependScheme(journeyPath, scheme.id)
          : journeyPath
        return (
          <Link
            style={{
              color: hasActiveJourney(msg) ? '#ffb600' : undefined,
              fontSize: '18px',
            }}
            to={link}
          >
            {id}
          </Link>
        )
      },
    },
    !is_mobile && {
      title: 'Last Action',
      dataIndex: ['state', 'time'],
      className: 'xs-hide',
      render: time => formatDatetime(time),
    },
    !scheme_id && {
      title: 'Scheme',
      dataIndex: ['scheme', 'name'],
    },
    !moduleId && {
      title: 'Module ID',
      dataIndex: ['module', 'id'],
      render: (module_id: string, { module: { bike } }) => (
        <ModuleLink id={module_id} bikeScheme={bike?.scheme} />
      ),
    },
    !moduleId && {
      title: 'Frame',
      render: ({ module }: JOURNEY_LIST_table_nodes) =>
        getModuleTitle(module?.bike?.frame?.id, module?.hardware_type),
    },
    !omit_user && {
      title: 'User',
      className: 'xs-hide',
      dataIndex: 'user',
      render: user => <UserLink user={user} fallback="Not Found" />,
    },
    {
      title: 'Status',
      dataIndex: ['state', 'message'],
      render: m => <span>{message && capitalize(m)}</span>,
    },
    !is_mobile && {
      title: 'Actions',
      className: 'xs-hide',
      render: ({
        id,
        is_complete,
        module,
        scheme,
      }: JOURNEY_LIST_table_nodes) => {
        const moduleState =
          module?.module_state?.__typename === 'ModuleState'
            ? module.module_state
            : null
        const assistConfig = moduleState?.assist_config ?? null

        return !is_complete && scheme ? (
          <End
            id={id}
            moduleId={module.id}
            currentAssistConfig={assistConfig}
            hardwareType={module.hardware_type}
            schemeId={scheme.id}
          />
        ) : null
      },
    },
  ].filter(c => c)

export const shouldOmitUser = (user, scheme, is_mobile) =>
  !hasPermission(user.roles, 'user.view', parseInt(scheme.id)) || is_mobile

export default withScheme(
  withUser(({ user: ui_user, user_id, module_id, scheme }) => {
    // If a scheme is specified then query journeys for this scheme only.
    // If not, query all journeys as we're in Beryl Admin mode
    const scheme_id = scheme ? scheme.id : null
    const where = scheme_id
      ? []
      : [{ scheme: { id: { ne: SchemeId.tfl_smartbike } } }]
    const id = 'journeys-table'
    const [{ current, pageSize }, setQueryParams] = useQueryParams(id)
    const is_mobile = isPhone()
    const omit_user = shouldOmitUser(ui_user, scheme, is_mobile)

    return (
      <Query
        variables={{
          user_id,
          module_id,
          scheme_id,
          is_mobile,
          omit_user,
          where,
          paginate: {
            page: current,
            per_page: pageSize,
          },
        }}
        waitFor={'data.table.nodes'}
        query={JourneyListDocument}
      >
        {(props: ApolloQueryResult<JourneyListQuery>) => (
          <Table
            onChange={({ current, pageSize }) =>
              setQueryParams({ current, pageSize })
            }
            id={id}
            columns={
              getColumns({
                module_id,
                is_mobile,
                scheme_id,
                omit_user,
              }) as ColumnType<JourneyListQuery_table_JourneyConnection_nodes_Journey>[]
            }
            {...props}
          />
        )}
      </Query>
    )
  }),
)
