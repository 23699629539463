import React from 'react'

import { Box, Icon } from '@weareberyl/design-system'

const BooleanIcon = ({
  value,
  margin = 'auto 0',
}: {
  value: boolean
  margin?: typeof Box['margin']
}): JSX.Element => (
  <Box
    bg={value ? 'mint' : 'prawn'}
    width={20}
    height={20}
    borderRadius={10}
    justifyContent="center"
    alignItems="center"
    margin={margin}
  >
    <Icon
      type={value ? 'tick' : 'cross'}
      color={value ? 'jade' : 'brick'}
      width={10}
      height={10}
    />
  </Box>
)

export default BooleanIcon
