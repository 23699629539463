import { useState } from 'react'
import { WarningOutlined } from '@ant-design/icons'

import { Button } from '@weareberyl/design-system'

import { Center } from 'components/Layout'

type Props = {
  loginUrl: string
}

export default function ServiceNowAuthError(props: Props) {
  const [isServiceNowButtonPressed, setIsServiceNowButtonPressed] = useState(
    false,
  )

  if (isServiceNowButtonPressed) {
    return (
      <Center className="text-center">
        <WarningOutlined />
        <p>Please finish ServiceNow login and reload this page</p>
      </Center>
    )
  }
  return (
    <Center className="text-center">
      <WarningOutlined />
      <p>You need to authenticate ServiceNow</p>
      <Button
        variant="danger"
        // href={loginUrl}
        title="Set up ServiceNow with CORE"
        onClick={() => {
          setIsServiceNowButtonPressed(true)
          window.open(props.loginUrl, '_blank')
        }}
      />
    </Center>
  )
}
