import { useEffect, useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { useMutation, gql } from '@apollo/client'
import {
  Flex,
  Box,
  Card,
  Heading,
  TextLink,
  Button,
} from '@weareberyl/design-system'
import { Modal } from 'antd'
import { LoadingComponent, ErrorComponent } from 'components/Query/utils'
import Diff from 'components/Zone/Sync/Diff'
import SyncTabs, { SyncTab } from 'components/shared/SyncTabs'
import SuspendedStatus from 'components/Zone/SuspendedStatus'
import * as routes from 'constants/routes'
import {
  VehicleIcon,
  getPositionLinkFromCoordinates,
  isEmptySyncData,
} from 'utils'

import { ZONES_SYNC } from './__generated__/ZONES_SYNC'
import ZoneTypeStatus from '../ZoneTypeStatus'
import { SchemeId } from 'constants/domain'

const ZONES_SYNC_QUERY = gql`
  mutation ZONES_SYNC($scheme_id: ID!, $dry_run: Boolean) {
    sync_zones(scheme_id: $scheme_id, dry_run: $dry_run) {
      added {
        capacity
        geom
        centre
        permitted_vehicles
        zone_type
        ... on DraftZone {
          name
        }
      }
      updated {
        id
        name
        capacity
        geom
        centre
        permitted_vehicles
        zone_type
      }
      deleted {
        capacity
        geom
        centre
        permitted_vehicles
        zone_type
        ... on Zone {
          id
          name
          is_suspended
        }
      }
    }
  }
`

const columns = [
  {
    title: 'Type',
    dataIndex: 'zone_type',
    render: zoneType => <ZoneTypeStatus zoneType={zoneType} />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Capacity',
    dataIndex: 'capacity',
  },
  {
    title: 'Location',
    dataIndex: ['centre', 'coordinates'],
    render: getPositionLinkFromCoordinates,
  },
  {
    title: 'Permitted vehicles',
    dataIndex: 'permitted_vehicles',
    render: permitted_vehicles => (
      <Flex>
        {permitted_vehicles.map(vehicle => (
          <VehicleIcon key={vehicle} vehicle={vehicle} size={18} />
        ))}
      </Flex>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'is_suspended',
    render: isSuspended => (
      <SuspendedStatus isSuspended={isSuspended ?? true} />
    ),
  },
]

type Props = RouteComponentProps & { scheme_id: SchemeId }
const ZoneSync = ({ scheme_id, history }: Props) => {
  const [syncBays, { data, loading, error }] = useMutation<ZONES_SYNC>(
    ZONES_SYNC_QUERY,
  )

  const [modelVisibility, setModalVisibility] = useState(false)
  const [selectedZone, setSelectedZone] = useState({})

  useEffect(() => {
    syncBays({
      variables: { scheme_id, dry_run: true },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <div>
      <Modal
        visible={modelVisibility}
        footer={null}
        onCancel={() => setModalVisibility(false)}
        width="800px"
      >
        <Heading variant="h1">Differences</Heading>
        <Box mt={4}>
          <Diff columns={columns} zone={selectedZone} />
        </Box>
      </Modal>

      <Card p={5}>
        <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
          <Heading variant="callout">Sync Bays</Heading>
          {!isEmptySyncData(data?.sync_zones ?? null) && (
            <Flex>
              <Button
                title="Reject all"
                onPress={async () => {
                  history.push(`/scheme/${scheme_id}${routes.ZONES}`)
                }}
                variant="outline"
                mr={3}
                width="140px"
              />
              <Button
                title="Accept all"
                onPress={async () => {
                  await syncBays({
                    variables: { scheme_id, dry_run: false },
                  })

                  history.goBack()
                }}
                variant="primary"
                width="140px"
              />
            </Flex>
          )}
        </Flex>
        <SyncTabs
          id="zones-sync"
          data={data?.sync_zones ?? null}
          columns={tab =>
            tab === SyncTab.updated
              ? [
                  ...columns.slice(0, -1),
                  {
                    render: zone => {
                      return (
                        <TextLink
                          variant="gray"
                          onPress={() => {
                            setSelectedZone(zone)
                            setModalVisibility(true)
                          }}
                        >
                          See differences
                        </TextLink>
                      )
                    },
                  },
                ]
              : columns
          }
        />
      </Card>
    </div>
  )
}

export default withRouter(ZoneSync)
