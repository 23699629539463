import { Flex, Box, Icon } from '@weareberyl/design-system'
import { Typography } from 'antd'

const icons = {
  burger: {
    type: 'menu',
    color: 'deepPurple',
    bg: 'oyster',
  },
  cross: {
    type: 'cross',
    color: 'brick',
    bg: 'prawn',
  },
  repeat: {
    type: 'repeat',
    color: 'lake',
    bg: 'shell',
  },
  tick: {
    type: 'tick',
    color: 'pickle',
    bg: 'mint',
  },
  warning: {
    type: 'warning',
    color: 'jaffa',
    bg: 'wheat',
  },
  bikeJourney: {
    type: 'bikeJourney',
    color: 'corporate',
    bg: 'shell',
  },
  user: {
    type: 'user',
    color: 'pickle',
    bg: 'mint',
  },
  spanner: {
    type: 'spanner',
    color: 'grape',
    bg: 'turmeric',
  },
  parking: {
    type: 'parking',
    color: 'grape',
    bg: 'berylGreen',
  },
  menu: {
    type: 'menu',
    color: 'tofu',
    bg: 'corporate',
  },
  scheme: {
    type: 'scheme',
    color: 'tofu',
    bg: 'berylGreen',
  },
}

type TabTitleProps = {
  tabText: string
  icon: keyof typeof icons
}

const TabTitle = ({ tabText, icon }: TabTitleProps) => {
  return (
    <Flex flexWrap="nowrap">
      <Box
        borderRadius="100%"
        alignItems="center"
        bg={icons[icon].bg}
        p={1}
        height={24}
        width={24}
      >
        <Icon
          color={icons[icon].color}
          mt="2px"
          type={icons[icon].type}
          width={12}
          height={12}
        />
      </Box>

      <Box mb={1} ml={2} flex={1}>
        <Typography.Text ellipsis strong style={{ fontSize: '1.2em' }}>
          {tabText}
        </Typography.Text>
      </Box>
    </Flex>
  )
}

export default TabTitle
