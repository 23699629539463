import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import Table from 'components/Table'
import { Heading } from '@weareberyl/design-system'
import {
  LoadingComponent,
  ErrorComponent,
  EmptyComponent,
} from 'components/Query/utils'
import { compareAndShortenGeoms } from 'utils'

import { ZONE_DIFF } from './__generated__/ZONE_DIFF'

const ZONE_DIFF_QUERY = gql`
  query ZONE_DIFF($id: ID!) {
    zone(zone_id: $id) {
      id
      name
      centre
      capacity
      geom
      centre
      is_suspended
      permitted_vehicles
    }
  }
`

export default ({ zone, columns }) => {
  const [[oldGeom, newGeom], setGeom] = useState(['-', '-'])

  const { loading, error, data } = useQuery<ZONE_DIFF>(ZONE_DIFF_QUERY, {
    variables: { id: zone.id },
    pollInterval: 0,
    onCompleted: () => {
      setGeom(compareAndShortenGeoms(data?.zone?.geom, zone?.geom))
    },
  })

  const cols = [
    {
      title: '',
      dataIndex: 'diffType',
      render: diffType => <Heading variant="h4">{diffType}</Heading>,
    },
    ...columns,
    {
      title: 'Geometry',
      dataIndex: 'geom',
    },
  ]

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (loading) {
    return <LoadingComponent />
  }

  if (!data) {
    return <EmptyComponent />
  }

  return (
    <Table
      id="zones-sync-table-diff"
      columns={cols}
      hideProgress
      data={{
        table: {
          pagination: false,
          nodes: [
            {
              ...zone,
              is_suspended: data.zone?.is_suspended,
              geom: newGeom,
              diffType: 'New',
            },
            { ...data.zone, id: 'old', geom: oldGeom, diffType: 'Old' },
          ],
        },
      }}
    />
  )
}
