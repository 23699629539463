/* eslint-disable */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: any; output: any }
  EmailAddress: { input: any; output: any }
  GUID: { input: any; output: any }
  JSON: { input: any; output: any }
}

export type ArchiveJob = {
  __typename: 'ArchiveJob'
  details: ArchiveJobDetails
  sys_id: Scalars['ID']['output']
  timestamps: JobTimestamps
  uuid: Scalars['ID']['output']
}

export type ArchiveJobConnection = {
  __typename: 'ArchiveJobConnection'
  nodes: Array<ArchiveJob>
  pagination: Pagination
}

export type ArchiveJobDetails = {
  __typename: 'ArchiveJobDetails'
  assignee?: Maybe<User>
  isJustified: Scalars['Boolean']['output']
  notes?: Maybe<Scalars['String']['output']>
  scheme?: Maybe<Scheme>
  status: JobStatusEnum
  taskId: Scalars['ID']['output']
}

export type Asset = {
  __typename: 'Asset'
  asset_tag?: Maybe<Scalars['String']['output']>
  child_assets?: Maybe<Array<Maybe<ChildAsset>>>
  model_frame_type?: Maybe<Scalars['String']['output']>
  model_name: Scalars['String']['output']
  model_sys_id: Scalars['ID']['output']
  scheme_id?: Maybe<Scalars['String']['output']>
  serial_number: Scalars['String']['output']
}

export enum AssetCategory {
  bay = 'bay',
  bike = 'bike',
  dock = 'dock',
  ebike = 'ebike',
  scooter = 'scooter',
  unknown = 'unknown',
}

export type AssetInput = {
  asset_tag: Scalars['String']['input']
  model: Scalars['ID']['input']
  name: Scalars['String']['input']
  serial_number: Scalars['String']['input']
}

export type AssetModel = {
  __typename: 'AssetModel'
  child_assets?: Maybe<Array<Maybe<BaseAssetModel>>>
  model_category: Scalars['String']['output']
  name: Scalars['String']['output']
  sys_id: Scalars['ID']['output']
  type: AssetType
}

export enum AssetType {
  furniture = 'furniture',
  none = 'none',
  vehicle = 'vehicle',
}

export enum AssistConfig {
  assist_config_disable = 'ASSIST_CONFIG_DISABLE',
  assist_config_normal = 'ASSIST_CONFIG_NORMAL',
  assist_config_override = 'ASSIST_CONFIG_OVERRIDE',
}

export enum AssistMode {
  assist_eco = 'ASSIST_ECO',
  assist_none = 'ASSIST_NONE',
  assist_sport = 'ASSIST_SPORT',
  assist_tour = 'ASSIST_TOUR',
  assist_turbo = 'ASSIST_TURBO',
}

export type Attempt = {
  __typename: 'Attempt'
  assignee: User
  created_at: Scalars['DateTime']['output']
  reportedLocation?: Maybe<Scalars['String']['output']>
}

export type AttemptInput = {
  assignee: Scalars['String']['input']
  reportedLocation?: InputMaybe<Scalars['String']['input']>
}

export type Automation = {
  __typename: 'Automation'
  description: Scalars['String']['output']
  jobs: Array<Maybe<AutomationJob>>
  justification_id: Scalars['String']['output']
  side_effects: Array<Maybe<Scalars['String']['output']>>
  taskId: Scalars['String']['output']
}

export type AutomationFilters = {
  justification_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  prefix?: InputMaybe<Scalars['String']['input']>
}

export type AutomationJob = {
  __typename: 'AutomationJob'
  created_at?: Maybe<Scalars['DateTime']['output']>
  frame_id: Scalars['String']['output']
  scheme_id: Scalars['Int']['output']
  uuid: Scalars['ID']['output']
}

export type Availability = {
  __typename: 'Availability'
  created_at?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  status: AvailabilityStatus
}

export type AvailabilityConnection = {
  __typename: 'AvailabilityConnection'
  nodes: Array<Availability>
  pagination: Pagination
}

/** Different availability states of the device */
export enum AvailabilityStatus {
  /** Available */
  available = 'available',
  /** Unavailabile */
  unavailable = 'unavailable',
}

export type BaseAssetModel = {
  __typename: 'BaseAssetModel'
  model_category: Scalars['String']['output']
  name: Scalars['String']['output']
  sys_id: Scalars['ID']['output']
  type: AssetType
}

export enum BatteryGroup {
  a200_p = 'A200P',
  bbe1 = 'BBE1',
  bbe2 = 'BBE2',
  ecargo = 'ECARGO',
  es400_a = 'ES400A',
  es400_b = 'ES400B',
  es410 = 'ES410',
}

export type Bike = {
  __typename: 'Bike'
  frame: Frame
  lock_configuration: LockConfiguration
  module?: Maybe<Module>
  scheme: Scheme
  vehicle_id: Scalars['ID']['output']
  vehicle_state_history?: Maybe<VehicleStateEventConnection>
}

export type Bikevehicle_state_historyArgs = {
  paginate?: InputMaybe<Paginate>
}

export type BikeConnection = {
  __typename: 'BikeConnection'
  nodes: Array<Bike>
  pagination: Pagination
}

export type BikeWhere = {
  frame?: InputMaybe<RemoteNodeOp>
}

export type BooleanOp = {
  eq?: InputMaybe<Scalars['Boolean']['input']>
  is_not_null?: InputMaybe<Scalars['Boolean']['input']>
  is_null?: InputMaybe<Scalars['Boolean']['input']>
  ne?: InputMaybe<Scalars['Boolean']['input']>
}

export enum CantDeleteCode {
  journey_in_progress = 'journey_in_progress',
  outstanding_balance = 'outstanding_balance',
  user_already_deleted = 'user_already_deleted',
  user_not_found = 'user_not_found',
}

export type Card = {
  __typename: 'Card'
  /** @deprecated Use brand_enum for better typing */
  brand: Scalars['String']['output']
  brand_enum: CardBrandType
  country?: Maybe<Scalars['String']['output']>
  exp_month: Scalars['Int']['output']
  exp_year: Scalars['Int']['output']
  funding?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  last4: Scalars['String']['output']
  type: CardType
}

export enum CardBrandType {
  amex = 'amex',
  diners = 'diners',
  diners_club = 'diners_club',
  discover = 'discover',
  jcb = 'jcb',
  mastercard = 'mastercard',
  unionpay = 'unionpay',
  unknown = 'unknown',
  visa = 'visa',
}

export enum CardType {
  apple_pay = 'apple_pay',
  card = 'card',
  google_pay = 'google_pay',
}

export type ChargeExemption = {
  __typename: 'ChargeExemption'
  added_by_id: Scalars['ID']['output']
  charge_type: ChargeType
  created_at?: Maybe<Scalars['DateTime']['output']>
  scheme_id: Scalars['ID']['output']
  user_id: Scalars['ID']['output']
}

export enum ChargeType {
  cancel_journey_penalty = 'cancel_journey_penalty',
  minute_refund = 'minute_refund',
  minute_topup = 'minute_topup',
  out_of_service_area_charge = 'out_of_service_area_charge',
  out_of_zone_charge = 'out_of_zone_charge',
  partially_locked_in_zone_parking_charge = 'partially_locked_in_zone_parking_charge',
  product_purchase = 'product_purchase',
  tax = 'tax',
  time_based_charge = 'time_based_charge',
  time_based_minute_charge = 'time_based_minute_charge',
  time_based_overflow_charge = 'time_based_overflow_charge',
  time_based_period_pass_charge = 'time_based_period_pass_charge',
  unlock_fee = 'unlock_fee',
  vehicle_not_permitted_in_zone_charge = 'vehicle_not_permitted_in_zone_charge',
  zone_max_capacity_charge = 'zone_max_capacity_charge',
}

export type ChildAsset = {
  __typename: 'ChildAsset'
  model_name: Scalars['String']['output']
  model_sys_id: Scalars['ID']['output']
  serial_number?: Maybe<Scalars['String']['output']>
}

export type ChildJob = {
  __typename: 'ChildJob'
  assetCategory: AssetCategory
  assetName: Scalars['String']['output']
  assetTag: Scalars['String']['output']
  uuid?: Maybe<Scalars['ID']['output']>
  vehicle?: Maybe<Vehicle>
}

export type ConnectivityWhere = {
  id?: InputMaybe<ScalarOp>
  is_online?: InputMaybe<BooleanOp>
}

export enum CurrencyPaymentProviderType {
  stripe_customer = 'stripe_customer',
  swift_customer = 'swift_customer',
  trafi_customer = 'trafi_customer',
}

export type CurrencyTransaction = ITransaction & {
  __typename: 'CurrencyTransaction'
  amount_refundable?: Maybe<FormattedAmount>
  amount_refunded?: Maybe<FormattedAmount>
  category: TransactionCategory
  created_at: Scalars['DateTime']['output']
  credit_amount: FormattedAmount
  debit_amount: FormattedAmount
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  meta?: Maybe<TransactionMeta>
  provider_type?: Maybe<CurrencyPaymentProviderType>
  refund_requester?: Maybe<User>
  result?: Maybe<TransactionResult>
  type?: Maybe<TransactionType>
  void: Scalars['Boolean']['output']
}

export type DeletePermission = {
  __typename: 'DeletePermission'
  can_delete: Scalars['Boolean']['output']
  /** This will be null if deletion is permitted */
  code?: Maybe<CantDeleteCode>
}

export type DeletedZone = IZone & {
  __typename: 'DeletedZone'
  capacity: Scalars['Int']['output']
  centre: Scalars['JSON']['output']
  external_id: Scalars['ID']['output']
  geom: Scalars['JSON']['output']
  hiring_capacity_status: HiringCapacityStatus
  is_at_max_overflow_capacity: Scalars['Boolean']['output']
  is_external: Scalars['Boolean']['output']
  is_promotional: Scalars['Boolean']['output']
  is_suspended: Scalars['Boolean']['output']
  notes?: Maybe<Scalars['String']['output']>
  parking_capacity_status: ParkingCapacityStatus
  permitted_vehicles: Array<VehicleType>
  zone_type: ZoneType
}

export type Deployment = {
  __typename: 'Deployment'
  created_at?: Maybe<Scalars['DateTime']['output']>
  /** Returns all events for deployment */
  event_history?: Maybe<DeploymentEventConnection>
  id: Scalars['ID']['output']
  /** Returns latest events distint by module */
  modules?: Maybe<DeploymentEventConnection>
  version?: Maybe<Firmware>
}

export type Deploymentevent_historyArgs = {
  paginate?: InputMaybe<Paginate>
}

export type DeploymentmodulesArgs = {
  paginate?: InputMaybe<Paginate>
}

export type DeploymentConnection = {
  __typename: 'DeploymentConnection'
  nodes: Array<Maybe<Deployment>>
  pagination?: Maybe<Pagination>
}

export type DeploymentEvent = {
  __typename: 'DeploymentEvent'
  created_at?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  module?: Maybe<Module>
  state?: Maybe<DeploymentEventState>
}

export type DeploymentEventConnection = {
  __typename: 'DeploymentEventConnection'
  nodes: Array<Maybe<DeploymentEvent>>
  pagination?: Maybe<Pagination>
}

export enum DeploymentEventState {
  downloading = 'downloading',
  installed = 'installed',
  installing = 'installing',
  pending = 'pending',
  starting = 'starting',
}

export enum Direction {
  asc = 'asc',
  desc = 'desc',
}

export type DiscountCalculations = {
  __typename: 'DiscountCalculations'
  discount: FormattedAmount
  subtotal: FormattedAmount
  total: FormattedAmount
  voucher: Voucher
}

export enum DistanceUnit {
  kilometre = 'kilometre',
  metre = 'metre',
  mile = 'mile',
}

export type DraftServiceArea = IServiceArea & {
  __typename: 'DraftServiceArea'
  applies_to: Array<VehicleType>
  effective_from?: Maybe<Scalars['String']['output']>
  effective_to?: Maybe<Scalars['String']['output']>
  external_id?: Maybe<Scalars['ID']['output']>
  geom: Scalars['JSON']['output']
  is_external?: Maybe<Scalars['Boolean']['output']>
  is_parking_allowed: Scalars['Boolean']['output']
  name?: Maybe<Scalars['String']['output']>
  scheme: Scheme
  service_area_type: ServiceAreaType
  speed_limit: Scalars['Float']['output']
}

export type DraftZone = IZone & {
  __typename: 'DraftZone'
  all_commissioned_modules_count: Scalars['Int']['output']
  all_modules_count: Scalars['Int']['output']
  capacity: Scalars['Int']['output']
  centre: Scalars['JSON']['output']
  external_id: Scalars['ID']['output']
  geom: Scalars['JSON']['output']
  /** @deprecated Use Zone.hireable_vehicles instead */
  hireable_modules: Array<Maybe<Module>>
  /** @deprecated Use Zone.hireable_vehicles_count instead */
  hireable_modules_count: Scalars['Int']['output']
  hireable_vehicles: Array<Maybe<Vehicle>>
  hireable_vehicles_count: Scalars['Int']['output']
  hiring_capacity_status: HiringCapacityStatus
  is_at_max_overflow_capacity: Scalars['Boolean']['output']
  is_external?: Maybe<Scalars['Boolean']['output']>
  is_promotional: Scalars['Boolean']['output']
  /** @deprecated Use Zone.hireable_modules_count directly instead */
  locked_modules?: Maybe<ModuleConnection>
  modules?: Maybe<ModuleConnection>
  name: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  parking_capacity_status: ParkingCapacityStatus
  permitted_vehicles: Array<VehicleType>
  promotion?: Maybe<Promotion>
  related_modules?: Maybe<Array<Maybe<Module>>>
  scheme: Scheme
  zone_type: ZoneType
}

export type DraftZoneall_commissioned_modules_countArgs = {
  hardware_type?: InputMaybe<ModuleHardware>
}

export type DraftZoneall_modules_countArgs = {
  hardware_type?: InputMaybe<ModuleHardware>
}

export type DraftZonehireable_modulesArgs = {
  hardware_type?: InputMaybe<ModuleHardware>
}

export type DraftZonehireable_modules_countArgs = {
  hardware_type?: InputMaybe<ModuleHardware>
}

export type DraftZonehireable_vehiclesArgs = {
  propulsion_type?: InputMaybe<VehiclePropulsionType>
  vehicle_type?: InputMaybe<VehicleType>
}

export type DraftZonehireable_vehicles_countArgs = {
  propulsion_type?: InputMaybe<VehiclePropulsionType>
  vehicle_type?: InputMaybe<VehicleType>
}

export type DraftZonelocked_modulesArgs = {
  paginate?: InputMaybe<Paginate>
}

export type DraftZonemodulesArgs = {
  paginate?: InputMaybe<Paginate>
}

export type DraftZonepromotionArgs = {
  user_id?: InputMaybe<Scalars['ID']['input']>
}

export type Drive = {
  __typename: 'Drive'
  battery_health?: Maybe<Scalars['Int']['output']>
  battery_percent?: Maybe<Scalars['Int']['output']>
  battery_voltage?: Maybe<Scalars['Float']['output']>
  estimated_range?: Maybe<Scalars['Int']['output']>
  estimated_range_minutes?: Maybe<Scalars['Int']['output']>
}

export type Driveestimated_rangeArgs = {
  unit?: InputMaybe<DistanceUnit>
}

export type DrivingLicenceCheck = {
  __typename: 'DrivingLicenceCheck'
  id: Scalars['ID']['output']
}

export type EcuError = {
  __typename: 'EcuError'
  cause?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  protection?: Maybe<Scalars['String']['output']>
  solution?: Maybe<Scalars['String']['output']>
}

export type EmailLinkInput = {
  android_install_app?: InputMaybe<Scalars['Boolean']['input']>
  android_package_name?: InputMaybe<Scalars['String']['input']>
  dynamic_link_domain?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  handle_code_in_app?: InputMaybe<Scalars['Boolean']['input']>
  ios_bundle_id?: InputMaybe<Scalars['String']['input']>
  url: Scalars['String']['input']
}

export type ExistingOrWhitelist = {
  __typename: 'ExistingOrWhitelist'
  scheme?: Maybe<Scheme>
  status: Scalars['Boolean']['output']
}

export type Firmware = {
  __typename: 'Firmware'
  created_at: Scalars['DateTime']['output']
  formatted_size: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  size: Scalars['Int']['output']
  supported_hardware?: Maybe<Array<Maybe<ModuleHardwareType>>>
}

export enum FirmwareType {
  ecu = 'ECU',
  iot = 'IOT',
  meter = 'METER',
}

export type FormattedAmount = {
  __typename: 'FormattedAmount'
  amount: Scalars['Int']['output']
  formatted_amount: Scalars['String']['output']
  unit: Scalars['String']['output']
}

export type Frame = {
  __typename: 'Frame'
  id: Scalars['ID']['output']
  module?: Maybe<Module>
}

export type FramemoduleArgs = {
  scheme_id: Scalars['ID']['input']
}

export enum FrameType {
  beryl_bike = 'beryl_bike',
  beryl_e_bike = 'beryl_e_bike',
  beryl_e_bike_2 = 'beryl_e_bike_2',
  okai_scooter = 'okai_scooter',
  pashley_tfl = 'pashley_tfl',
  pashley_westmidlands_bike = 'pashley_westmidlands_bike',
  pashley_westmidlands_e_bike = 'pashley_westmidlands_e_bike',
  raleigh_cargo_e_bike = 'raleigh_cargo_e_bike',
  segway_e_bike = 'segway_e_bike',
}

export enum HardwareModel {
  a200_p = 'A200P',
  es400 = 'ES400',
  es400_gen2 = 'ES400GEN2',
  es410 = 'ES410',
  es600_gen1 = 'ES600GEN1',
  es600_gen2 = 'ES600GEN2',
}

export enum HiringCapacityStatus {
  available_bikes = 'available_bikes',
  limited_bikes = 'limited_bikes',
  no_bikes = 'no_bikes',
}

export type IInvoice = {
  created_at: Scalars['DateTime']['output']
  currency_balance?: Maybe<FormattedAmount>
  currency_debit?: Maybe<FormattedAmount>
  currency_debit_sub_total?: Maybe<FormattedAmount>
  currency_refundable?: Maybe<FormattedAmount>
  /** Human readable description of the purchase */
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  is_void: Scalars['Boolean']['output']
  minute_debit?: Maybe<FormattedAmount>
  minute_refundable?: Maybe<FormattedAmount>
  next_action?: Maybe<Array<Maybe<PaymentIntent>>>
  scheme_product?: Maybe<SchemeProduct>
  status?: Maybe<InvoiceStatus>
  timezone_offset?: Maybe<Scalars['Float']['output']>
  transactions?: Maybe<TransactionConnection>
  user?: Maybe<User>
}

export type IInvoiceConnection = {
  __typename: 'IInvoiceConnection'
  nodes: Array<IInvoice>
  pagination: Pagination
}

export type IInvoiceOrderby = {
  created_at?: InputMaybe<Direction>
  description?: InputMaybe<Direction>
  id?: InputMaybe<Direction>
  type?: InputMaybe<Direction>
}

export type IInvoiceWhere = {
  amount?: InputMaybe<ScalarOp>
  description?: InputMaybe<ScalarOp>
  id?: InputMaybe<ScalarOp>
  status?: InputMaybe<ScalarOp>
  type?: InputMaybe<ScalarOp>
  user?: InputMaybe<RemoteNodeOp>
}

export type IModuleState = {
  id: Scalars['ID']['output']
  time: Scalars['DateTime']['output']
}

export type IServiceArea = {
  applies_to: Array<VehicleType>
  effective_from?: Maybe<Scalars['String']['output']>
  effective_to?: Maybe<Scalars['String']['output']>
  external_id?: Maybe<Scalars['ID']['output']>
  geom: Scalars['JSON']['output']
  is_external?: Maybe<Scalars['Boolean']['output']>
  is_parking_allowed: Scalars['Boolean']['output']
  name?: Maybe<Scalars['String']['output']>
  scheme: Scheme
  service_area_type: ServiceAreaType
  speed_limit: Scalars['Float']['output']
}

export type ITransaction = {
  amount_refundable?: Maybe<FormattedAmount>
  amount_refunded?: Maybe<FormattedAmount>
  category: TransactionCategory
  created_at: Scalars['DateTime']['output']
  credit_amount: FormattedAmount
  debit_amount: FormattedAmount
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  meta?: Maybe<TransactionMeta>
  provider_type?: Maybe<CurrencyPaymentProviderType>
  refund_requester?: Maybe<User>
  result?: Maybe<TransactionResult>
  type?: Maybe<TransactionType>
  void: Scalars['Boolean']['output']
}

export type IZone = {
  capacity: Scalars['Int']['output']
  centre: Scalars['JSON']['output']
  external_id: Scalars['ID']['output']
  geom: Scalars['JSON']['output']
  hiring_capacity_status: HiringCapacityStatus
  is_at_max_overflow_capacity: Scalars['Boolean']['output']
  is_external?: Maybe<Scalars['Boolean']['output']>
  is_promotional: Scalars['Boolean']['output']
  notes?: Maybe<Scalars['String']['output']>
  parking_capacity_status: ParkingCapacityStatus
  permitted_vehicles: Array<VehicleType>
  zone_type: ZoneType
}

export type Incident = {
  __typename: 'Incident'
  description: Scalars['String']['output']
  number: Scalars['String']['output']
  priority: Scalars['Int']['output']
  service_offering: Scalars['String']['output']
  state: Scalars['Int']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type IncidentInput = {
  description: Scalars['String']['input']
  service_offering: Scalars['String']['input']
  title: Scalars['String']['input']
  urgency: Scalars['Int']['input']
  user_id: Scalars['ID']['input']
}

export enum InvoiceStatus {
  fully_refunded = 'fully_refunded',
  outstanding = 'outstanding',
  partially_refunded = 'partially_refunded',
  pending = 'pending',
  settled = 'settled',
  void = 'void',
}

export type IotState = {
  __typename: 'IotState'
  battery_id?: Maybe<Scalars['String']['output']>
  /** To differentiate between beryl bikes and tfl smartbikes using the board hardware type */
  board_hw_type?: Maybe<ModuleHardwareType>
  commit_hash?: Maybe<Scalars['String']['output']>
  debug_mode?: Maybe<Scalars['Boolean']['output']>
  dock_nfc_tag_id?: Maybe<Scalars['String']['output']>
  drive_id?: Maybe<Scalars['String']['output']>
  firmware_version?: Maybe<Scalars['String']['output']>
  hardware_version?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imei?: Maybe<Scalars['String']['output']>
  imsi?: Maybe<Scalars['String']['output']>
  installed_deployment_id?: Maybe<Scalars['Int']['output']>
  mac_address?: Maybe<Scalars['String']['output']>
  pending_firmware_url?: Maybe<Scalars['String']['output']>
  power_cycles?: Maybe<Scalars['Int']['output']>
  power_mode?: Maybe<Scalars['String']['output']>
  status_accelerometer?: Maybe<Scalars['Int']['output']>
  status_battery_voltage?: Maybe<Scalars['Boolean']['output']>
  status_charging_current?: Maybe<Scalars['Boolean']['output']>
  status_front_light?: Maybe<Scalars['Int']['output']>
  status_generated_power?: Maybe<Scalars['Boolean']['output']>
  status_gnss_module?: Maybe<Scalars['String']['output']>
  status_lock?: Maybe<Scalars['Int']['output']>
  status_rear_light?: Maybe<Scalars['Int']['output']>
  status_supercap_voltage?: Maybe<Scalars['Boolean']['output']>
  status_temp_sensor?: Maybe<Scalars['Int']['output']>
  time: Scalars['DateTime']['output']
  watchdog_resets?: Maybe<Scalars['Int']['output']>
}

export type IotStateWhere = {
  board_hw_type?: InputMaybe<ScalarOp>
  firmware_version?: InputMaybe<ScalarOp>
  hardware_version?: InputMaybe<ScalarOp>
  id?: InputMaybe<ScalarOp>
  power_mode?: InputMaybe<ScalarOp>
}

export type Job = {
  __typename: 'Job'
  attempts?: Maybe<Array<Attempt>>
  /** @deprecated Use Vehicle instead */
  bike?: Maybe<Bike>
  childJobs?: Maybe<Array<ChildJob>>
  details: JobDetails
  justifications?: Maybe<Array<Justification>>
  tasks?: Maybe<Array<Task>>
  timestamps?: Maybe<JobTimestamps>
  uuid: Scalars['ID']['output']
  vandalism_event_uuid?: Maybe<Scalars['String']['output']>
  vehicle?: Maybe<Vehicle>
}

export type JobAsset = {
  __typename: 'JobAsset'
  assetCategory: AssetCategory
  assetName: Scalars['String']['output']
  assetTag: Scalars['String']['output']
  externalId: Scalars['String']['output']
}

export enum JobAutomationSideEffect {
  set_unavailable = 'set_unavailable',
}

export type JobAutomationVehicleInput = {
  frame_id: Scalars['String']['input']
  scheme_id: Scalars['Int']['input']
}

export type JobConnection = {
  __typename: 'JobConnection'
  nodes: Array<Job>
  pagination: Pagination
}

export type JobDetails = {
  __typename: 'JobDetails'
  assetCategory?: Maybe<AssetCategory>
  assetName?: Maybe<Scalars['String']['output']>
  assetTag?: Maybe<Scalars['String']['output']>
  assignee?: Maybe<User>
  batteryGroup?: Maybe<BatteryGroup>
  /** @deprecated use assetTag */
  bikeNumber?: Maybe<Scalars['String']['output']>
  createdBy?: Maybe<User>
  description?: Maybe<Scalars['String']['output']>
  isJustified?: Maybe<Scalars['Boolean']['output']>
  isUnlocked?: Maybe<Scalars['Boolean']['output']>
  location?: Maybe<Scalars['String']['output']>
  locationDescription?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  numberOfAttempts?: Maybe<Scalars['Int']['output']>
  priority?: Maybe<Scalars['String']['output']>
  reportedLocation?: Maybe<Scalars['String']['output']>
  role?: Maybe<Scalars['String']['output']>
  scheme?: Maybe<Scheme>
  /** @deprecated Use field `scheme` instead. Remove when tasking client is updated. */
  scheme_id?: Maybe<Scalars['String']['output']>
  status?: Maybe<JobStatusEnum>
  taskId: Scalars['ID']['output']
  updatedBy?: Maybe<User>
  /** @deprecated use assetCategory */
  vehicleType?: Maybe<VehicleType>
  vehicle_id?: Maybe<Scalars['ID']['output']>
}

export type JobDetailsInput = {
  assetTag?: InputMaybe<Scalars['String']['input']>
  assets?: InputMaybe<Scalars['String']['input']>
  assignee?: InputMaybe<Scalars['String']['input']>
  bikeNumber?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  is_unlocked?: InputMaybe<Scalars['Boolean']['input']>
  locationDescription?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  priority?: InputMaybe<Scalars['String']['input']>
  reportedLocation?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Scalars['String']['input']>
  scheme_id?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<JobStatusEnum>
  taskId?: InputMaybe<Scalars['ID']['input']>
}

export type JobFiltersInput = {
  assetCategory?: InputMaybe<Array<InputMaybe<AssetCategory>>>
  assetNameContains?: InputMaybe<Scalars['String']['input']>
  assetTag?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  assignee?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  batteryGroup?: InputMaybe<Array<InputMaybe<BatteryGroup>>>
  bikeNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  is_unlocked?: InputMaybe<Scalars['Boolean']['input']>
  role?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  scheme_id?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  status?: InputMaybe<Array<InputMaybe<JobStatusEnum>>>
  taskId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  vehicleType?: InputMaybe<Array<InputMaybe<VehicleType>>>
}

export type JobInput = {
  assetTag?: InputMaybe<Scalars['String']['input']>
  assignee?: InputMaybe<Scalars['String']['input']>
  bikeNumber?: InputMaybe<Scalars['String']['input']>
  locationDescription?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  priority?: InputMaybe<Scalars['String']['input']>
  reportedLocation?: InputMaybe<Scalars['String']['input']>
  scheme_id?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<JobStatusEnum>
  taskId?: InputMaybe<Scalars['String']['input']>
}

export enum JobSortField {
  is_unlocked = 'is_unlocked',
  updated_at = 'updated_at',
}

export enum JobStatusEnum {
  blocked = 'blocked',
  complete = 'complete',
  in_progress = 'inProgress',
  new = 'new',
}

export type JobTaskInput = {
  details?: InputMaybe<TaskDetailsInput>
  uuid?: InputMaybe<Scalars['ID']['input']>
}

export type JobTimestamps = {
  __typename: 'JobTimestamps'
  created_at: Scalars['DateTime']['output']
  deleted_at?: Maybe<Scalars['DateTime']['output']>
  last_attempted_at?: Maybe<Scalars['DateTime']['output']>
  updated_at: Scalars['DateTime']['output']
}

export type JobTypes = {
  __typename: 'JobTypes'
  onStreetMechanic: JobTypesAssignmentGroup
  redistributionDriver: JobTypesAssignmentGroup
  securityTeam: JobTypesAssignmentGroup
  workshopMechanic: JobTypesAssignmentGroup
}

export type JobTypesAssignmentGroup = {
  __typename: 'JobTypesAssignmentGroup'
  children: Array<JobTypesChildren>
  label: Scalars['String']['output']
}

export type JobTypesChildren = {
  __typename: 'JobTypesChildren'
  code: Scalars['String']['output']
  disabled?: Maybe<Scalars['Boolean']['output']>
  group?: Maybe<JobTypesGroup>
  label: Scalars['String']['output']
  name: Scalars['String']['output']
}

export enum JobTypesGroup {
  legacy_jobs = 'LEGACY_JOBS',
  tracking_assets_jobs = 'TRACKING_ASSETS_JOBS',
}

export type JobUpdateInput = {
  details?: InputMaybe<JobDetailsInput>
  tasks?: InputMaybe<Array<JobTaskInput>>
}

export type Journey = {
  __typename: 'Journey'
  co2_saved?: Maybe<Scalars['Int']['output']>
  completed_at?: Maybe<Scalars['DateTime']['output']>
  confirmed_rewards: Array<VehicleReward>
  /** Human readable description of the journey */
  description?: Maybe<Scalars['String']['output']>
  distance?: Maybe<Scalars['Float']['output']>
  /** How many docks were available at the end of journey zone */
  docks_available?: Maybe<Scalars['Int']['output']>
  /** Journey duration in minutes */
  duration?: Maybe<Scalars['Int']['output']>
  /** Did the journey end in the service area? */
  end_in_service_area?: Maybe<Scalars['Boolean']['output']>
  end_in_zone?: Maybe<Scalars['Boolean']['output']>
  /** Is the final endpoint known? */
  final_location_known?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  /** Channel which journey was started with */
  initiator?: Maybe<JourneyInitiator>
  /** The billing info for the journey */
  invoice?: Maybe<JourneyInvoice>
  /** If the journey has been cancelled by us */
  is_cancelled: Scalars['Boolean']['output']
  is_complete: Scalars['Boolean']['output']
  is_error: Scalars['Boolean']['output']
  /** If the journey has been voided by us */
  is_voided: Scalars['Boolean']['output']
  module: Module
  parking_status?: Maybe<ParkingStatus>
  possible_rewards: Array<VehicleReward>
  route?: Maybe<Scalars['JSON']['output']>
  route_V2?: Maybe<Scalars['JSON']['output']>
  scheme?: Maybe<Scheme>
  speed?: Maybe<Scalars['Float']['output']>
  started_at?: Maybe<Scalars['DateTime']['output']>
  state: JourneyEvent
  state_history: JourneyEventConnection
  timezone_offset?: Maybe<Scalars['Float']['output']>
  user?: Maybe<User>
  user_trip_id: Scalars['GUID']['output']
  vehicle: Vehicle
  /** Vehicle docked at end of journey */
  vehicle_docked?: Maybe<Scalars['Boolean']['output']>
  /** The lock state of the vehicle at the end of journey */
  vehicle_lock_state?: Maybe<VehicleLockState>
}

export type JourneydistanceArgs = {
  unit?: InputMaybe<DistanceUnit>
}

export type JourneyspeedArgs = {
  unit?: InputMaybe<DistanceUnit>
}

export type Journeystate_historyArgs = {
  paginate?: InputMaybe<Paginate>
}

export type JourneyConnection = {
  __typename: 'JourneyConnection'
  nodes: Array<Journey>
  pagination: Pagination
}

export type JourneyEvent = {
  __typename: 'JourneyEvent'
  duration?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  message: Scalars['String']['output']
  time: Scalars['DateTime']['output']
  trigger?: Maybe<Scalars['String']['output']>
}

export type JourneyEventConnection = {
  __typename: 'JourneyEventConnection'
  nodes: Array<JourneyEvent>
  pagination: Pagination
}

export enum JourneyInitiator {
  /** Default, If not specified it will be app */
  app = 'app',
  /** Journey started via SMS */
  sms = 'sms',
  /** Journey started via Breeze app */
  trafi = 'trafi',
}

export type JourneyInvoice = IInvoice & {
  __typename: 'JourneyInvoice'
  created_at: Scalars['DateTime']['output']
  currency_balance?: Maybe<FormattedAmount>
  currency_debit?: Maybe<FormattedAmount>
  currency_debit_sub_total?: Maybe<FormattedAmount>
  currency_refundable?: Maybe<FormattedAmount>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  is_void: Scalars['Boolean']['output']
  journey: Journey
  minute_debit?: Maybe<FormattedAmount>
  minute_refundable?: Maybe<FormattedAmount>
  next_action?: Maybe<Array<Maybe<PaymentIntent>>>
  scheme_product?: Maybe<SchemeProduct>
  status?: Maybe<InvoiceStatus>
  timezone_offset?: Maybe<Scalars['Float']['output']>
  transactions?: Maybe<TransactionConnection>
  user?: Maybe<User>
}

export type JourneyOrderBy = {
  completed_at?: InputMaybe<Direction>
  id?: InputMaybe<Direction>
  started_at?: InputMaybe<Direction>
}

export type JourneyWhere = {
  completed_at?: InputMaybe<ScalarOp>
  id?: InputMaybe<ScalarOp>
  module?: InputMaybe<RemoteNodeOp>
  scheme?: InputMaybe<RemoteNodeOp>
  started_at?: InputMaybe<ScalarOp>
  user?: InputMaybe<RemoteNodeOp>
}

export type Justification = {
  __typename: 'Justification'
  description: Scalars['String']['output']
  justification_id: Scalars['String']['output']
  side_effects: Array<Maybe<Scalars['String']['output']>>
  timestamps: Timestamps
}

export type Location = {
  __typename: 'Location'
  altitude: Scalars['Float']['output']
  id: Scalars['ID']['output']
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
  max_error?: Maybe<Scalars['Float']['output']>
  parking_status?: Maybe<ParkingStatus>
  time: Scalars['DateTime']['output']
}

export type LocationAreaInput = {
  latitude_max: Scalars['Float']['input']
  latitude_min: Scalars['Float']['input']
  longitude_max: Scalars['Float']['input']
  longitude_min: Scalars['Float']['input']
}

export type LocationInput = {
  latitude: Scalars['Float']['input']
  longitude: Scalars['Float']['input']
}

export enum LockConfiguration {
  electronic_lock = 'electronic_lock',
  front_and_electronic_rear_lock = 'front_and_electronic_rear_lock',
  front_and_rear_lock = 'front_and_rear_lock',
  no_lock = 'no_lock',
  rear_lock = 'rear_lock',
}

export type LockEvent = {
  __typename: 'LockEvent'
  front_lock_state?: Maybe<LockState>
  id: Scalars['ID']['output']
  overall_state?: Maybe<LockState>
  rear_lock_state?: Maybe<LockState>
  time: Scalars['DateTime']['output']
  vehicle_lock_state?: Maybe<VehicleLockState>
}

export type LockEventConnection = {
  __typename: 'LockEventConnection'
  nodes: Array<LockEvent>
  pagination: Pagination
}

export enum LockState {
  lock_failed = 'lock_failed',
  lock_fault = 'lock_fault',
  lock_stuck = 'lock_stuck',
  lock_timeout = 'lock_timeout',
  locked = 'locked',
  not_applicable = 'not_applicable',
  spontaneous_lock = 'spontaneous_lock',
  spontaneous_unlock = 'spontaneous_unlock',
  unknown = 'unknown',
  unlock_failed = 'unlock_failed',
  unlock_timeout = 'unlock_timeout',
  unlocked = 'unlocked',
  waiting_for_lock = 'waiting_for_lock',
  waiting_for_unlock = 'waiting_for_unlock',
}

export type ManufacturingInfoInput = {
  frame_model: Scalars['String']['input']
  frame_year: Scalars['Int']['input']
  module_id: Scalars['ID']['input']
}

export type MaptexConfig = OkaiScooterConfig | SegwayEBikeConfig

export type MinuteRefundCalcs = {
  __typename: 'MinuteRefundCalcs'
  /** Proportion of currency that can be refunded for minutes that haven't been used */
  currency_amount: FormattedAmount
  /** Number of minutes that have been used out of this bundle to balance if voiding */
  minute_adjustment_quantity: Scalars['Int']['output']
}

export type MinuteTransaction = ITransaction & {
  __typename: 'MinuteTransaction'
  amount_refundable?: Maybe<FormattedAmount>
  amount_refunded?: Maybe<FormattedAmount>
  category: TransactionCategory
  created_at: Scalars['DateTime']['output']
  credit_amount: FormattedAmount
  debit_amount: FormattedAmount
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  meta?: Maybe<TransactionMeta>
  provider_type?: Maybe<CurrencyPaymentProviderType>
  refund_requester?: Maybe<User>
  result?: Maybe<TransactionResult>
  scheme_ids?: Maybe<Array<Scalars['ID']['output']>>
  type?: Maybe<TransactionType>
  void: Scalars['Boolean']['output']
}

export type Module = {
  __typename: 'Module'
  availability?: Maybe<Availability>
  availability_history: AvailabilityConnection
  /** @deprecated Use .vehicle instead */
  bike?: Maybe<Bike>
  connectivity?: Maybe<ModuleConnectivity>
  connectivity_history: ModuleConnectivityConnection
  dock_state?: Maybe<ModuleEvent>
  dock_state_history: ModuleEventConnection
  /** The type of hardware this module is associated with */
  hardware_type: ModuleHardware
  id: Scalars['ID']['output']
  /** @deprecated Field no longer supported */
  iot_state?: Maybe<IotState>
  is_commissioned?: Maybe<Scalars['Boolean']['output']>
  is_hireable?: Maybe<Scalars['Boolean']['output']>
  /** Module has neither missing nor workshop tag */
  is_present: Scalars['Boolean']['output']
  journeys: JourneyConnection
  location?: Maybe<Location>
  lock_state?: Maybe<LockEvent>
  lock_state_history: LockEventConnection
  module_state?: Maybe<IModuleState>
  soak_test?: Maybe<SoakSuite>
  tags?: Maybe<Array<ModuleTag>>
  telemetry?: Maybe<Telemetry>
  unlock_id?: Maybe<Scalars['String']['output']>
  vehicle?: Maybe<Vehicle>
  zone_id?: Maybe<Scalars['String']['output']>
}

export type Moduleavailability_historyArgs = {
  paginate?: InputMaybe<Paginate>
}

export type Moduledock_state_historyArgs = {
  paginate?: InputMaybe<Paginate>
}

export type ModulejourneysArgs = {
  paginate?: InputMaybe<Paginate>
  scheme_id: Scalars['ID']['input']
}

export type Modulelock_state_historyArgs = {
  paginate?: InputMaybe<Paginate>
}

export type ModuleConnection = {
  __typename: 'ModuleConnection'
  nodes: Array<Module>
  pagination: Pagination
}

export type ModuleConnectivity = {
  __typename: 'ModuleConnectivity'
  details?: Maybe<ModuleConnectivityDetails>
  id: Scalars['ID']['output']
  is_online: Scalars['Boolean']['output']
  time: Scalars['DateTime']['output']
}

export type ModuleConnectivityConnection = {
  __typename: 'ModuleConnectivityConnection'
  nodes: Array<ModuleConnectivity>
  pagination: Pagination
}

export type ModuleConnectivityDetails = {
  __typename: 'ModuleConnectivityDetails'
  error_code?: Maybe<Scalars['Int']['output']>
  error_message?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  last_config_ack_time?: Maybe<Scalars['DateTime']['output']>
  last_config_send_time?: Maybe<Scalars['DateTime']['output']>
  last_error_time?: Maybe<Scalars['DateTime']['output']>
  last_event_time?: Maybe<Scalars['DateTime']['output']>
  last_heartbeat_time?: Maybe<Scalars['DateTime']['output']>
  last_state_time?: Maybe<Scalars['DateTime']['output']>
}

export type ModuleEvent = {
  __typename: 'ModuleEvent'
  id: Scalars['ID']['output']
  message: Scalars['String']['output']
  time: Scalars['DateTime']['output']
}

export type ModuleEventConnection = {
  __typename: 'ModuleEventConnection'
  nodes: Array<ModuleEvent>
  pagination: Pagination
}

export enum ModuleHardware {
  bbe = 'bbe',
  beryl_bike = 'beryl_bike',
  scooter = 'scooter',
  smartbike_tfl = 'smartbike_tfl',
}

export enum ModuleHardwareSubtype {
  okai_es400 = 'okai_es400',
  okai_es410 = 'okai_es410',
  okai_es600 = 'okai_es600',
  segway_a200p_gen2 = 'segway_a200p_gen2',
}

export enum ModuleHardwareType {
  /** Beryl bike electric hardware */
  bbe = 'BBE',
  /** Beryl bike hardware */
  beryl_bike = 'BERYL_BIKE',
  /** TFL Smartbike hardware */
  smartbike_tfl = 'SMARTBIKE_TFL',
  /** Undefined hardware */
  undefined_hw = 'UNDEFINED_HW',
  /** Unknown hardware */
  unknown_hw = 'UNKNOWN_HW',
}

export type ModuleInputAdd = {
  hardware_subtype?: InputMaybe<ModuleHardwareSubtype>
  hardware_type: ModuleHardware
  module_id: Scalars['ID']['input']
  public_key: Scalars['String']['input']
  unlock_id: Scalars['ID']['input']
}

export type ModuleInputUpdate = {
  module_id: Scalars['ID']['input']
  public_key?: InputMaybe<Scalars['String']['input']>
  unlock_id?: InputMaybe<Scalars['ID']['input']>
}

export type ModuleOrderby = {
  id?: InputMaybe<Direction>
  telemetry?: InputMaybe<TelemetryOrderby>
}

export type ModuleState = IModuleState & {
  __typename: 'ModuleState'
  assist_config?: Maybe<AssistConfig>
  assist_mode?: Maybe<AssistMode>
  battery_id?: Maybe<Scalars['String']['output']>
  /** To differentiate between beryl bikes and tfl smartbikes using the board hardware type */
  board_hw_type?: Maybe<ModuleHardwareType>
  commit_hash?: Maybe<Scalars['String']['output']>
  debug_mode?: Maybe<Scalars['Boolean']['output']>
  dock_nfc_tag_id?: Maybe<Scalars['String']['output']>
  drive_id?: Maybe<Scalars['String']['output']>
  firmware_version?: Maybe<Scalars['String']['output']>
  hardware_version?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imei?: Maybe<Scalars['String']['output']>
  imsi?: Maybe<Scalars['String']['output']>
  installed_deployment_id?: Maybe<Scalars['Int']['output']>
  mac_address?: Maybe<Scalars['String']['output']>
  orientation?: Maybe<Scalars['String']['output']>
  pending_firmware_url?: Maybe<Scalars['String']['output']>
  power_cycles?: Maybe<Scalars['Int']['output']>
  power_mode?: Maybe<Scalars['String']['output']>
  status_accelerometer?: Maybe<Scalars['Int']['output']>
  status_battery_voltage?: Maybe<Scalars['Boolean']['output']>
  status_charging_current?: Maybe<Scalars['Boolean']['output']>
  status_front_light?: Maybe<Scalars['Int']['output']>
  status_generated_power?: Maybe<Scalars['Boolean']['output']>
  status_gnss_module?: Maybe<Scalars['String']['output']>
  status_lock?: Maybe<Scalars['Int']['output']>
  status_rear_light?: Maybe<Scalars['Int']['output']>
  status_supercap_voltage?: Maybe<Scalars['Boolean']['output']>
  status_temp_sensor?: Maybe<Scalars['Int']['output']>
  time: Scalars['DateTime']['output']
  watchdog_resets?: Maybe<Scalars['Int']['output']>
}

export type ModuleTag = {
  __typename: 'ModuleTag'
  created_at: Scalars['DateTime']['output']
  deleted?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  module_id: Scalars['ID']['output']
  name: ModuleTagName
}

/** List of acceptable tag names */
export enum ModuleTagName {
  /** Vehicle is being used as part of an event */
  event = 'event',
  /** Ignore battery_level when deciding if vehicle is available */
  low_battery_override = 'low_battery_override',
  /** Flagged for maintenance */
  maintenance = 'maintenance',
  /** Missing */
  missing = 'missing',
  /** In workshop */
  workshop = 'workshop',
}

export type ModuleWhere = {
  bike?: InputMaybe<BikeWhere>
  connectivity?: InputMaybe<ConnectivityWhere>
  hardware_type?: InputMaybe<ScalarOp>
  id?: InputMaybe<ScalarOp>
  iot_state?: InputMaybe<IotStateWhere>
  tags?: InputMaybe<TagWhere>
  telemetry?: InputMaybe<TelemetryWhere>
  unlock_id?: InputMaybe<ScalarOp>
  vehicle?: InputMaybe<VehicleWhere>
}

export type Mutation = {
  __typename: 'Mutation'
  /** Activates the SIM card for a module */
  activate_sim: Scalars['Boolean']['output']
  add_manufacturing_info?: Maybe<Scalars['Boolean']['output']>
  /** Add module ('module' is a reserved keyword) */
  add_module: Module
  add_org_scheme?: Maybe<Scalars['Boolean']['output']>
  /** Add permitted vehicle model to a scheme */
  add_permitted_vehicle_model: PermittedVehicleModel
  /** Add registration ID - used for push notifications */
  add_registration_id: Scalars['Boolean']['output']
  /** Adds a user role */
  add_role: Role
  /** Add scheme */
  add_scheme: Scheme
  /** Add scheme accounting information */
  add_scheme_accounting: SchemeAccounting
  /** Add a scheme availability restriction */
  add_scheme_availability_restriction?: Maybe<SchemeAvailabilityRestriction>
  /** Add scheme group information */
  add_scheme_group: SchemeGroup
  /** Adds a scheme product */
  add_scheme_product: SchemeProduct
  /** Add service area */
  add_service_area?: Maybe<ServiceArea>
  /** Adds a firebase user */
  add_user: User
  /** Register a user with a device - Syncs with Persona */
  add_user_device: Scalars['Boolean']['output']
  /** Buy a user product */
  add_user_product?: Maybe<UserProduct>
  /** Buy a user product */
  add_user_product_V2?: Maybe<IInvoice>
  /** Add a user to a scheme */
  add_user_scheme: Scalars['Boolean']['output']
  /** Add a vehicle */
  add_vehicle?: Maybe<Vehicle>
  /** Add entry to whitelist */
  add_whitelist: Whitelist
  /** Add zone */
  add_zone?: Maybe<Zone>
  apply_vehicle_configuration?: Maybe<Scalars['Boolean']['output']>
  archive_restore: Scalars['Boolean']['output']
  /** Block the user's Card fingerprint on Stripe */
  block_user_card_fingerprint: Scalars['Boolean']['output']
  /** Set the user's Persona account to blocked (Naughty) */
  block_user_identity_platform_account: Scalars['Boolean']['output']
  /** Cancel a journey - will trigger a journey penalty - usually a module locking will trigger journey end */
  cancel_journey: Journey
  /**
   * Commission a bike
   * @deprecated Use commission_V2
   */
  commission: Bike
  /** Commission a bike */
  commission_V2: Vehicle
  /** Commission an asset via ServiceNow */
  commission_asset: Job
  create_charge_exemption: OperationResult
  create_incident?: Maybe<Incident>
  /** Create a Job */
  create_job?: Maybe<Job>
  /** Create an onfido check to trigger the document verification process */
  create_onfido_check: Scalars['Boolean']['output']
  /** Create an onfido client sdk token for a given user */
  create_onfido_sdk_token: OnfidoSDKToken
  /** Create setup_intent for caller */
  create_setup_intent?: Maybe<SetupIntent>
  /** Creates a firebase user */
  create_user: Scalars['ID']['output']
  /** Decommission a module */
  decommission?: Maybe<Scalars['Boolean']['output']>
  /** Decommission an asset via ServiceNow */
  decommission_asset: Job
  /** Decrypt an email sent by Swift App */
  decrypt_swift_email: Scalars['String']['output']
  delete_charge_exemption: OperationResult
  /** Delete a job */
  delete_job?: Maybe<Scalars['Boolean']['output']>
  delete_ota_configuration_command?: Maybe<OTAConfigurationCommand>
  delete_ota_firmware?: Maybe<OTAFirmware>
  delete_permitted_vehicle_model: Scalars['Boolean']['output']
  /** Enables a zone */
  enable_zone?: Maybe<Zone>
  /** End a journey - usually a module locking will trigger journey end */
  end_journey: Journey
  /** Force add a user's phone number, if they aren't able to add it via Firebase */
  force_add_user_phone_number: Scalars['Boolean']['output']
  /** Instructs a module to go into hibernate mode */
  hibernate: Scalars['Boolean']['output']
  insert_ota_configuration_command?: Maybe<OTAConfigurationCommand>
  /** No longer allow current access token */
  invalidate_access_token: Scalars['Boolean']['output']
  /** Set the has_valid_licence flag for a user to false */
  invalidate_user_licence: User
  job_add_asset: ChildJob
  /** Job automation */
  job_automation?: Maybe<Scalars['Boolean']['output']>
  job_remove_asset: ChildJob
  /** Locates a module */
  locate?: Maybe<Scalars['Boolean']['output']>
  /** Instructs a module to remotely lock - only supported by scooters and virtualbikes */
  lock: Scalars['Boolean']['output']
  /** Locks a bike in context of journey - currently used for scooters */
  lock_journey: Journey
  /** Move a commissioned device from one scheme to another */
  move_scheme: Vehicle
  /** Pause a journey */
  pause_journey: Journey
  /** Instructs a module to reboot */
  reboot: Scalars['Boolean']['output']
  /**
   * Requests config from IoT device
   * @deprecated Use refresh_ota_device_information
   */
  refresh_config?: Maybe<Scalars['Boolean']['output']>
  refresh_ota_device_information?: Maybe<Scalars['Boolean']['output']>
  /** Fully or partially refund currency and minutes on an invoice */
  refund_invoice?: Maybe<RefundInvoiceResult>
  refund_invoice_v2?: Maybe<RefundInvoiceResult>
  /** Register job attempt */
  register_job_attempt?: Maybe<Attempt>
  /** Releases a modules battery */
  release_battery?: Maybe<Scalars['Boolean']['output']>
  /** Instructs a module to release lock */
  release_lock: Scalars['Boolean']['output']
  remove_org_scheme?: Maybe<Scalars['Boolean']['output']>
  /** Removes a card from a payer */
  remove_payer_card?: Maybe<Scalars['Boolean']['output']>
  /** Remove registration ID - used to cancel push notifications on logout */
  remove_registration_id: Scalars['Boolean']['output']
  /** Removes a user role */
  remove_role?: Maybe<Scalars['Boolean']['output']>
  /** Remove a scheme accounting */
  remove_scheme_accounting?: Maybe<Scalars['Boolean']['output']>
  /** Remove a scheme availability restriction */
  remove_scheme_availability_restriction?: Maybe<Scalars['Boolean']['output']>
  /** Remove a scheme group */
  remove_scheme_group?: Maybe<Scalars['Boolean']['output']>
  /** Remove a scheme product */
  remove_scheme_product?: Maybe<Scalars['Boolean']['output']>
  /** Remove a service area */
  remove_service_area?: Maybe<Scalars['Boolean']['output']>
  /** Remove user */
  remove_user?: Maybe<Scalars['Boolean']['output']>
  /** Removes a user's phone number */
  remove_user_phone_number: Scalars['Boolean']['output']
  /** Remove entry in whitelist */
  remove_whitelist?: Maybe<Scalars['Boolean']['output']>
  /** Remove zone */
  remove_zone?: Maybe<Scalars['Boolean']['output']>
  request_delete_user: OperationResult
  /** Resume a journey */
  resume_journey: Journey
  reverse_cancelled_journey?: Maybe<RefundInvoiceResult>
  /** Instructs a vehicle to lock asynchronously, and with a movement failsafe */
  safe_lock: Scalars['Boolean']['output']
  /** Send sign in email to specified email address */
  send_sign_in_email_link: Scalars['Boolean']['output']
  /** Send anonymous user_id to user service to generate and push token to mobile */
  send_sign_in_request: Scalars['JSON']['output']
  /** Instructs a module to send telemetry */
  send_telemetry: Scalars['Boolean']['output']
  /** Sets the modules electric assist config */
  set_assist_config: Scalars['Boolean']['output']
  /** Set the preferred type of currency payment provider for this payer */
  set_preferred_currency_payment_provider?: Maybe<Scalars['Boolean']['output']>
  /** Set payment token from Swift webview callback */
  set_swift_payment_authorisation?: Maybe<Scalars['Boolean']['output']>
  /** Sets notes on a zone */
  set_zone_notes?: Maybe<Zone>
  /** Set/Unset a zone as promotional */
  set_zone_promotional?: Maybe<Zone>
  /** Settle outstanding charges on an Invoice */
  settle_invoice_V2?: Maybe<IInvoice>
  /** Set a module to shipping mode */
  shipping?: Maybe<Scalars['Boolean']['output']>
  /** Start deployment */
  start_deployment?: Maybe<Deployment>
  /** Start a journey, specifying module_id */
  start_journey_by_module_id: Journey
  /** Start a journey, specifying unlock_id */
  start_journey_by_unlock_id: Journey
  /** Suspends a zone */
  suspend_zone?: Maybe<Zone>
  sync_service_areas?: Maybe<ServiceAreaSyncResult>
  /** Sync zones */
  sync_zones?: Maybe<ZoneSyncResult>
  /** Synchronize a user with its Firebase claims */
  synchronize_firebase_user: User
  /** Un-block the user's Card fingerprint on Stripe */
  unblock_user_card_fingerprint: Scalars['Boolean']['output']
  /** Unblock the user's Persona account */
  unblock_user_identity_platform_account: Scalars['Boolean']['output']
  /** Unvoid a transaction */
  unvoid_transaction?: Maybe<ITransaction>
  /** Update a Job */
  update_job?: Maybe<Job>
  /** Update multiple Job */
  update_jobs?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Update module ('module' is a reserved keyword) */
  update_module: Module
  /** Update a scheme */
  update_scheme: Scheme
  /** Updates a scheme accounting */
  update_scheme_accounting: SchemeAccounting
  /** Update a scheme availability restriction */
  update_scheme_availability_restriction?: Maybe<SchemeAvailabilityRestriction>
  /** Updates a scheme group */
  update_scheme_group: SchemeGroup
  /** Updates a schemeProduct */
  update_scheme_product: SchemeProduct
  /** Update service area */
  update_service_area?: Maybe<ServiceArea>
  /** Update service area policy */
  update_service_area_policy?: Maybe<ServiceArea>
  /** Update a Task */
  update_task?: Maybe<Scalars['Boolean']['output']>
  update_vehicle_detail?: Maybe<Vehicle>
  /** Update zone */
  update_zone?: Maybe<Zone>
  upgrade_module_firmwares?: Maybe<Scalars['Boolean']['output']>
  upsert_ota_firmware?: Maybe<OTAFirmware>
  /** Validate a task replacement */
  validate_replacements?: Maybe<Scalars['Boolean']['output']>
  /** Set the has_valid_licence flag for a user to true */
  validate_user_licence: User
  /** Validates a voucher code and returns a voucher with a list of scheme products with discount_calculations attached */
  validate_voucher_code: Voucher
  vehicle_event_end?: Maybe<Scalars['Boolean']['output']>
  vehicle_event_start?: Maybe<Scalars['Boolean']['output']>
  vehicle_located?: Maybe<Scalars['Boolean']['output']>
  vehicle_low_battery_override_end?: Maybe<Scalars['Boolean']['output']>
  vehicle_low_battery_override_start?: Maybe<Scalars['Boolean']['output']>
  vehicle_maintenance_end?: Maybe<Scalars['Boolean']['output']>
  vehicle_maintenance_pick_up?: Maybe<Scalars['Boolean']['output']>
  vehicle_maintenance_start?: Maybe<Scalars['Boolean']['output']>
  vehicle_missing?: Maybe<Scalars['Boolean']['output']>
  vehicle_provider_drop_off?: Maybe<Scalars['Boolean']['output']>
  vehicle_rebalance_cancel?: Maybe<Scalars['Boolean']['output']>
  vehicle_rebalance_pick_up?: Maybe<Scalars['Boolean']['output']>
  vehicle_safe_maintenance_end?: Maybe<Scalars['Boolean']['output']>
  /** Void a journey - usually a module locking will trigger journey end */
  void_journey: Journey
  /** Void a transaction */
  void_transaction?: Maybe<ITransaction>
}

export type Mutationactivate_simArgs = {
  module_id: Scalars['ID']['input']
}

export type Mutationadd_manufacturing_infoArgs = {
  manufacturing_info: ManufacturingInfoInput
}

export type Mutationadd_moduleArgs = {
  mod: ModuleInputAdd
}

export type Mutationadd_org_schemeArgs = {
  org_id: Scalars['ID']['input']
  scheme_id: Scalars['ID']['input']
}

export type Mutationadd_permitted_vehicle_modelArgs = {
  permitted_vehicle_model: PermittedVehicleModelInput
}

export type Mutationadd_registration_idArgs = {
  registration_id: Scalars['ID']['input']
  user_id: Scalars['ID']['input']
}

export type Mutationadd_roleArgs = {
  role: RoleInput
}

export type Mutationadd_schemeArgs = {
  scheme: SchemeInput
}

export type Mutationadd_scheme_accountingArgs = {
  scheme_accounting: SchemeAccountingInput
}

export type Mutationadd_scheme_availability_restrictionArgs = {
  scheme_availability_restriction: SchemeAvailabilityRestrictionInput
}

export type Mutationadd_scheme_groupArgs = {
  scheme_group: SchemeGroupInput
}

export type Mutationadd_scheme_productArgs = {
  scheme_product: SchemeProductInput
}

export type Mutationadd_service_areaArgs = {
  service_area: ServiceAreaInput
}

export type Mutationadd_userArgs = {
  user: UserInput
}

export type Mutationadd_user_deviceArgs = {
  device_vendor_id: Scalars['String']['input']
  user_id: Scalars['ID']['input']
}

export type Mutationadd_user_productArgs = {
  reward?: InputMaybe<VehicleRewardType>
  scheme_product_id: Scalars['ID']['input']
  user_id: Scalars['ID']['input']
  voucher_code?: InputMaybe<Scalars['String']['input']>
  voucher_purchase?: InputMaybe<Scalars['Boolean']['input']>
}

export type Mutationadd_user_product_V2Args = {
  scheme_product_id: Scalars['ID']['input']
  user_id: Scalars['ID']['input']
  voucher_code?: InputMaybe<Scalars['String']['input']>
}

export type Mutationadd_user_schemeArgs = {
  scheme_id: Scalars['ID']['input']
  user_id: Scalars['ID']['input']
}

export type Mutationadd_vehicleArgs = {
  frame_id: Scalars['ID']['input']
  frame_model?: InputMaybe<Scalars['String']['input']>
  frame_type: FrameType
  frame_year?: InputMaybe<Scalars['Int']['input']>
  hardware_type: ModuleHardware
  propulsion_type: Array<VehiclePropulsionType>
  vehicle_type: VehicleType
}

export type Mutationadd_whitelistArgs = {
  whitelist: WhitelistInput
}

export type Mutationadd_zoneArgs = {
  zone: ZoneInput
}

export type Mutationapply_vehicle_configurationArgs = {
  dry_run?: InputMaybe<Scalars['Boolean']['input']>
  module_id: Scalars['ID']['input']
}

export type Mutationarchive_restoreArgs = {
  sys_id: Scalars['ID']['input']
}

export type Mutationblock_user_card_fingerprintArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationblock_user_identity_platform_accountArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationcancel_journeyArgs = {
  journey_id: Scalars['ID']['input']
  use_movement_check?: InputMaybe<Scalars['Boolean']['input']>
  with_lock_or_raise_job?: InputMaybe<Scalars['Boolean']['input']>
  with_safe_lock?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationcommissionArgs = {
  frame_id: Scalars['ID']['input']
  module_id?: InputMaybe<Scalars['ID']['input']>
  scheme_id: Scalars['ID']['input']
}

export type Mutationcommission_V2Args = {
  frame_id: Scalars['ID']['input']
  frame_type?: InputMaybe<FrameType>
  lock_configuration: LockConfiguration
  module_id: Scalars['ID']['input']
  scheme_id: Scalars['ID']['input']
  vehicle_unlock_id?: InputMaybe<Scalars['ID']['input']>
}

export type Mutationcommission_assetArgs = {
  asset: ParentAsset
  scheme_id: Scalars['ID']['input']
  taskId: Scalars['ID']['input']
  uuid?: InputMaybe<Scalars['ID']['input']>
  zone_id?: InputMaybe<Scalars['String']['input']>
}

export type Mutationcreate_charge_exemptionArgs = {
  scheme_id: Scalars['ID']['input']
  user_id: Scalars['ID']['input']
}

export type Mutationcreate_incidentArgs = {
  incident: IncidentInput
}

export type Mutationcreate_jobArgs = {
  job: JobInput
}

export type Mutationcreate_onfido_checkArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationcreate_onfido_sdk_tokenArgs = {
  bundle_identifier: Scalars['String']['input']
  user_id: Scalars['ID']['input']
}

export type Mutationcreate_setup_intentArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationcreate_userArgs = {
  email: Scalars['EmailAddress']['input']
}

export type MutationdecommissionArgs = {
  module_id?: InputMaybe<Scalars['ID']['input']>
  vehicle_id?: InputMaybe<Scalars['ID']['input']>
}

export type Mutationdecommission_assetArgs = {
  external_id: Scalars['ID']['input']
}

export type Mutationdecrypt_swift_emailArgs = {
  hash: Scalars['String']['input']
}

export type Mutationdelete_charge_exemptionArgs = {
  scheme_id: Scalars['ID']['input']
  user_id: Scalars['ID']['input']
}

export type Mutationdelete_jobArgs = {
  job_id?: InputMaybe<Scalars['ID']['input']>
}

export type Mutationdelete_ota_configuration_commandArgs = {
  id: Scalars['Int']['input']
}

export type Mutationdelete_ota_firmwareArgs = {
  id: Scalars['Int']['input']
}

export type Mutationdelete_permitted_vehicle_modelArgs = {
  permitted_vehicle_model_id: Scalars['ID']['input']
}

export type Mutationenable_zoneArgs = {
  zone_id: Scalars['ID']['input']
}

export type Mutationend_journeyArgs = {
  journey_id: Scalars['ID']['input']
  use_movement_check?: InputMaybe<Scalars['Boolean']['input']>
  with_lock_or_raise_job?: InputMaybe<Scalars['Boolean']['input']>
  with_safe_lock?: InputMaybe<Scalars['Boolean']['input']>
}

export type Mutationforce_add_user_phone_numberArgs = {
  phone_number: Scalars['String']['input']
  user_id: Scalars['ID']['input']
}

export type MutationhibernateArgs = {
  module_id: Scalars['ID']['input']
}

export type Mutationinsert_ota_configuration_commandArgs = {
  input: OTAConfigurationCommandInput
}

export type Mutationinvalidate_user_licenceArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationjob_add_assetArgs = {
  asset_tag: Scalars['ID']['input']
  job_id: Scalars['ID']['input']
}

export type Mutationjob_automationArgs = {
  assets?: InputMaybe<Array<Scalars['String']['input']>>
  description: Scalars['String']['input']
  dry_run: Scalars['Boolean']['input']
  justification_id: Scalars['String']['input']
  results?: InputMaybe<Array<InputMaybe<JobAutomationVehicleInput>>>
  side_effects: Array<InputMaybe<JobAutomationSideEffect>>
  taskId: Scalars['String']['input']
  use_cleanup?: InputMaybe<Scalars['Boolean']['input']>
}

export type Mutationjob_remove_assetArgs = {
  asset_tag: Scalars['ID']['input']
  job_id: Scalars['ID']['input']
}

export type MutationlocateArgs = {
  module_id?: InputMaybe<Scalars['ID']['input']>
  vehicle_id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationlockArgs = {
  module_id?: InputMaybe<Scalars['ID']['input']>
  vehicle_id?: InputMaybe<Scalars['ID']['input']>
}

export type Mutationlock_journeyArgs = {
  journey_id: Scalars['ID']['input']
}

export type Mutationmove_schemeArgs = {
  module_id: Scalars['ID']['input']
  scheme_id: Scalars['ID']['input']
}

export type Mutationpause_journeyArgs = {
  journey_id: Scalars['ID']['input']
}

export type MutationrebootArgs = {
  module_id?: InputMaybe<Scalars['ID']['input']>
  vehicle_id?: InputMaybe<Scalars['ID']['input']>
}

export type Mutationrefresh_configArgs = {
  module_id: Scalars['ID']['input']
}

export type Mutationrefresh_ota_device_informationArgs = {
  module_id: Scalars['ID']['input']
}

export type Mutationrefund_invoiceArgs = {
  invoice_id: Scalars['ID']['input']
  refund_invoice: RefundInvoiceInput
}

export type Mutationrefund_invoice_v2Args = {
  invoice_id: Scalars['ID']['input']
  refund_invoice: RefundInvoiceV2Input
}

export type Mutationregister_job_attemptArgs = {
  attempt: AttemptInput
  job_id: Scalars['ID']['input']
}

export type Mutationrelease_batteryArgs = {
  module_id?: InputMaybe<Scalars['ID']['input']>
  vehicle_id?: InputMaybe<Scalars['ID']['input']>
}

export type Mutationrelease_lockArgs = {
  module_id?: InputMaybe<Scalars['ID']['input']>
  vehicle_id?: InputMaybe<Scalars['ID']['input']>
}

export type Mutationremove_org_schemeArgs = {
  org_id: Scalars['ID']['input']
  scheme_id: Scalars['ID']['input']
}

export type Mutationremove_payer_cardArgs = {
  card_id: Scalars['ID']['input']
  user_id: Scalars['ID']['input']
}

export type Mutationremove_registration_idArgs = {
  registration_id: Scalars['ID']['input']
  user_id: Scalars['ID']['input']
}

export type Mutationremove_roleArgs = {
  role_id: Scalars['ID']['input']
}

export type Mutationremove_scheme_accountingArgs = {
  scheme_accounting_id: Scalars['ID']['input']
}

export type Mutationremove_scheme_availability_restrictionArgs = {
  scheme_availability_restriction_id: Scalars['ID']['input']
}

export type Mutationremove_scheme_groupArgs = {
  scheme_group_id: Scalars['ID']['input']
}

export type Mutationremove_scheme_productArgs = {
  scheme_product_id: Scalars['ID']['input']
}

export type Mutationremove_service_areaArgs = {
  service_area_id: Scalars['ID']['input']
}

export type Mutationremove_userArgs = {
  user: UserInput
}

export type Mutationremove_user_phone_numberArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationremove_whitelistArgs = {
  whitelist_id: Scalars['ID']['input']
}

export type Mutationremove_zoneArgs = {
  zone_id: Scalars['ID']['input']
}

export type Mutationrequest_delete_userArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationresume_journeyArgs = {
  journey_id: Scalars['ID']['input']
}

export type Mutationreverse_cancelled_journeyArgs = {
  invoice_id: Scalars['ID']['input']
  refund_request: ReverseCancelledJourneyInput
}

export type Mutationsafe_lockArgs = {
  use_movement_check?: InputMaybe<Scalars['Boolean']['input']>
  vehicle_id: Scalars['ID']['input']
}

export type Mutationsend_sign_in_email_linkArgs = {
  email_link: EmailLinkInput
}

export type Mutationsend_sign_in_requestArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationsend_telemetryArgs = {
  module_id?: InputMaybe<Scalars['ID']['input']>
  vehicle_id?: InputMaybe<Scalars['ID']['input']>
}

export type Mutationset_assist_configArgs = {
  assist_config: AssistConfig
  module_id?: InputMaybe<Scalars['ID']['input']>
  vehicle_id?: InputMaybe<Scalars['ID']['input']>
}

export type Mutationset_preferred_currency_payment_providerArgs = {
  preferred_provider: CurrencyPaymentProviderType
  user_id: Scalars['ID']['input']
}

export type Mutationset_swift_payment_authorisationArgs = {
  always_ask: Scalars['Boolean']['input']
  payment_token: Scalars['String']['input']
  user_id: Scalars['ID']['input']
}

export type Mutationset_zone_notesArgs = {
  notes?: InputMaybe<Scalars['String']['input']>
  zone_id: Scalars['ID']['input']
}

export type Mutationset_zone_promotionalArgs = {
  is_promotional: Scalars['Boolean']['input']
  zone_id: Scalars['ID']['input']
}

export type Mutationsettle_invoice_V2Args = {
  invoice_id: Scalars['ID']['input']
}

export type MutationshippingArgs = {
  module_id: Scalars['ID']['input']
}

export type Mutationstart_deploymentArgs = {
  module_ids: Array<InputMaybe<Scalars['ID']['input']>>
  version: Scalars['String']['input']
}

export type Mutationstart_journey_by_module_idArgs = {
  initiator?: InputMaybe<JourneyInitiator>
  module_id: Scalars['ID']['input']
  pausable?: InputMaybe<Scalars['Boolean']['input']>
}

export type Mutationstart_journey_by_unlock_idArgs = {
  initiator?: InputMaybe<JourneyInitiator>
  pausable?: InputMaybe<Scalars['Boolean']['input']>
  unlock_id: Scalars['ID']['input']
}

export type Mutationsuspend_zoneArgs = {
  zone_id: Scalars['ID']['input']
}

export type Mutationsync_service_areasArgs = {
  dry_run?: InputMaybe<Scalars['Boolean']['input']>
  scheme_id: Scalars['ID']['input']
}

export type Mutationsync_zonesArgs = {
  dry_run?: InputMaybe<Scalars['Boolean']['input']>
  scheme_id: Scalars['ID']['input']
}

export type Mutationsynchronize_firebase_userArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationunblock_user_card_fingerprintArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationunblock_user_identity_platform_accountArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationunvoid_transactionArgs = {
  transaction_id: Scalars['GUID']['input']
}

export type Mutationupdate_jobArgs = {
  job: JobUpdateInput
  job_id: Scalars['ID']['input']
}

export type Mutationupdate_jobsArgs = {
  job: JobUpdateInput
  job_ids: Array<Scalars['ID']['input']>
}

export type Mutationupdate_moduleArgs = {
  mod: ModuleInputUpdate
}

export type Mutationupdate_schemeArgs = {
  scheme: SchemeInput
  scheme_id: Scalars['ID']['input']
}

export type Mutationupdate_scheme_accountingArgs = {
  scheme_accounting: SchemeAccountingInput
  scheme_accounting_id: Scalars['ID']['input']
}

export type Mutationupdate_scheme_availability_restrictionArgs = {
  scheme_availability_restriction: SchemeAvailabilityRestrictionInput
  scheme_availability_restriction_id: Scalars['ID']['input']
}

export type Mutationupdate_scheme_groupArgs = {
  scheme_group: SchemeGroupInput
  scheme_group_id: Scalars['ID']['input']
}

export type Mutationupdate_scheme_productArgs = {
  scheme_product: SchemeProductInput
  scheme_product_id: Scalars['ID']['input']
}

export type Mutationupdate_service_areaArgs = {
  service_area: ServiceAreaInput
  service_area_id: Scalars['ID']['input']
}

export type Mutationupdate_service_area_policyArgs = {
  service_area_id: Scalars['ID']['input']
  service_area_policy: ServiceAreaPolicyInput
}

export type Mutationupdate_taskArgs = {
  job_id?: InputMaybe<Scalars['ID']['input']>
  task: JobTaskInput
}

export type Mutationupdate_vehicle_detailArgs = {
  vehicle_detail: VehicleDetailUpdateInput
  vehicle_id: Scalars['ID']['input']
}

export type Mutationupdate_zoneArgs = {
  zone: ZoneInput
  zone_id: Scalars['ID']['input']
}

export type Mutationupgrade_module_firmwaresArgs = {
  dry_run?: InputMaybe<Scalars['Boolean']['input']>
  module_id: Scalars['ID']['input']
}

export type Mutationupsert_ota_firmwareArgs = {
  input: OTAFirmwareInput
}

export type Mutationvalidate_replacementsArgs = {
  job_id: Scalars['ID']['input']
  task: JobTaskInput
}

export type Mutationvalidate_user_licenceArgs = {
  user_id: Scalars['ID']['input']
}

export type Mutationvalidate_voucher_codeArgs = {
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  user_id: Scalars['ID']['input']
  voucher_code: Scalars['String']['input']
}

export type Mutationvehicle_event_endArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_event_startArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_locatedArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_low_battery_override_endArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_low_battery_override_startArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_maintenance_endArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_maintenance_pick_upArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_maintenance_startArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_missingArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_provider_drop_offArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_rebalance_cancelArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_rebalance_pick_upArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvehicle_safe_maintenance_endArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Mutationvoid_journeyArgs = {
  journey_id: Scalars['ID']['input']
  use_movement_check?: InputMaybe<Scalars['Boolean']['input']>
  with_lock_or_raise_job?: InputMaybe<Scalars['Boolean']['input']>
  with_safe_lock?: InputMaybe<Scalars['Boolean']['input']>
}

export type Mutationvoid_transactionArgs = {
  transaction_id: Scalars['GUID']['input']
}

export type OTAConfigurationCommand = {
  __typename: 'OTAConfigurationCommand'
  command?: Maybe<Scalars['String']['output']>
  expected_status_code?: Maybe<Scalars['Int']['output']>
  hardware_model?: Maybe<HardwareModel>
  id: Scalars['Int']['output']
  name?: Maybe<Scalars['String']['output']>
  scheme_id?: Maybe<Scalars['Int']['output']>
  timeout?: Maybe<Scalars['Int']['output']>
}

export type OTAConfigurationCommandInput = {
  command: Scalars['String']['input']
  expected_status_code?: InputMaybe<Scalars['Int']['input']>
  hardware_model: HardwareModel
  name: Scalars['String']['input']
  scheme_id?: InputMaybe<Scalars['Int']['input']>
  timeout?: InputMaybe<Scalars['Int']['input']>
}

export type OTAFirmware = {
  __typename: 'OTAFirmware'
  file_name: Scalars['String']['output']
  firmware_type: FirmwareType
  firmware_version: Scalars['String']['output']
  hardware_model: HardwareModel
  hardware_version: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type OTAFirmwareInput = {
  file_name: Scalars['String']['input']
  firmware_type: FirmwareType
  firmware_version: Scalars['String']['input']
  hardware_model: HardwareModel
  hardware_version: Scalars['String']['input']
}

export type OTAInformation = {
  __typename: 'OTAInformation'
  config_set_at?: Maybe<Scalars['DateTime']['output']>
  firmware_versions: OTAVersions
  hardware_versions: OTAVersions
  required_firmware_versions: RequiredOTAVersions
  scheme_config_set_at?: Maybe<Scalars['DateTime']['output']>
  server_address: Scalars['String']['output']
  server_port: Scalars['String']['output']
  time: Scalars['DateTime']['output']
}

export type OTAVersions = {
  __typename: 'OTAVersions'
  ecu: Scalars['String']['output']
  iot: Scalars['String']['output']
  meter: Scalars['String']['output']
}

export type OkaiScooterConfig = {
  __typename: 'OkaiScooterConfig'
  ble_sw?: Maybe<Scalars['String']['output']>
  ecu_fw?: Maybe<Scalars['String']['output']>
  ecu_hw?: Maybe<Scalars['String']['output']>
  iot_hw?: Maybe<Scalars['String']['output']>
  iot_sw?: Maybe<Scalars['String']['output']>
  mdm_hw?: Maybe<Scalars['String']['output']>
  mdm_sw?: Maybe<Scalars['String']['output']>
  server_address?: Maybe<Scalars['String']['output']>
  server_port?: Maybe<Scalars['String']['output']>
  time?: Maybe<Scalars['DateTime']['output']>
}

export type OnfidoApplicant = {
  __typename: 'OnfidoApplicant'
  id: Scalars['ID']['output']
}

export type OnfidoSDKToken = {
  __typename: 'OnfidoSDKToken'
  token: Scalars['String']['output']
}

export type OperationResult = {
  __typename: 'OperationResult'
  code?: Maybe<Scalars['String']['output']>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type Org = {
  __typename: 'Org'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type OrgConnection = {
  __typename: 'OrgConnection'
  nodes: Array<Org>
  pagination: Pagination
}

export type Paginate = {
  page?: InputMaybe<Scalars['Int']['input']>
  per_page?: InputMaybe<Scalars['Int']['input']>
}

export type Pagination = {
  __typename: 'Pagination'
  has_next: Scalars['Boolean']['output']
  has_prev: Scalars['Boolean']['output']
  page: Scalars['Int']['output']
  pages: Scalars['Int']['output']
  per_page: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type ParentAsset = {
  asset_tag: Scalars['String']['input']
  child_assets: Array<InputMaybe<AssetInput>>
  model: Scalars['ID']['input']
  name: Scalars['String']['input']
  serial_number: Scalars['String']['input']
  vehicle_unlock_id?: InputMaybe<Scalars['String']['input']>
}

export enum ParkingCapacityStatus {
  available_space = 'available_space',
  limited_space = 'limited_space',
  no_space = 'no_space',
}

export enum ParkingStatus {
  out_of_service_area = 'out_of_service_area',
  out_of_zone = 'out_of_zone',
  valid = 'valid',
  vehicle_not_permitted = 'vehicle_not_permitted',
  zone_max_capacity = 'zone_max_capacity',
}

export type PartDetail = {
  __typename: 'PartDetail'
  batchNumber?: Maybe<Scalars['String']['output']>
  partName?: Maybe<Scalars['String']['output']>
  serialNumber?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  uuid?: Maybe<Scalars['String']['output']>
}

export type PartInput = {
  batchNumber?: InputMaybe<Scalars['String']['input']>
  partName?: InputMaybe<Scalars['String']['input']>
  serialNumber?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
}

export type Payer = {
  __typename: 'Payer'
  created_at: Scalars['DateTime']['output']
  /** @deprecated Use PaymentProvider.default_payment_method */
  default_card?: Maybe<Card>
  deleted?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  preferred_currency_payment_provider_type: CurrencyPaymentProviderType
  providers: Array<PaymentProvider>
  user?: Maybe<User>
}

export type PaymentIntent = {
  __typename: 'PaymentIntent'
  client_secret?: Maybe<Scalars['String']['output']>
  /** Indication that there is no more to do on this PaymentIntent. Client secret will not be populated */
  has_next_action?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
}

export type PaymentProvider = {
  __typename: 'PaymentProvider'
  /** Outstanding balance for currency providers. Minute provider should use usable_minute_balance by scheme id */
  balance?: Maybe<FormattedAmount>
  created_at: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** Net of minute topups (-debits) with no scheme id. These are debited first and can be used in any scheme */
  legacy_minute_balance?: Maybe<FormattedAmount>
  payer?: Maybe<Payer>
  type: PaymentProviderType
  /** Minute balance per scheme id */
  usable_minute_balance?: Maybe<Array<SchemeBalance>>
}

export enum PaymentProviderType {
  minute_customer = 'minute_customer',
  stripe_customer = 'stripe_customer',
  swift_customer = 'swift_customer',
  trafi_customer = 'trafi_customer',
}

export type PeriodPassBalances = {
  __typename: 'PeriodPassBalances'
  /** Balance from bonus passes */
  bonus_balance: FormattedAmount
  /** Balance for this current pass, other passes might mean more balance is available */
  current_balance: FormattedAmount
  /** Total pass balance available in this scheme */
  total_balance: FormattedAmount
}

export type PeriodPassCapInfo = {
  __typename: 'PeriodPassCapInfo'
  is_billable: Scalars['Boolean']['output']
  limit: Scalars['Int']['output']
  period: Scalars['String']['output']
  used: Scalars['Int']['output']
}

export type PeriodPassUnlocks = {
  __typename: 'PeriodPassUnlocks'
  hardware_type: ModuleHardware
  limit: Scalars['Int']['output']
  period: Scalars['String']['output']
  used: Scalars['Int']['output']
}

export type PermittedVehicleModel = {
  __typename: 'PermittedVehicleModel'
  created_at: Scalars['DateTime']['output']
  frame_type: FrameType
  hardware_type: ModuleHardware
  id: Scalars['ID']['output']
  scheme: Scheme
  updated_at: Scalars['DateTime']['output']
}

export type PermittedVehicleModelInput = {
  frame_type: FrameType
  hardware_type: ModuleHardware
  scheme_id: Scalars['ID']['input']
}

export type ProductDiscountConfig = {
  __typename: 'ProductDiscountConfig'
  amount_off?: Maybe<Scalars['Int']['output']>
  percent_off?: Maybe<Scalars['Float']['output']>
  type: ProductDiscountType
  unit_off?: Maybe<Scalars['Float']['output']>
  unit_type?: Maybe<Scalars['String']['output']>
}

export enum ProductDiscountType {
  amount = 'AMOUNT',
  percent = 'PERCENT',
  unit = 'UNIT',
}

export enum ProductImageVariant {
  /** Image of a commuter */
  commute_1 = 'commute_1',
  /** People having fun variant 1 */
  explore_1 = 'explore_1',
  /** People having fun variant 2 */
  explore_2 = 'explore_2',
}

export type ProductInvoice = IInvoice & {
  __typename: 'ProductInvoice'
  created_at: Scalars['DateTime']['output']
  currency_balance?: Maybe<FormattedAmount>
  currency_debit?: Maybe<FormattedAmount>
  currency_debit_sub_total?: Maybe<FormattedAmount>
  currency_refundable?: Maybe<FormattedAmount>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  is_void: Scalars['Boolean']['output']
  minute_debit?: Maybe<FormattedAmount>
  /** Calculated amount refundable if this is a partially used minute bundle purchase */
  minute_refund_calcs?: Maybe<MinuteRefundCalcs>
  minute_refundable?: Maybe<FormattedAmount>
  next_action?: Maybe<Array<Maybe<PaymentIntent>>>
  scheme_product?: Maybe<SchemeProduct>
  status?: Maybe<InvoiceStatus>
  timezone_offset?: Maybe<Scalars['Float']['output']>
  transactions?: Maybe<TransactionConnection>
  user?: Maybe<User>
}

/** Used by clients to group similar products in the UI */
export enum ProductType {
  /** Bundle */
  bundle = 'bundle',
  /** Day pass */
  day_pass = 'day_pass',
  /** PAYR */
  payg = 'payg',
  /** Period pass */
  period_pass = 'period_pass',
}

export type Promotion = {
  __typename: 'Promotion'
  banner: Scalars['String']['output']
  description: Scalars['String']['output']
  discount: ProductDiscountConfig
  expiration_date?: Maybe<Scalars['DateTime']['output']>
  first_free_minutes?: Maybe<Scalars['Int']['output']>
  hardware_types?: Maybe<Array<ModuleHardware>>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  start_date: Scalars['DateTime']['output']
  unlock_fee_percent_off?: Maybe<Scalars['Int']['output']>
}

export type Query = {
  __typename: 'Query'
  all_scheme_products?: Maybe<SchemeProductConnection>
  /** Get all service_areas regardless of state - super_admin only */
  all_service_areas: ServiceAreaConnection
  /** Get all zones regardless of status - super_admin only */
  all_zones: ZoneConnection
  /** Returns all archived jobs against a given asset */
  archive_jobs: ArchiveJobConnection
  /** Note: external_id will always be unique, so this endpoint returns a single asset */
  asset?: Maybe<Asset>
  asset_by_asset_tag?: Maybe<Asset>
  asset_models?: Maybe<Array<Maybe<AssetModel>>>
  /** Note: serial_number is not necessarily unique, so this endpoint might return multiple assets */
  assets: Array<Asset>
  /** Returns all job automations */
  automations?: Maybe<Array<Maybe<Automation>>>
  /** @deprecated bike is deprecated in favour of vehicle */
  bike?: Maybe<Bike>
  /** @deprecated bike is deprecated in favour of vehicle */
  bikes: BikeConnection
  commissioned_vehicles: VehicleConnection
  /**
   * Fetches vehicle config
   * @deprecated Use ota_device_information
   */
  config: MaptexConfig
  customers: UserConnection
  deployment?: Maybe<Deployment>
  /** Returns all deployments in desc chronological order */
  deployments?: Maybe<DeploymentConnection>
  existing_or_whitelist: ExistingOrWhitelist
  /** Returns all available firmware blobs */
  firmware: Array<Maybe<Firmware>>
  /**
   * Return all modules NOT in a zone that are locked & available & online & in normal power mode.
   * @deprecated Use free_floating_vehicles instead
   */
  free_floating_modules: ModuleConnection
  /** Return all vehicles NOT in a zone that are locked & available & online & in normal power mode. */
  free_floating_vehicles: VehicleConnection
  intercom_hash: Scalars['JSON']['output']
  invoice?: Maybe<IInvoice>
  invoices?: Maybe<IInvoiceConnection>
  /** Returns specific job */
  job?: Maybe<Job>
  job_types: JobTypes
  /** Returns all jobs - use job_filters as job_details_filters is deprecated */
  jobs: JobConnection
  /** Returns all jobs - use job_filters as job_details_filters is deprecated */
  jobs_v2: JobConnection
  journey?: Maybe<Journey>
  journeys: JourneyConnection
  module?: Maybe<Module>
  /** Fetches a module by its unlock_id */
  module_by_unlock_id?: Maybe<Module>
  /** @deprecated Use vehicles instead */
  modules: ModuleConnection
  orgs: OrgConnection
  ota_configuration_command?: Maybe<OTAConfigurationCommand>
  ota_configuration_commands?: Maybe<Array<Maybe<OTAConfigurationCommand>>>
  ota_device_information: OTAInformation
  ota_firmware?: Maybe<OTAFirmware>
  ota_firmwares?: Maybe<Array<Maybe<OTAFirmware>>>
  permitted_vehicle_models: Array<PermittedVehicleModel>
  role_types: Array<RoleType>
  scheme?: Maybe<Scheme>
  scheme_accounting: SchemeAccounting
  scheme_accountings: SchemeAccoutingConnection
  scheme_group: SchemeGroup
  scheme_groups: SchemeGroupConnection
  scheme_job_assets: Array<JobAsset>
  scheme_product?: Maybe<SchemeProduct>
  scheme_products?: Maybe<SchemeProductConnection>
  schemes?: Maybe<SchemeConnection>
  service_area?: Maybe<ServiceArea>
  service_areas: ServiceAreaConnection
  service_now_oauth_check?: Maybe<ServiceNowOauthCheck>
  service_now_oauth_url?: Maybe<ServiceNowOauthURL>
  service_offerings?: Maybe<Array<Maybe<ServiceOffering>>>
  /** Responds after given milliseconds so it can be used to test upstream timeouts. Only works in test and development, but returns instantly in staging and production. */
  sleep?: Maybe<Scalars['Boolean']['output']>
  staff: UserConnection
  /** Returns all available task codes */
  task_codes_list: Array<TaskCodeListItem>
  task_consumable_options: Array<TaskConsumableOption>
  user?: Maybe<User>
  users: UserConnection
  /** Get a vehicle from vehicle_id */
  vehicle?: Maybe<Vehicle>
  vehicle_by_module_id?: Maybe<Vehicle>
  vehicle_by_unlock_id?: Maybe<Vehicle>
  vehicles: VehicleConnection
  whitelist: Whitelist
  whitelists: WhitelistConnection
  workshop_users: UserConnection
  zone?: Maybe<Zone>
  zones: ZoneConnection
}

export type Queryall_scheme_productsArgs = {
  paginate?: InputMaybe<Paginate>
  scheme_id: Scalars['ID']['input']
}

export type Queryall_service_areasArgs = {
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
}

export type Queryall_zonesArgs = {
  is_suspended?: InputMaybe<Scalars['Boolean']['input']>
  location?: InputMaybe<LocationAreaInput>
  order_by?: InputMaybe<Array<InputMaybe<ZoneOrderBy>>>
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  where?: InputMaybe<Array<InputMaybe<ZoneWhere>>>
}

export type Queryarchive_jobsArgs = {
  asset_tag: Scalars['ID']['input']
  paginate?: InputMaybe<Paginate>
  sort_field?: InputMaybe<JobSortField>
  sort_order?: InputMaybe<Direction>
}

export type QueryassetArgs = {
  external_id: Scalars['ID']['input']
}

export type Queryasset_by_asset_tagArgs = {
  asset_tag: Scalars['ID']['input']
}

export type QueryassetsArgs = {
  serial_number: Scalars['ID']['input']
}

export type QueryautomationsArgs = {
  filters?: InputMaybe<AutomationFilters>
}

export type QuerybikeArgs = {
  frame_id?: InputMaybe<Scalars['ID']['input']>
  module_id?: InputMaybe<Scalars['ID']['input']>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
}

export type QuerybikesArgs = {
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
}

export type Querycommissioned_vehiclesArgs = {
  frame_id__like?: InputMaybe<Scalars['String']['input']>
  hardware_type__in?: InputMaybe<Array<ModuleHardware>>
  module_id__like?: InputMaybe<Scalars['String']['input']>
  paginate?: InputMaybe<Paginate>
  scheme_id: Scalars['ID']['input']
  state__in?: InputMaybe<Array<VehicleState>>
  unlock_id__like?: InputMaybe<Scalars['String']['input']>
}

export type QueryconfigArgs = {
  module_id: Scalars['ID']['input']
}

export type QuerycustomersArgs = {
  order_by?: InputMaybe<Array<InputMaybe<UserOrderby>>>
  paginate?: InputMaybe<Paginate>
  scheme_id: Scalars['ID']['input']
  where?: InputMaybe<Array<InputMaybe<UserWhere>>>
}

export type QuerydeploymentArgs = {
  deployment_id: Scalars['ID']['input']
}

export type QuerydeploymentsArgs = {
  paginate?: InputMaybe<Paginate>
}

export type Queryexisting_or_whitelistArgs = {
  email?: InputMaybe<Scalars['String']['input']>
}

export type Queryfree_floating_modulesArgs = {
  location?: InputMaybe<LocationAreaInput>
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
}

export type Queryfree_floating_vehiclesArgs = {
  location?: InputMaybe<LocationAreaInput>
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
}

export type QueryinvoiceArgs = {
  invoice_id?: InputMaybe<Scalars['ID']['input']>
}

export type QueryinvoicesArgs = {
  order_by?: InputMaybe<Array<InputMaybe<IInvoiceOrderby>>>
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  user_id?: InputMaybe<Scalars['ID']['input']>
  where?: InputMaybe<Array<InputMaybe<IInvoiceWhere>>>
}

export type QueryjobArgs = {
  job_uuid?: InputMaybe<Scalars['ID']['input']>
}

export type QueryjobsArgs = {
  job_details_filters?: InputMaybe<JobDetailsInput>
  job_filters?: InputMaybe<JobFiltersInput>
  paginate?: InputMaybe<Paginate>
  sort_field?: InputMaybe<JobSortField>
  sort_order?: InputMaybe<Direction>
}

export type Queryjobs_v2Args = {
  job_details_filters?: InputMaybe<JobDetailsInput>
  job_filters?: InputMaybe<JobFiltersInput>
  paginate?: InputMaybe<Paginate>
  sort_field?: InputMaybe<JobSortField>
  sort_order?: InputMaybe<Direction>
}

export type QueryjourneyArgs = {
  journey_id: Scalars['ID']['input']
}

export type QueryjourneysArgs = {
  module_id?: InputMaybe<Scalars['ID']['input']>
  order_by?: InputMaybe<Array<InputMaybe<JourneyOrderBy>>>
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  user_id?: InputMaybe<Scalars['ID']['input']>
  vehicle_id?: InputMaybe<Scalars['ID']['input']>
  where?: InputMaybe<Array<InputMaybe<JourneyWhere>>>
}

export type QuerymoduleArgs = {
  module_id: Scalars['ID']['input']
}

export type Querymodule_by_unlock_idArgs = {
  unlock_id: Scalars['ID']['input']
}

export type QuerymodulesArgs = {
  in_zone?: InputMaybe<Scalars['Boolean']['input']>
  location?: InputMaybe<LocationAreaInput>
  order_by?: InputMaybe<Array<InputMaybe<ModuleOrderby>>>
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  where?: InputMaybe<Array<InputMaybe<ModuleWhere>>>
}

export type QueryorgsArgs = {
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
}

export type Queryota_configuration_commandArgs = {
  id: Scalars['Int']['input']
}

export type Queryota_device_informationArgs = {
  module_id: Scalars['ID']['input']
}

export type Queryota_firmwareArgs = {
  id: Scalars['Int']['input']
}

export type Querypermitted_vehicle_modelsArgs = {
  scheme_id: Scalars['ID']['input']
}

export type QueryschemeArgs = {
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  scheme_name?: InputMaybe<Scalars['String']['input']>
}

export type Queryscheme_accountingArgs = {
  scheme_accounting_id: Scalars['ID']['input']
}

export type Queryscheme_groupArgs = {
  scheme_group_id: Scalars['ID']['input']
}

export type Queryscheme_job_assetsArgs = {
  scheme_id: Scalars['ID']['input']
}

export type Queryscheme_productArgs = {
  scheme_product_id?: InputMaybe<Scalars['ID']['input']>
}

export type Queryscheme_productsArgs = {
  paginate?: InputMaybe<Paginate>
  scheme_id: Scalars['ID']['input']
}

export type QueryschemesArgs = {
  paginate?: InputMaybe<Paginate>
  user_id?: InputMaybe<Scalars['ID']['input']>
}

export type Queryservice_areaArgs = {
  location?: InputMaybe<LocationInput>
  service_area_id?: InputMaybe<Scalars['ID']['input']>
}

export type Queryservice_areasArgs = {
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
}

export type QuerysleepArgs = {
  value: Scalars['Int']['input']
}

export type QuerystaffArgs = {
  order_by?: InputMaybe<Array<InputMaybe<UserOrderby>>>
  paginate?: InputMaybe<Paginate>
  scheme_id: Scalars['ID']['input']
  where?: InputMaybe<Array<InputMaybe<UserWhere>>>
}

export type Querytask_consumable_optionsArgs = {
  model_sys_id: Scalars['ID']['input']
}

export type QueryuserArgs = {
  user_id: Scalars['ID']['input']
}

export type QueryusersArgs = {
  order_by?: InputMaybe<Array<InputMaybe<UserOrderby>>>
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  where?: InputMaybe<Array<InputMaybe<UserWhere>>>
}

export type QueryvehicleArgs = {
  vehicle_id: Scalars['GUID']['input']
}

export type Queryvehicle_by_module_idArgs = {
  module_id: Scalars['ID']['input']
}

export type Queryvehicle_by_unlock_idArgs = {
  unlock_id: Scalars['ID']['input']
}

export type QueryvehiclesArgs = {
  location?: InputMaybe<LocationAreaInput>
  paginate?: InputMaybe<Paginate>
  propulsion_type?: InputMaybe<Array<VehiclePropulsionType>>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  vehicle_type?: InputMaybe<VehicleType>
}

export type QuerywhitelistArgs = {
  whitelist_id: Scalars['ID']['input']
}

export type QuerywhitelistsArgs = {
  order_by?: InputMaybe<Array<InputMaybe<WhitelistOrderby>>>
  paginate?: InputMaybe<Paginate>
  scheme_id: Scalars['ID']['input']
  where?: InputMaybe<Array<InputMaybe<WhitelistWhere>>>
}

export type Queryworkshop_usersArgs = {
  order_by?: InputMaybe<Array<InputMaybe<UserOrderby>>>
  paginate?: InputMaybe<Paginate>
  where?: InputMaybe<Array<InputMaybe<UserWhere>>>
}

export type QueryzoneArgs = {
  zone_id: Scalars['ID']['input']
}

export type QueryzonesArgs = {
  location?: InputMaybe<LocationAreaInput>
  order_by?: InputMaybe<Array<InputMaybe<ZoneOrderBy>>>
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  where?: InputMaybe<Array<InputMaybe<ZoneWhere>>>
}

export type RefundInvoiceInput = {
  currency_amount: Scalars['Int']['input']
  description: Scalars['String']['input']
  minute_amount: Scalars['Int']['input']
  reason: Scalars['String']['input']
  revoke_product: Scalars['Boolean']['input']
}

export type RefundInvoiceResult = {
  __typename: 'RefundInvoiceResult'
  currency?: Maybe<RefundResultItem>
  minutes?: Maybe<RefundResultItem>
}

export type RefundInvoiceV2Input = {
  description: Scalars['String']['input']
  reason: Scalars['String']['input']
  refund_transactions: Array<RefundInvoiceV2InputTransaction>
  revoke_product: Scalars['Boolean']['input']
}

export type RefundInvoiceV2InputTransaction = {
  amount: Scalars['Int']['input']
  id: Scalars['ID']['input']
}

export type RefundResultItem = {
  __typename: 'RefundResultItem'
  amount?: Maybe<Scalars['Int']['output']>
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type RemoteNodeOp = {
  id?: InputMaybe<ScalarOp>
}

export type RequiredOTAVersions = {
  __typename: 'RequiredOTAVersions'
  ecu?: Maybe<Scalars['String']['output']>
  iot?: Maybe<Scalars['String']['output']>
  meter?: Maybe<Scalars['String']['output']>
}

export type ReverseCancelledJourneyInput = {
  /** Amended journey duration in minutes */
  amended_duration?: InputMaybe<Scalars['Int']['input']>
  description: Scalars['String']['input']
}

export type Role = {
  __typename: 'Role'
  id: Scalars['ID']['output']
  org?: Maybe<Org>
  scheme?: Maybe<Scheme>
  type: RoleType
  user: User
}

export type RoleInput = {
  org_id?: InputMaybe<Scalars['ID']['input']>
  role_type_id: Scalars['ID']['input']
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  user_id: Scalars['ID']['input']
}

export type RoleType = {
  __typename: 'RoleType'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export type RoleTypeWhere = {
  id?: InputMaybe<ScalarOp>
}

export type RoleWhere = {
  type?: InputMaybe<RoleTypeWhere>
}

export type ScalarOp = {
  eq?: InputMaybe<Scalars['String']['input']>
  ge?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  ilike?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  is_not_null?: InputMaybe<Scalars['Boolean']['input']>
  is_null?: InputMaybe<Scalars['Boolean']['input']>
  le?: InputMaybe<Scalars['String']['input']>
  like?: InputMaybe<Scalars['String']['input']>
  lt?: InputMaybe<Scalars['String']['input']>
  ne?: InputMaybe<Scalars['String']['input']>
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type Scheme = {
  __typename: 'Scheme'
  availability_restriction: SchemeAvailabilityRestrictionConnection
  config: SchemeConfig
  id: Scalars['ID']['output']
  is_discoverable: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  orgs?: Maybe<OrgConnection>
  permitted_vehicle_models: Array<PermittedVehicleModel>
  promotions: Array<Promotion>
  scheme_accounting?: Maybe<SchemeAccounting>
  service_areas: ServiceAreaConnection
  timezone: Scalars['String']['output']
  zones: ZoneConnection
}

export type SchemepromotionsArgs = {
  user_id?: InputMaybe<Scalars['ID']['input']>
}

export type Schemeservice_areasArgs = {
  paginate?: InputMaybe<Paginate>
}

export type SchemezonesArgs = {
  location?: InputMaybe<LocationAreaInput>
  paginate?: InputMaybe<Paginate>
}

export type SchemeAccounting = {
  __typename: 'SchemeAccounting'
  /** A lowercase, 3 character ISO 4217 Currency Code */
  currency: Scalars['String']['output']
  id: Scalars['ID']['output']
  inclusive_of_tax: Scalars['Boolean']['output']
  scheme?: Maybe<Scheme>
  scheme_group?: Maybe<SchemeGroup>
  tax_rate?: Maybe<Scalars['Float']['output']>
}

export type SchemeAccountingInput = {
  currency: Scalars['String']['input']
  inclusive_of_tax: Scalars['Boolean']['input']
  scheme_group_id?: InputMaybe<Scalars['ID']['input']>
  scheme_id: Scalars['ID']['input']
  tax_rate: Scalars['Float']['input']
}

export type SchemeAccoutingConnection = {
  __typename: 'SchemeAccoutingConnection'
  nodes: Array<Maybe<SchemeAccounting>>
  pagination: Pagination
}

export type SchemeAvailabilityRestriction = {
  __typename: 'SchemeAvailabilityRestriction'
  end_time: Scalars['String']['output']
  frame_type?: Maybe<FrameType>
  id: Scalars['ID']['output']
  last_status_change_time?: Maybe<Scalars['String']['output']>
  propulsion_type: VehiclePropulsionType
  start_time: Scalars['String']['output']
  vehicle_type: VehicleType
}

export type SchemeAvailabilityRestrictionConnection = {
  __typename: 'SchemeAvailabilityRestrictionConnection'
  nodes: Array<SchemeAvailabilityRestriction>
  pagination: Pagination
}

export type SchemeAvailabilityRestrictionInput = {
  end_time: Scalars['String']['input']
  frame_type: FrameType
  propulsion_type: VehiclePropulsionType
  scheme_id: Scalars['ID']['input']
  start_time: Scalars['String']['input']
  vehicle_type: VehicleType
}

export type SchemeBalance = {
  __typename: 'SchemeBalance'
  balance: FormattedAmount
  scheme_id: Scalars['ID']['output']
}

export type SchemeConfig = {
  __typename: 'SchemeConfig'
  is_max_capacity_zones_enabled: Scalars['Boolean']['output']
  is_vehicle_rewards_enabled: Scalars['Boolean']['output']
  sponsorship_content?: Maybe<SponsorshipContent>
  supported_unlock_methods: Array<UnlockMethod>
}

export type SchemeConnection = {
  __typename: 'SchemeConnection'
  nodes: Array<Scheme>
  pagination: Pagination
}

export type SchemeGroup = {
  __typename: 'SchemeGroup'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SchemeGroupConnection = {
  __typename: 'SchemeGroupConnection'
  nodes: Array<Maybe<SchemeGroup>>
  pagination: Pagination
}

export type SchemeGroupInput = {
  name: Scalars['String']['input']
}

export type SchemeInput = {
  id: Scalars['ID']['input']
  /** Should the scheme be discoverable on the public GBFS index */
  is_discoverable?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  /** The timezone where the scheme is located as a string, defaults to Europe/London */
  timezone?: InputMaybe<Scalars['String']['input']>
}

export type SchemeProduct = {
  __typename: 'SchemeProduct'
  /** Price in cents */
  amount: Scalars['Int']['output']
  /** How many journeys can be billed with this pass per sub period */
  billable_journey_cap?: Maybe<Scalars['Int']['output']>
  /** Time period for the billable journey cap */
  billable_journey_cap_sub_period?: Maybe<Scalars['String']['output']>
  /**
   * Parameters used by Journey charge calculator defined by hardware_type
   * @deprecated Use configs_v3
   */
  configs_V2: SchemeProductConfigByHardwareTypeV2
  /** Parameters used by Journey charge calculator defined by vehicle_category */
  configs_v3: SchemeProductConfigByVehicleCategory
  /** Extra details for displaying product information. Only enforced for PeriodPass at the moment */
  details?: Maybe<SchemeProductDetails>
  /** This field is present if fetching scheme_products through a valid voucher */
  discount_calculations?: Maybe<DiscountCalculations>
  /** Is this scheme product gifted when a reward is confirmed? */
  fulfill_rewards: Array<VehicleRewardType>
  id: Scalars['ID']['output']
  /** Whether the product is on offer */
  is_active: Scalars['Boolean']['output']
  /** Whether product should be shown in public listings */
  is_discoverable?: Maybe<Scalars['Boolean']['output']>
  /** Whether the product should be protected by the role_whitelist */
  is_protected?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  /** Formatted amount */
  price: FormattedAmount
  product_type: ProductType
  /** Amount of items or minutes */
  quantity: Scalars['Int']['output']
  /** A whitelist of role_types that a user must have in order to purchase the protected product */
  role_whitelist: Array<Scalars['String']['output']>
  scheme_accounting: SchemeAccounting
  scheme_id: Scalars['ID']['output']
  /** Static ID that can be saved by BMan and propagated between environments */
  uuid?: Maybe<Scalars['GUID']['output']>
  /** The period which the scheme product is valid for */
  validity_interval?: Maybe<Scalars['String']['output']>
}

export type SchemeProductConfig = {
  __typename: 'SchemeProductConfig'
  charge_increment_duration: Scalars['String']['output']
  journey_minutes_percent_off: Scalars['Int']['output']
  minutes_chargeable: Scalars['Boolean']['output']
  out_of_bundle_minute_increment_cost: FormattedAmount
  out_of_service_area_parking_charge: FormattedAmount
  out_of_zone_parking_charge: FormattedAmount
  period_pass_max_minute_debit?: Maybe<Scalars['Int']['output']>
  period_pass_sub_period_for_unlocks?: Maybe<Scalars['String']['output']>
  period_pass_unlocks_per_sub_period?: Maybe<Scalars['Int']['output']>
  time_increment_cost: FormattedAmount
  undocked_in_zone_parking_charge: FormattedAmount
  unlock_fee: FormattedAmount
}

export type SchemeProductConfigByHardwareTypeV2 = {
  __typename: 'SchemeProductConfigByHardwareTypeV2'
  bbe?: Maybe<SchemeProductConfig>
  beryl_bike?: Maybe<SchemeProductConfig>
  scooter?: Maybe<SchemeProductConfig>
}

export type SchemeProductConfigByVehicleCategory = {
  __typename: 'SchemeProductConfigByVehicleCategory'
  bike?: Maybe<SchemeProductConfig>
  cargo_ebike?: Maybe<SchemeProductConfig>
  ebike?: Maybe<SchemeProductConfig>
  escooter?: Maybe<SchemeProductConfig>
}

export type SchemeProductConfigInput = {
  /** The interval at which to incur time_increment_cost Example PT1M */
  charge_increment_duration?: InputMaybe<Scalars['String']['input']>
  /** Minute bundle percent discount, for making it cheaper for certain hardware types */
  journey_minutes_percent_off?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether minutes can be charged */
  minutes_chargeable?: InputMaybe<Scalars['Boolean']['input']>
  /** Cost of minutes when bundle runs out */
  out_of_bundle_minute_increment_cost?: InputMaybe<Scalars['Int']['input']>
  /** Penalty price for out or service area parking - pence */
  out_of_service_area_parking_charge: Scalars['Int']['input']
  /** Penalty price for out of bay parking - pence */
  out_of_zone_parking_charge: Scalars['Int']['input']
  /** The maximum minutes of journey duration to be debited from a period pass */
  period_pass_max_minute_debit?: InputMaybe<Scalars['Int']['input']>
  /** The smaller time period within the duration of a period pass by which included unlocks are counted */
  period_pass_sub_period_for_unlocks?: InputMaybe<Scalars['String']['input']>
  /** The number of unlocks that are included within a sub-period of the period pass */
  period_pass_unlocks_per_sub_period?: InputMaybe<Scalars['Int']['input']>
  /** The cost per charge_increment_duration */
  time_increment_cost?: InputMaybe<Scalars['Int']['input']>
  /** Fine to charge if the bike is in zone but not docked at the end of journey */
  undocked_in_zone_parking_charge?: InputMaybe<Scalars['Int']['input']>
  /** Price to unlock - pence */
  unlock_fee?: InputMaybe<Scalars['Int']['input']>
}

export type SchemeProductConfigsV3Input = {
  bike?: InputMaybe<SchemeProductConfigInput>
  cargo_ebike?: InputMaybe<SchemeProductConfigInput>
  ebike?: InputMaybe<SchemeProductConfigInput>
  escooter?: InputMaybe<SchemeProductConfigInput>
}

export type SchemeProductConnection = {
  __typename: 'SchemeProductConnection'
  nodes: Array<SchemeProduct>
  pagination: Pagination
}

export type SchemeProductDetails = {
  __typename: 'SchemeProductDetails'
  benefit_sentence?: Maybe<Scalars['String']['output']>
  bullets?: Maybe<Array<Scalars['String']['output']>>
  cta_text?: Maybe<Scalars['String']['output']>
  /** Image lookup key */
  image_variant: ProductImageVariant
  sub_title: Scalars['String']['output']
  /** Override SchemeProduct.name */
  title: Scalars['String']['output']
}

export type SchemeProductDetailsInput = {
  benefit_sentence?: InputMaybe<Scalars['String']['input']>
  bullets?: InputMaybe<Array<Scalars['String']['input']>>
  cta_text?: InputMaybe<Scalars['String']['input']>
  image_variant: ProductImageVariant
  sub_title: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type SchemeProductInput = {
  amount: Scalars['Int']['input']
  billable_journey_cap?: InputMaybe<Scalars['Int']['input']>
  billable_journey_cap_sub_period?: InputMaybe<Scalars['String']['input']>
  configs_v3: SchemeProductConfigsV3Input
  details?: InputMaybe<SchemeProductDetailsInput>
  fulfill_rewards?: InputMaybe<Array<VehicleRewardType>>
  is_active: Scalars['Boolean']['input']
  is_discoverable?: InputMaybe<Scalars['Boolean']['input']>
  is_protected?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  product_type: ProductType
  quantity?: InputMaybe<Scalars['Int']['input']>
  role_whitelist?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  scheme_id: Scalars['ID']['input']
  uuid?: InputMaybe<Scalars['GUID']['input']>
  validity_interval?: InputMaybe<Scalars['String']['input']>
}

export type ScooterModuleState = IModuleState & {
  __typename: 'ScooterModuleState'
  battery_id?: Maybe<Scalars['String']['output']>
  ecu_error?: Maybe<EcuError>
  id: Scalars['ID']['output']
  ignition_on?: Maybe<Scalars['Boolean']['output']>
  max_speed?: Maybe<Scalars['Int']['output']>
  orientation?: Maybe<Scalars['String']['output']>
  status_charging_current?: Maybe<Scalars['Boolean']['output']>
  status_front_light?: Maybe<Scalars['Boolean']['output']>
  status_rear_light?: Maybe<Scalars['Boolean']['output']>
  time: Scalars['DateTime']['output']
}

export type SegwayEBikeConfig = {
  __typename: 'SegwayEBikeConfig'
  battery_lock_Version?: Maybe<Scalars['String']['output']>
  ble_sw?: Maybe<Scalars['String']['output']>
  bms_version?: Maybe<Scalars['String']['output']>
  cable_lock_version?: Maybe<Scalars['String']['output']>
  charge_port_version?: Maybe<Scalars['String']['output']>
  ecu_version?: Maybe<Scalars['String']['output']>
  gnss_version?: Maybe<Scalars['String']['output']>
  helmet_lock_version?: Maybe<Scalars['String']['output']>
  iot_sw?: Maybe<Scalars['String']['output']>
  meter_version?: Maybe<Scalars['String']['output']>
  monitor_version?: Maybe<Scalars['String']['output']>
  server_address?: Maybe<Scalars['String']['output']>
  server_port?: Maybe<Scalars['String']['output']>
  time?: Maybe<Scalars['DateTime']['output']>
  voice_version?: Maybe<Scalars['String']['output']>
}

export type ServiceArea = IServiceArea & {
  __typename: 'ServiceArea'
  applies_to: Array<VehicleType>
  effective_from?: Maybe<Scalars['String']['output']>
  effective_to?: Maybe<Scalars['String']['output']>
  external_id?: Maybe<Scalars['ID']['output']>
  geom: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  is_external: Scalars['Boolean']['output']
  is_parking_allowed: Scalars['Boolean']['output']
  name?: Maybe<Scalars['String']['output']>
  scheme: Scheme
  service_area_type: ServiceAreaType
  speed_limit: Scalars['Float']['output']
}

export type ServiceAreaConnection = {
  __typename: 'ServiceAreaConnection'
  geojson: Scalars['JSON']['output']
  nodes: Array<ServiceArea>
  pagination: Pagination
}

export type ServiceAreaConnectionnodesArgs = {
  merge_service_areas?: InputMaybe<Scalars['Boolean']['input']>
  omit_restricted_areas?: InputMaybe<Scalars['Boolean']['input']>
}

export type ServiceAreaInput = {
  effective_from?: InputMaybe<Scalars['String']['input']>
  effective_to?: InputMaybe<Scalars['String']['input']>
  external_id?: InputMaybe<Scalars['ID']['input']>
  geom: Scalars['JSON']['input']
  is_external?: InputMaybe<Scalars['Boolean']['input']>
  is_parking_allowed?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  scheme_id: Scalars['ID']['input']
  service_area_type?: InputMaybe<ServiceAreaType>
  speed_limit?: InputMaybe<Scalars['Float']['input']>
}

export type ServiceAreaPolicyInput = {
  applies_to?: InputMaybe<Array<VehicleType>>
  effective_from?: InputMaybe<Scalars['String']['input']>
  effective_to?: InputMaybe<Scalars['String']['input']>
  is_parking_allowed: Scalars['Boolean']['input']
  service_area_type?: InputMaybe<ServiceAreaType>
  speed_limit: Scalars['Float']['input']
}

export type ServiceAreaSyncResult = {
  __typename: 'ServiceAreaSyncResult'
  added: Array<IServiceArea>
  deleted: Array<ServiceArea>
  dry_run: Scalars['Boolean']['output']
  updated: Array<ServiceArea>
}

export enum ServiceAreaType {
  go_slow = 'go_slow',
  no_go = 'no_go',
  normal = 'normal',
  segment = 'segment',
}

export type ServiceNowOauthCheck = {
  __typename: 'ServiceNowOauthCheck'
  authorized: Scalars['Boolean']['output']
  login_url: Scalars['String']['output']
}

export type ServiceNowOauthURL = {
  __typename: 'ServiceNowOauthURL'
  login_url: Scalars['String']['output']
}

export type ServiceOffering = {
  __typename: 'ServiceOffering'
  name: Scalars['String']['output']
  sys_id: Scalars['ID']['output']
}

export type SetupIntent = {
  __typename: 'SetupIntent'
  client_secret?: Maybe<Scalars['String']['output']>
}

export type SoakSuite = {
  __typename: 'SoakSuite'
  status: SoakTestStatus
  tests: Array<Maybe<SoakTest>>
}

export type SoakTest = {
  __typename: 'SoakTest'
  detail: Scalars['String']['output']
  name: Scalars['String']['output']
  status: SoakTestStatus
}

export enum SoakTestStatus {
  /** Fail */
  fail = 'fail',
  /** In progress */
  in_progress = 'in_progress',
  /** Success */
  success = 'success',
  /** Waiting for data */
  waiting_for_data = 'waiting_for_data',
}

export type SponsorshipContent = {
  __typename: 'SponsorshipContent'
  drawer_cta_text?: Maybe<Scalars['String']['output']>
  sponsor_url?: Maybe<Scalars['String']['output']>
  sponsorship_tile?: Maybe<SponsorshipTile>
}

export type SponsorshipTile = {
  __typename: 'SponsorshipTile'
  image_cta_text?: Maybe<Scalars['String']['output']>
  image_url?: Maybe<Scalars['String']['output']>
}

export type TagWhere = {
  deleted?: InputMaybe<BooleanOp>
  id?: InputMaybe<ScalarOp>
  name?: InputMaybe<ScalarOp>
}

export type Task = {
  __typename: 'Task'
  details?: Maybe<TaskDetails>
  timestamps?: Maybe<Timestamps>
  uuid: Scalars['ID']['output']
}

export type TaskCodeItem = {
  __typename: 'TaskCodeItem'
  code: Scalars['String']['output']
  reason: TaskCodeReason
}

export type TaskCodeItemInput = {
  code: Scalars['String']['input']
  reason: TaskCodeReason
}

export type TaskCodeListItem = {
  __typename: 'TaskCodeListItem'
  code: Scalars['String']['output']
  description: Scalars['String']['output']
  vehicle_type: Array<ModuleHardware>
}

export enum TaskCodeReason {
  other = 'other',
  vandalism = 'vandalism',
}

export type TaskConsumable = {
  __typename: 'TaskConsumable'
  quantity: Scalars['Int']['output']
  sku: Scalars['ID']['output']
}

export type TaskConsumableInput = {
  quantity: Scalars['Int']['input']
  sku: Scalars['ID']['input']
}

export type TaskConsumableOption = {
  __typename: 'TaskConsumableOption'
  name: Scalars['String']['output']
  sku: Scalars['ID']['output']
}

export type TaskDetails = {
  __typename: 'TaskDetails'
  allow_parts_tracking: Scalars['Boolean']['output']
  assignee?: Maybe<User>
  consumables?: Maybe<Array<TaskConsumable>>
  description?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  parts?: Maybe<Array<Maybe<PartDetail>>>
  replacements?: Maybe<Array<TaskReplacement>>
  status?: Maybe<TaskStatusEnum>
  task_codes_details?: Maybe<Array<TaskCodeItem>>
  title?: Maybe<Scalars['String']['output']>
  vandalism_notes?: Maybe<Scalars['String']['output']>
}

export type TaskDetailsInput = {
  allow_parts_tracking?: InputMaybe<Scalars['Boolean']['input']>
  assignee?: InputMaybe<Scalars['String']['input']>
  consumables?: InputMaybe<Array<TaskConsumableInput>>
  description?: InputMaybe<Scalars['String']['input']>
  faultIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  notes?: InputMaybe<Scalars['String']['input']>
  parts?: InputMaybe<Array<InputMaybe<PartInput>>>
  replacements?: InputMaybe<Array<TaskReplacementInput>>
  status?: InputMaybe<TaskStatusEnum>
  task_codes_details?: InputMaybe<Array<TaskCodeItemInput>>
  title?: InputMaybe<Scalars['String']['input']>
}

export type TaskReplacement = {
  __typename: 'TaskReplacement'
  modelName: Scalars['String']['output']
  modelSysId: Scalars['ID']['output']
  new: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  previous?: Maybe<Scalars['String']['output']>
  reusability?: Maybe<TaskReplacementReusability>
}

export type TaskReplacementInput = {
  modelName: Scalars['String']['input']
  modelSysId: Scalars['ID']['input']
  new: Scalars['String']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  previous?: InputMaybe<Scalars['String']['input']>
  reusability?: InputMaybe<TaskReplacementReusability>
}

export enum TaskReplacementReusability {
  non_reusable = 'non_reusable',
  reusable_with_repair = 'reusable_with_repair',
  reusable_without_repair = 'reusable_without_repair',
}

export enum TaskStatusEnum {
  blocked = 'blocked',
  complete = 'complete',
  new = 'new',
}

export type Telemetry = {
  __typename: 'Telemetry'
  altitude?: Maybe<Scalars['Float']['output']>
  ambient_temperature: Scalars['Float']['output']
  battery_percent: Scalars['Float']['output']
  battery_voltage?: Maybe<Scalars['Float']['output']>
  drive?: Maybe<Drive>
  dyno_pulses: Scalars['Int']['output']
  fix_invalid: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  last_fix_time?: Maybe<Scalars['DateTime']['output']>
  latitude?: Maybe<Scalars['Float']['output']>
  longitude?: Maybe<Scalars['Float']['output']>
  max_error?: Maybe<Scalars['Float']['output']>
  time: Scalars['DateTime']['output']
}

export type TelemetryOrderby = {
  altitude?: InputMaybe<Direction>
  ambient_temperature?: InputMaybe<Direction>
  battery_percent?: InputMaybe<Direction>
  battery_voltage?: InputMaybe<Direction>
  dyno_pulses?: InputMaybe<Direction>
  fix_invalid?: InputMaybe<Direction>
  id?: InputMaybe<Direction>
  last_fix_time?: InputMaybe<Direction>
  latitude?: InputMaybe<Direction>
  longitude?: InputMaybe<Direction>
  max_error?: InputMaybe<Direction>
  time?: InputMaybe<Direction>
}

export type TelemetryWhere = {
  altitude?: InputMaybe<ScalarOp>
  battery_percent?: InputMaybe<ScalarOp>
  battery_voltage?: InputMaybe<ScalarOp>
  dyno_pulses?: InputMaybe<ScalarOp>
  fix_invalid?: InputMaybe<ScalarOp>
  id?: InputMaybe<ScalarOp>
  last_fix_time?: InputMaybe<ScalarOp>
  latitude?: InputMaybe<ScalarOp>
  longitude?: InputMaybe<ScalarOp>
  max_error?: InputMaybe<ScalarOp>
  time?: InputMaybe<ScalarOp>
}

export type Timestamps = {
  __typename: 'Timestamps'
  created_at: Scalars['DateTime']['output']
  deleted_at?: Maybe<Scalars['DateTime']['output']>
  updated_at: Scalars['DateTime']['output']
}

export enum TransactionCategory {
  /** If this transaction is a deduction due to a discount */
  discount = 'discount',
  /** Any other transaction not one of the special types */
  generic = 'generic',
  /** If this transaction is a record of a provider refund */
  refund = 'refund',
  /** If this transaction is a record of a provider charge reversal */
  reversal = 'reversal',
  /** This is used by internal services to indicate a transaction used to settled deficit */
  settler = 'settler',
  /** If an amount is too small to charge we waive the transaction */
  waiver = 'waiver',
}

export type TransactionConnection = {
  __typename: 'TransactionConnection'
  nodes: Array<ITransaction>
  pagination: Pagination
}

export type TransactionMeta = {
  __typename: 'TransactionMeta'
  charge_type?: Maybe<TransactionMetaChargeType>
  descriptor?: Maybe<TransactionCategory>
  failure_reason?: Maybe<Scalars['String']['output']>
  minutes_charged?: Maybe<Scalars['Int']['output']>
  refund_description?: Maybe<Scalars['String']['output']>
  requester_id?: Maybe<Scalars['ID']['output']>
  time_increment_cost?: Maybe<Scalars['Int']['output']>
  transaction_label?: Maybe<Scalars['String']['output']>
}

export enum TransactionMetaChargeType {
  /** Whenever a penalty is charged for cancelling a journey */
  cancel_journey_penalty = 'cancel_journey_penalty',
  /** Minutes refunded to a user */
  minute_refund = 'minute_refund',
  /** When ever minutes are credited to an account */
  minute_topup = 'minute_topup',
  out_of_service_area_charge = 'out_of_service_area_charge',
  out_of_zone_charge = 'out_of_zone_charge',
  /** Fee charged when vehicle is parked in a zone, but not front locked. where mandated */
  partially_locked_in_zone_parking_charge = 'partially_locked_in_zone_parking_charge',
  /** Whenever a product is purchased eg minute bundle, day pass */
  product_purchase = 'product_purchase',
  /** Tax (if applicable) */
  tax = 'tax',
  time_based_charge = 'time_based_charge',
  time_based_minute_charge = 'time_based_minute_charge',
  /** If a user exceeds their minute balance while on a journey we make a time based charge */
  time_based_overflow_charge = 'time_based_overflow_charge',
  time_based_period_pass_charge = 'time_based_period_pass_charge',
  /**
   * Fee charged when vehicle is parked in a zone, but not docked
   * @deprecated use partially_locked_in_zone_parking_charge
   */
  undocked_in_zone_parking_charge = 'undocked_in_zone_parking_charge',
  unlock_fee = 'unlock_fee',
  /** Fee charged when vehicle is parked in a zone but the vehicle type is not permitted */
  vehicle_not_permitted_in_zone_charge = 'vehicle_not_permitted_in_zone_charge',
  /** Fee charged when vehicle is parked in a zone at maximum capacity */
  zone_max_capacity_charge = 'zone_max_capacity_charge',
}

export type TransactionResult = {
  __typename: 'TransactionResult'
  created_at?: Maybe<Scalars['DateTime']['output']>
  failure_code?: Maybe<Scalars['String']['output']>
  failure_message?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  status?: Maybe<TransactionResultType>
}

export enum TransactionResultType {
  /** The transaction failed with provider */
  failed = 'failed',
  /** The tansaction has not been actioned with provider */
  pending = 'pending',
  /** The transaction succeeded with provider */
  succeeded = 'succeeded',
}

export enum TransactionType {
  /** Currency */
  currency_transaction = 'currency_transaction',
  /** Minute */
  minute_transaction = 'minute_transaction',
}

export enum UnlockMethod {
  manual = 'manual',
  nfc = 'nfc',
  qr = 'qr',
}

export type User = {
  __typename: 'User'
  /** Exemptions from charges in a scheme */
  charge_exemptions: Array<ChargeExemption>
  /** Is the account in a state where we allow deletion */
  delete_permission: DeletePermission
  deleted?: Maybe<Scalars['Boolean']['output']>
  driving_licence_check?: Maybe<DrivingLicenceCheck>
  email?: Maybe<Scalars['String']['output']>
  /** User has verified a Driving licence since we started checking DoB */
  has_birthdate: Scalars['Boolean']['output']
  has_valid_licence: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  /** When was the last time they validated their selfie? ISO8610 */
  identity_checked_at?: Maybe<Scalars['DateTime']['output']>
  /** @deprecated Use top level `intercom_hash` resolver instead. */
  intercom_hash: Scalars['JSON']['output']
  invoices?: Maybe<IInvoiceConnection>
  /** Account disabled on Firebase (via User service) */
  is_blocked: Scalars['Boolean']['output']
  /** User is known to be over 18 */
  is_over_18: Scalars['Boolean']['output']
  is_whitelisted?: Maybe<Scalars['Boolean']['output']>
  journeys: JourneyConnection
  /** The onfido applicant details for a user */
  onfido_applicant?: Maybe<OnfidoApplicant>
  outstanding_invoices?: Maybe<IInvoiceConnection>
  passes?: Maybe<UserProductConnection>
  payer?: Maybe<Payer>
  /** An account has been created for this user on Persona */
  persona_enrolled: Scalars['Boolean']['output']
  /** A device has been enrolled for this user on Persona */
  persona_enrolled_device: Scalars['Boolean']['output']
  phone_number?: Maybe<Scalars['String']['output']>
  /** Returns the best valid promotion for a given scheme */
  promotion?: Maybe<Promotion>
  promotions: Array<Promotion>
  roles?: Maybe<Array<Maybe<Role>>>
  user_products?: Maybe<UserProductConnection>
  /** UserScheme associations where the user is active */
  user_schemes: Array<UserScheme>
  /** uuid is a secondary id for external use. eg MDS/DFT */
  uuid: Scalars['GUID']['output']
  wallet?: Maybe<Array<Maybe<Wallet>>>
}

export type Usercharge_exemptionsArgs = {
  scheme_id?: InputMaybe<Scalars['ID']['input']>
}

export type UserinvoicesArgs = {
  order_by?: InputMaybe<IInvoiceOrderby>
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  where?: InputMaybe<IInvoiceWhere>
}

export type UserjourneysArgs = {
  paginate?: InputMaybe<Paginate>
  scheme_id: Scalars['ID']['input']
}

export type Useroutstanding_invoicesArgs = {
  order_by?: InputMaybe<IInvoiceOrderby>
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  where?: InputMaybe<IInvoiceWhere>
}

export type UserpassesArgs = {
  scheme_id: Scalars['ID']['input']
}

export type UserpromotionArgs = {
  scheme_id: Scalars['ID']['input']
}

export type UserpromotionsArgs = {
  scheme_id: Scalars['ID']['input']
}

export type UserrolesArgs = {
  scheme_id?: InputMaybe<Scalars['ID']['input']>
}

export type Useruser_productsArgs = {
  paginate?: InputMaybe<Paginate>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
}

export type UserwalletArgs = {
  scheme_id: Scalars['ID']['input']
}

export type UserConnection = {
  __typename: 'UserConnection'
  nodes: Array<User>
  pagination: Pagination
}

export type UserInput = {
  email?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type UserOrderby = {
  email?: InputMaybe<Direction>
  id?: InputMaybe<Direction>
}

export type UserProduct = {
  __typename: 'UserProduct'
  /** Minute balances for active passes */
  balances?: Maybe<PeriodPassBalances>
  created_at?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  invoice?: Maybe<ProductInvoice>
  is_bonus: Scalars['Boolean']['output']
  /**
   * Total period pass minute balance
   * @deprecated Use balances field
   */
  period_pass_balance?: Maybe<FormattedAmount>
  /**
   * Minute balance for this period pass
   * @deprecated Use balances field
   */
  period_pass_current_balance?: Maybe<FormattedAmount>
  /** If there is a journey cap, how many have been used */
  period_pass_journey_cap?: Maybe<PeriodPassCapInfo>
  /** Included unlocks used in this sub period */
  period_pass_unlocks?: Maybe<Array<PeriodPassUnlocks>>
  reward?: Maybe<VehicleReward>
  scheme_product: SchemeProduct
  user: User
  valid_until?: Maybe<Scalars['DateTime']['output']>
}

export type UserProductConnection = {
  __typename: 'UserProductConnection'
  nodes: Array<UserProduct>
  pagination: Pagination
}

export type UserScheme = {
  __typename: 'UserScheme'
  scheme?: Maybe<Scheme>
  scheme_id: Scalars['ID']['output']
  user_id: Scalars['ID']['output']
}

export type UserWhere = {
  email?: InputMaybe<ScalarOp>
  id?: InputMaybe<ScalarOp>
  phone_number?: InputMaybe<ScalarOp>
  roles?: InputMaybe<RoleWhere>
}

export type Vehicle = {
  __typename: 'Vehicle'
  detail: VehicleDetail
  ebike_estimated_range?: Maybe<Scalars['Float']['output']>
  frame_id: Scalars['ID']['output']
  frame_model?: Maybe<Scalars['String']['output']>
  frame_type: Scalars['String']['output']
  frame_year?: Maybe<Scalars['Int']['output']>
  hardware_type: ModuleHardware
  id: Scalars['GUID']['output']
  /** @deprecated Use frame_model */
  model?: Maybe<Scalars['String']['output']>
  propulsion_type: Array<VehiclePropulsionType>
  rewards: Array<VehicleReward>
  state?: Maybe<VehicleStateEvent>
  state_history?: Maybe<VehicleStateEventConnection>
  time: Scalars['DateTime']['output']
  vehicle_type: VehicleType
}

export type Vehiclestate_historyArgs = {
  paginate?: InputMaybe<Paginate>
}

export type VehicleConnection = {
  __typename: 'VehicleConnection'
  nodes: Array<Vehicle>
  pagination: Pagination
}

export type VehicleDetail = {
  __typename: 'VehicleDetail'
  id: Scalars['ID']['output']
  lock_configuration: LockConfiguration
  module?: Maybe<Module>
  scheme?: Maybe<Scheme>
  time: Scalars['DateTime']['output']
  vehicle_unlock_id?: Maybe<Scalars['String']['output']>
}

export type VehicleDetailUpdateInput = {
  vehicle_unlock_id?: InputMaybe<Scalars['String']['input']>
}

export enum VehicleLockState {
  front_lock_only = 'front_lock_only',
  lock_fault = 'lock_fault',
  locked = 'locked',
  rear_lock_only = 'rear_lock_only',
  unknown = 'unknown',
  unlocked = 'unlocked',
}

export enum VehiclePropulsionType {
  electric = 'electric',
  electric_assist = 'electric_assist',
  human = 'human',
}

export type VehicleReward = {
  __typename: 'VehicleReward'
  description: Scalars['String']['output']
  title: Scalars['String']['output']
  type: VehicleRewardType
}

export enum VehicleRewardType {
  rescue_stranded = 'rescue_stranded',
}

export enum VehicleState {
  available = 'available',
  non_operational = 'non_operational',
  on_trip = 'on_trip',
  rebalancing = 'rebalancing',
  removed = 'removed',
  uninitialised = 'uninitialised',
  unknown = 'unknown',
  waiting_for_location = 'waiting_for_location',
}

export type VehicleStateEvent = {
  __typename: 'VehicleStateEvent'
  frame?: Maybe<Frame>
  id: Scalars['ID']['output']
  journey?: Maybe<Journey>
  location?: Maybe<Scalars['JSON']['output']>
  module: Module
  service_area?: Maybe<ServiceArea>
  state: VehicleState
  time: Scalars['DateTime']['output']
  triggers: Array<Scalars['String']['output']>
  zone?: Maybe<Zone>
}

export type VehicleStateEventConnection = {
  __typename: 'VehicleStateEventConnection'
  nodes: Array<VehicleStateEvent>
  pagination: Pagination
}

export enum VehicleType {
  bike = 'bike',
  cargo_bike = 'cargo_bike',
  scooter = 'scooter',
}

export type VehicleWhere = {
  frame_id?: InputMaybe<ScalarOp>
  id?: InputMaybe<ScalarOp>
}

export type Voucher = {
  __typename: 'Voucher'
  campaign?: Maybe<Scalars['String']['output']>
  code: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  discount?: Maybe<ProductDiscountConfig>
  expiration_date: Scalars['DateTime']['output']
  scheme_products?: Maybe<Array<Maybe<SchemeProduct>>>
  start_date: Scalars['DateTime']['output']
}

export type Wallet = {
  __typename: 'Wallet'
  item?: Maybe<WalletItem>
  priority?: Maybe<Scalars['Int']['output']>
  product_type?: Maybe<ProductType>
}

export type WalletItem = PaymentProvider | SchemeProduct | UserProduct

export type Whitelist = {
  __typename: 'Whitelist'
  id: Scalars['ID']['output']
  pattern: Scalars['String']['output']
  role_type?: Maybe<Scalars['String']['output']>
  role_type_id?: Maybe<Scalars['Int']['output']>
  scheme: Scheme
}

export type WhitelistConnection = {
  __typename: 'WhitelistConnection'
  nodes: Array<Whitelist>
  pagination: Pagination
}

export type WhitelistInput = {
  pattern: Scalars['String']['input']
  role_type_id: Scalars['Int']['input']
  scheme_id: Scalars['ID']['input']
}

export type WhitelistOrderby = {
  id?: InputMaybe<Direction>
  pattern?: InputMaybe<Direction>
}

export type WhitelistWhere = {
  id?: InputMaybe<ScalarOp>
  pattern?: InputMaybe<ScalarOp>
}

export type Zone = IZone & {
  __typename: 'Zone'
  all_commissioned_modules_count: Scalars['Int']['output']
  all_modules_count: Scalars['Int']['output']
  capacity: Scalars['Int']['output']
  centre: Scalars['JSON']['output']
  docked_vehicles_count: Scalars['Int']['output']
  external_id: Scalars['ID']['output']
  geom: Scalars['JSON']['output']
  /** @deprecated Use Zone.hireable_vehicles instead */
  hireable_modules: Array<Maybe<Module>>
  /** @deprecated Use Zone.hireable_vehicles_count instead */
  hireable_modules_count: Scalars['Int']['output']
  hireable_vehicles: Array<Maybe<Vehicle>>
  hireable_vehicles_count: Scalars['Int']['output']
  hiring_capacity_status: HiringCapacityStatus
  id: Scalars['ID']['output']
  is_at_max_overflow_capacity: Scalars['Boolean']['output']
  is_external: Scalars['Boolean']['output']
  is_promotional: Scalars['Boolean']['output']
  is_suspended: Scalars['Boolean']['output']
  /** @deprecated Use Zone.hireable_modules_count directly instead */
  locked_modules?: Maybe<ModuleConnection>
  modules?: Maybe<ModuleConnection>
  name: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  parking_capacity_status: ParkingCapacityStatus
  permitted_vehicles: Array<VehicleType>
  promotion?: Maybe<Promotion>
  related_modules?: Maybe<Array<Maybe<Module>>>
  scheme: Scheme
  zone_type: ZoneType
}

export type Zoneall_commissioned_modules_countArgs = {
  hardware_type?: InputMaybe<ModuleHardware>
}

export type Zoneall_modules_countArgs = {
  hardware_type?: InputMaybe<ModuleHardware>
}

export type Zonehireable_modulesArgs = {
  hardware_type?: InputMaybe<ModuleHardware>
}

export type Zonehireable_modules_countArgs = {
  hardware_type?: InputMaybe<ModuleHardware>
}

export type Zonehireable_vehiclesArgs = {
  propulsion_type?: InputMaybe<VehiclePropulsionType>
  vehicle_type?: InputMaybe<VehicleType>
}

export type Zonehireable_vehicles_countArgs = {
  propulsion_type?: InputMaybe<VehiclePropulsionType>
  vehicle_type?: InputMaybe<VehicleType>
}

export type Zonelocked_modulesArgs = {
  paginate?: InputMaybe<Paginate>
}

export type ZonemodulesArgs = {
  paginate?: InputMaybe<Paginate>
  where?: InputMaybe<Array<InputMaybe<ModuleWhere>>>
}

export type ZonepromotionArgs = {
  user_id?: InputMaybe<Scalars['ID']['input']>
}

export type ZoneConnection = {
  __typename: 'ZoneConnection'
  geojson: Scalars['JSON']['output']
  nodes: Array<Zone>
  pagination: Pagination
}

export type ZoneInput = {
  capacity: Scalars['Int']['input']
  centre: Scalars['JSON']['input']
  external_id: Scalars['ID']['input']
  geom: Scalars['JSON']['input']
  is_external?: InputMaybe<Scalars['Boolean']['input']>
  is_suspended?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  permitted_vehicles?: InputMaybe<Array<VehicleType>>
  scheme_id: Scalars['ID']['input']
}

export type ZoneOrderBy = {
  id?: InputMaybe<Direction>
}

export type ZoneSyncResult = {
  __typename: 'ZoneSyncResult'
  added: Array<IZone>
  deleted: Array<IZone>
  dry_run: Scalars['Boolean']['output']
  updated: Array<Zone>
}

export enum ZoneType {
  normal = 'normal',
  workshop = 'workshop',
}

export type ZoneWhere = {
  id?: InputMaybe<ScalarOp>
  name?: InputMaybe<ScalarOp>
  scheme?: InputMaybe<RemoteNodeOp>
}

export type SchemeAvailabilityRestrictionsQuery_scheme_Scheme_availability_restriction_SchemeAvailabilityRestrictionConnection_nodes_SchemeAvailabilityRestriction = {
  __typename: 'SchemeAvailabilityRestriction'
  id: string
  vehicle_type: VehicleType
  frame_type?: FrameType | null
  propulsion_type: VehiclePropulsionType
  start_time: string
  end_time: string
  last_status_change_time?: string | null
}

export type SchemeAvailabilityRestrictionsQuery_scheme_Scheme_availability_restriction_SchemeAvailabilityRestrictionConnection = {
  __typename: 'SchemeAvailabilityRestrictionConnection'
  nodes: Array<SchemeAvailabilityRestrictionsQuery_scheme_Scheme_availability_restriction_SchemeAvailabilityRestrictionConnection_nodes_SchemeAvailabilityRestriction>
}

export type SchemeAvailabilityRestrictionsQuery_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
  availability_restriction: SchemeAvailabilityRestrictionsQuery_scheme_Scheme_availability_restriction_SchemeAvailabilityRestrictionConnection
}

export type SchemeAvailabilityRestrictionsQuery_Query = {
  __typename: 'Query'
  scheme?: SchemeAvailabilityRestrictionsQuery_scheme_Scheme | null
}

export type SchemeAvailabilityRestrictionsQueryVariables = Exact<{
  schemeId: Scalars['ID']['input']
}>

export type SchemeAvailabilityRestrictionsQuery = SchemeAvailabilityRestrictionsQuery_Query

export type AddSchemeAvailabilityRestrictionMutation_add_scheme_availability_restriction_SchemeAvailabilityRestriction = {
  __typename: 'SchemeAvailabilityRestriction'
  id: string
  vehicle_type: VehicleType
  frame_type?: FrameType | null
  propulsion_type: VehiclePropulsionType
  start_time: string
  end_time: string
  last_status_change_time?: string | null
}

export type AddSchemeAvailabilityRestrictionMutation_Mutation = {
  __typename: 'Mutation'
  add_scheme_availability_restriction?: AddSchemeAvailabilityRestrictionMutation_add_scheme_availability_restriction_SchemeAvailabilityRestriction | null
}

export type AddSchemeAvailabilityRestrictionMutationVariables = Exact<{
  scheme_availability_restriction: SchemeAvailabilityRestrictionInput
}>

export type AddSchemeAvailabilityRestrictionMutation = AddSchemeAvailabilityRestrictionMutation_Mutation

export type UpdateSchemeAvailabilityRestrictionMutation_update_scheme_availability_restriction_SchemeAvailabilityRestriction = {
  __typename: 'SchemeAvailabilityRestriction'
  id: string
  vehicle_type: VehicleType
  frame_type?: FrameType | null
  propulsion_type: VehiclePropulsionType
  start_time: string
  end_time: string
  last_status_change_time?: string | null
}

export type UpdateSchemeAvailabilityRestrictionMutation_Mutation = {
  __typename: 'Mutation'
  update_scheme_availability_restriction?: UpdateSchemeAvailabilityRestrictionMutation_update_scheme_availability_restriction_SchemeAvailabilityRestriction | null
}

export type UpdateSchemeAvailabilityRestrictionMutationVariables = Exact<{
  id: Scalars['ID']['input']
  scheme_availability_restriction: SchemeAvailabilityRestrictionInput
}>

export type UpdateSchemeAvailabilityRestrictionMutation = UpdateSchemeAvailabilityRestrictionMutation_Mutation

export type RemoveSchemeAvailabilityRestrictionMutation_Mutation = {
  __typename: 'Mutation'
  remove_scheme_availability_restriction?: boolean | null
}

export type RemoveSchemeAvailabilityRestrictionMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type RemoveSchemeAvailabilityRestrictionMutation = RemoveSchemeAvailabilityRestrictionMutation_Mutation

export type AvailabilityRestrictionDataFragment = {
  __typename: 'SchemeAvailabilityRestriction'
  id: string
  vehicle_type: VehicleType
  frame_type?: FrameType | null
  propulsion_type: VehiclePropulsionType
  start_time: string
  end_time: string
  last_status_change_time?: string | null
}

export type DeploymentListQuery_table_DeploymentConnection_nodes_Deployment_version_Firmware = {
  __typename: 'Firmware'
  name: string
  formatted_size: string
}

export type DeploymentListQuery_table_DeploymentConnection_nodes_Deployment = {
  __typename: 'Deployment'
  id: string
  created_at?: any | null
  version?: DeploymentListQuery_table_DeploymentConnection_nodes_Deployment_version_Firmware | null
}

export type DeploymentListQuery_table_DeploymentConnection_pagination_Pagination = {
  __typename: 'Pagination'
  total: number
  current: number
  pageSize: number
}

export type DeploymentListQuery_table_DeploymentConnection = {
  __typename: 'DeploymentConnection'
  nodes: Array<DeploymentListQuery_table_DeploymentConnection_nodes_Deployment | null>
  pagination?: DeploymentListQuery_table_DeploymentConnection_pagination_Pagination | null
}

export type DeploymentListQuery_Query = {
  __typename: 'Query'
  table?: DeploymentListQuery_table_DeploymentConnection | null
}

export type DeploymentListQueryVariables = Exact<{
  paginate?: InputMaybe<Paginate>
}>

export type DeploymentListQuery = DeploymentListQuery_Query

export type AllVersionsQuery_firmware_Firmware = {
  __typename: 'Firmware'
  id: string
  name: string
  supported_hardware?: Array<ModuleHardwareType | null> | null
}

export type AllVersionsQuery_Query = {
  __typename: 'Query'
  firmware: Array<AllVersionsQuery_firmware_Firmware | null>
}

export type AllVersionsQueryVariables = Exact<{ [key: string]: never }>

export type AllVersionsQuery = AllVersionsQuery_Query

export type DeploymentQuery_deployment_Deployment_version_Firmware = {
  __typename: 'Firmware'
  name: string
  size: number
  created_at: any
}

export type DeploymentQuery_deployment_Deployment_modules_DeploymentEventConnection_nodes_DeploymentEvent_module_Module = {
  __typename: 'Module'
  id: string
}

export type DeploymentQuery_deployment_Deployment_modules_DeploymentEventConnection_nodes_DeploymentEvent = {
  __typename: 'DeploymentEvent'
  id: string
  created_at?: any | null
  state?: DeploymentEventState | null
  module?: DeploymentQuery_deployment_Deployment_modules_DeploymentEventConnection_nodes_DeploymentEvent_module_Module | null
}

export type DeploymentQuery_deployment_Deployment_modules_DeploymentEventConnection_pagination_Pagination = {
  __typename: 'Pagination'
  total: number
  current: number
  pageSize: number
}

export type DeploymentQuery_deployment_Deployment_modules_DeploymentEventConnection = {
  __typename: 'DeploymentEventConnection'
  nodes: Array<DeploymentQuery_deployment_Deployment_modules_DeploymentEventConnection_nodes_DeploymentEvent | null>
  pagination?: DeploymentQuery_deployment_Deployment_modules_DeploymentEventConnection_pagination_Pagination | null
}

export type DeploymentQuery_deployment_Deployment = {
  __typename: 'Deployment'
  id: string
  created_at?: any | null
  version?: DeploymentQuery_deployment_Deployment_version_Firmware | null
  modules?: DeploymentQuery_deployment_Deployment_modules_DeploymentEventConnection | null
}

export type DeploymentQuery_Query = {
  __typename: 'Query'
  deployment?: DeploymentQuery_deployment_Deployment | null
}

export type DeploymentQueryVariables = Exact<{
  id: Scalars['ID']['input']
  paginate?: InputMaybe<Paginate>
}>

export type DeploymentQuery = DeploymentQuery_Query

export type InvoiceQuery_invoice_JourneyInvoice_journey_Journey_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
}

export type InvoiceQuery_invoice_JourneyInvoice_journey_Journey = {
  __typename: 'Journey'
  id: string
  initiator?: JourneyInitiator | null
  duration?: number | null
  scheme?: InvoiceQuery_invoice_JourneyInvoice_journey_Journey_scheme_Scheme | null
}

export type InvoiceQuery_invoice_JourneyInvoice_minute_refundable_FormattedAmount = {
  __typename: 'FormattedAmount'
  unit: string
  amount: number
  formatted_amount: string
}

export type InvoiceQuery_invoice_JourneyInvoice_scheme_product_SchemeProduct_scheme_accounting_SchemeAccounting = {
  __typename: 'SchemeAccounting'
  currency: string
}

export type InvoiceQuery_invoice_JourneyInvoice_scheme_product_SchemeProduct = {
  __typename: 'SchemeProduct'
  id: string
  name: string
  scheme_accounting: InvoiceQuery_invoice_JourneyInvoice_scheme_product_SchemeProduct_scheme_accounting_SchemeAccounting
}

export type InvoiceQuery_invoice_JourneyInvoice_user_User = {
  __typename: 'User'
  id: string
  email?: string | null
  deleted?: boolean | null
}

export type InvoiceQuery_invoice_JourneyInvoice_minute_debit_FormattedAmount = {
  __typename: 'FormattedAmount'
  unit: string
  amount: number
  formatted_amount: string
}

export type InvoiceQuery_invoice_JourneyInvoice_currency_debit_FormattedAmount = {
  __typename: 'FormattedAmount'
  unit: string
  amount: number
  formatted_amount: string
}

export type InvoiceQuery_invoice_JourneyInvoice_currency_refundable_FormattedAmount = {
  __typename: 'FormattedAmount'
  unit: string
  amount: number
  formatted_amount: string
}

export type InvoiceQuery_invoice_JourneyInvoice_currency_balance_FormattedAmount = {
  __typename: 'FormattedAmount'
  unit: string
  amount: number
  formatted_amount: string
}

export type InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_result_TransactionResult = {
  __typename: 'TransactionResult'
  status?: TransactionResultType | null
  failure_message?: string | null
}

export type InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_debit_amount_FormattedAmount = {
  __typename: 'FormattedAmount'
  unit: string
  amount: number
  formatted_amount: string
}

export type InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_credit_amount_FormattedAmount = {
  __typename: 'FormattedAmount'
  unit: string
  amount: number
  formatted_amount: string
}

export type InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_meta_TransactionMeta = {
  __typename: 'TransactionMeta'
  descriptor?: TransactionCategory | null
  failure_reason?: string | null
  requester_id?: string | null
  refund_description?: string | null
  transaction_label?: string | null
  charge_type?: TransactionMetaChargeType | null
  minutes_charged?: number | null
  time_increment_cost?: number | null
}

export type InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_refund_requester_User = {
  __typename: 'User'
  id: string
  email?: string | null
}

export type InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_amount_refundable_FormattedAmount = {
  __typename: 'FormattedAmount'
  unit: string
  amount: number
  formatted_amount: string
}

export type InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_amount_refunded_FormattedAmount = {
  __typename: 'FormattedAmount'
  unit: string
  amount: number
  formatted_amount: string
}

export type InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction = {
  __typename: 'CurrencyTransaction'
  id: string
  created_at: any
  description: string
  category: TransactionCategory
  provider_type?: CurrencyPaymentProviderType | null
  void: boolean
  type?: TransactionType | null
  result?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_result_TransactionResult | null
  debit_amount: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_debit_amount_FormattedAmount
  credit_amount: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_credit_amount_FormattedAmount
  meta?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_meta_TransactionMeta | null
  refund_requester?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_refund_requester_User | null
  amount_refundable?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_amount_refundable_FormattedAmount | null
  amount_refunded?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_amount_refunded_FormattedAmount | null
}

export type InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_MinuteTransaction = {
  __typename: 'MinuteTransaction'
  id: string
  created_at: any
  description: string
  category: TransactionCategory
  provider_type?: CurrencyPaymentProviderType | null
  void: boolean
  type?: TransactionType | null
  result?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_result_TransactionResult | null
  debit_amount: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_debit_amount_FormattedAmount
  credit_amount: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_credit_amount_FormattedAmount
  meta?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_meta_TransactionMeta | null
  refund_requester?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_refund_requester_User | null
  amount_refundable?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_amount_refundable_FormattedAmount | null
  amount_refunded?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction_amount_refunded_FormattedAmount | null
}

export type InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes =
  | InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_CurrencyTransaction
  | InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes_MinuteTransaction

export type InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection = {
  __typename: 'TransactionConnection'
  nodes: Array<InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes>
}

export type InvoiceQuery_invoice_ProductInvoice_minute_refund_calcs_MinuteRefundCalcs_currency_amount_FormattedAmount = {
  __typename: 'FormattedAmount'
  amount: number
  formatted_amount: string
}

export type InvoiceQuery_invoice_ProductInvoice_minute_refund_calcs_MinuteRefundCalcs = {
  __typename: 'MinuteRefundCalcs'
  minute_adjustment_quantity: number
  currency_amount: InvoiceQuery_invoice_ProductInvoice_minute_refund_calcs_MinuteRefundCalcs_currency_amount_FormattedAmount
}

export type InvoiceQuery_invoice_JourneyInvoice = {
  __typename: 'JourneyInvoice'
  id: string
  created_at: any
  description: string
  status?: InvoiceStatus | null
  journey: InvoiceQuery_invoice_JourneyInvoice_journey_Journey
  minute_refundable?: InvoiceQuery_invoice_JourneyInvoice_minute_refundable_FormattedAmount | null
  scheme_product?: InvoiceQuery_invoice_JourneyInvoice_scheme_product_SchemeProduct | null
  user?: InvoiceQuery_invoice_JourneyInvoice_user_User | null
  minute_debit?: InvoiceQuery_invoice_JourneyInvoice_minute_debit_FormattedAmount | null
  currency_debit?: InvoiceQuery_invoice_JourneyInvoice_currency_debit_FormattedAmount | null
  currency_refundable?: InvoiceQuery_invoice_JourneyInvoice_currency_refundable_FormattedAmount | null
  currency_balance?: InvoiceQuery_invoice_JourneyInvoice_currency_balance_FormattedAmount | null
  transactions?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection | null
}

export type InvoiceQuery_invoice_ProductInvoice = {
  __typename: 'ProductInvoice'
  id: string
  created_at: any
  description: string
  status?: InvoiceStatus | null
  minute_refund_calcs?: InvoiceQuery_invoice_ProductInvoice_minute_refund_calcs_MinuteRefundCalcs | null
  scheme_product?: InvoiceQuery_invoice_JourneyInvoice_scheme_product_SchemeProduct | null
  user?: InvoiceQuery_invoice_JourneyInvoice_user_User | null
  minute_debit?: InvoiceQuery_invoice_JourneyInvoice_minute_debit_FormattedAmount | null
  currency_debit?: InvoiceQuery_invoice_JourneyInvoice_currency_debit_FormattedAmount | null
  currency_refundable?: InvoiceQuery_invoice_JourneyInvoice_currency_refundable_FormattedAmount | null
  currency_balance?: InvoiceQuery_invoice_JourneyInvoice_currency_balance_FormattedAmount | null
  transactions?: InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection | null
}

export type InvoiceQuery_invoice =
  | InvoiceQuery_invoice_JourneyInvoice
  | InvoiceQuery_invoice_ProductInvoice

export type InvoiceQuery_Query = {
  __typename: 'Query'
  invoice?: InvoiceQuery_invoice | null
}

export type InvoiceQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type InvoiceQuery = InvoiceQuery_Query

export type RefundInvoiceMutation_refund_invoice_v2_RefundInvoiceResult_currency_RefundResultItem = {
  __typename: 'RefundResultItem'
  amount?: number | null
  status?: string | null
  message?: string | null
}

export type RefundInvoiceMutation_refund_invoice_v2_RefundInvoiceResult_minutes_RefundResultItem = {
  __typename: 'RefundResultItem'
  amount?: number | null
  status?: string | null
  message?: string | null
}

export type RefundInvoiceMutation_refund_invoice_v2_RefundInvoiceResult = {
  __typename: 'RefundInvoiceResult'
  currency?: RefundInvoiceMutation_refund_invoice_v2_RefundInvoiceResult_currency_RefundResultItem | null
  minutes?: RefundInvoiceMutation_refund_invoice_v2_RefundInvoiceResult_minutes_RefundResultItem | null
}

export type RefundInvoiceMutation_Mutation = {
  __typename: 'Mutation'
  refund_invoice_v2?: RefundInvoiceMutation_refund_invoice_v2_RefundInvoiceResult | null
}

export type RefundInvoiceMutationVariables = Exact<{
  id: Scalars['ID']['input']
  description: Scalars['String']['input']
  reason: Scalars['String']['input']
  revoke_product: Scalars['Boolean']['input']
  refund_transactions:
    | Array<RefundInvoiceV2InputTransaction>
    | RefundInvoiceV2InputTransaction
}>

export type RefundInvoiceMutation = RefundInvoiceMutation_Mutation

export type ReverseCancelledJourneyMutation_reverse_cancelled_journey_RefundInvoiceResult_currency_RefundResultItem = {
  __typename: 'RefundResultItem'
  amount?: number | null
  status?: string | null
  message?: string | null
}

export type ReverseCancelledJourneyMutation_reverse_cancelled_journey_RefundInvoiceResult = {
  __typename: 'RefundInvoiceResult'
  currency?: ReverseCancelledJourneyMutation_reverse_cancelled_journey_RefundInvoiceResult_currency_RefundResultItem | null
}

export type ReverseCancelledJourneyMutation_Mutation = {
  __typename: 'Mutation'
  reverse_cancelled_journey?: ReverseCancelledJourneyMutation_reverse_cancelled_journey_RefundInvoiceResult | null
}

export type ReverseCancelledJourneyMutationVariables = Exact<{
  id: Scalars['ID']['input']
  description: Scalars['String']['input']
  amendedDuration?: InputMaybe<Scalars['Int']['input']>
}>

export type ReverseCancelledJourneyMutation = ReverseCancelledJourneyMutation_Mutation

export type FormattedAmountFragment = {
  __typename: 'FormattedAmount'
  unit: string
  amount: number
  formatted_amount: string
}

export type SchemeJobAssetsQuery_scheme_job_assets_JobAsset = {
  __typename: 'JobAsset'
  assetTag: string
  assetName: string
  assetCategory: AssetCategory
}

export type SchemeJobAssetsQuery_Query = {
  __typename: 'Query'
  scheme_job_assets: Array<SchemeJobAssetsQuery_scheme_job_assets_JobAsset>
}

export type SchemeJobAssetsQueryVariables = Exact<{
  scheme_id: Scalars['ID']['input']
}>

export type SchemeJobAssetsQuery = SchemeJobAssetsQuery_Query

export type JobListQuery_table_JobConnection_nodes_Job_timestamps_JobTimestamps = {
  __typename: 'JobTimestamps'
  created_at: any
  updated_at: any
}

export type JobListQuery_table_JobConnection_nodes_Job_details_JobDetails_assignee_User = {
  __typename: 'User'
  id: string
  email?: string | null
}

export type JobListQuery_table_JobConnection_nodes_Job_details_JobDetails_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
  name: string
}

export type JobListQuery_table_JobConnection_nodes_Job_details_JobDetails_createdBy_User = {
  __typename: 'User'
  id: string
  email?: string | null
}

export type JobListQuery_table_JobConnection_nodes_Job_details_JobDetails_updatedBy_User = {
  __typename: 'User'
  id: string
  email?: string | null
}

export type JobListQuery_table_JobConnection_nodes_Job_details_JobDetails = {
  __typename: 'JobDetails'
  priority?: string | null
  assetTag?: string | null
  assetName?: string | null
  assetCategory?: AssetCategory | null
  taskId: string
  description?: string | null
  status?: JobStatusEnum | null
  role?: string | null
  notes?: string | null
  location?: string | null
  reportedLocation?: string | null
  locationDescription?: string | null
  isJustified?: boolean | null
  isUnlocked?: boolean | null
  assignee?: JobListQuery_table_JobConnection_nodes_Job_details_JobDetails_assignee_User | null
  scheme?: JobListQuery_table_JobConnection_nodes_Job_details_JobDetails_scheme_Scheme | null
  createdBy?: JobListQuery_table_JobConnection_nodes_Job_details_JobDetails_createdBy_User | null
  updatedBy?: JobListQuery_table_JobConnection_nodes_Job_details_JobDetails_updatedBy_User | null
}

export type JobListQuery_table_JobConnection_nodes_Job_vehicle_Vehicle_detail_VehicleDetail_module_Module_location_Location = {
  __typename: 'Location'
  id: string
  latitude: number
  longitude: number
  time: any
  max_error?: number | null
}

export type JobListQuery_table_JobConnection_nodes_Job_vehicle_Vehicle_detail_VehicleDetail_module_Module = {
  __typename: 'Module'
  id: string
  hardware_type: ModuleHardware
  location?: JobListQuery_table_JobConnection_nodes_Job_vehicle_Vehicle_detail_VehicleDetail_module_Module_location_Location | null
}

export type JobListQuery_table_JobConnection_nodes_Job_vehicle_Vehicle_detail_VehicleDetail = {
  __typename: 'VehicleDetail'
  module?: JobListQuery_table_JobConnection_nodes_Job_vehicle_Vehicle_detail_VehicleDetail_module_Module | null
}

export type JobListQuery_table_JobConnection_nodes_Job_vehicle_Vehicle = {
  __typename: 'Vehicle'
  id: any
  frame_id: string
  detail: JobListQuery_table_JobConnection_nodes_Job_vehicle_Vehicle_detail_VehicleDetail
}

export type JobListQuery_table_JobConnection_nodes_Job = {
  __typename: 'Job'
  uuid: string
  timestamps?: JobListQuery_table_JobConnection_nodes_Job_timestamps_JobTimestamps | null
  details: JobListQuery_table_JobConnection_nodes_Job_details_JobDetails
  vehicle?: JobListQuery_table_JobConnection_nodes_Job_vehicle_Vehicle | null
}

export type JobListQuery_table_JobConnection_pagination_Pagination = {
  __typename: 'Pagination'
  total: number
  current: number
  pageSize: number
}

export type JobListQuery_table_JobConnection = {
  __typename: 'JobConnection'
  nodes: Array<JobListQuery_table_JobConnection_nodes_Job>
  pagination: JobListQuery_table_JobConnection_pagination_Pagination
}

export type JobListQuery_Query = {
  __typename: 'Query'
  table: JobListQuery_table_JobConnection
}

export type JobListQueryVariables = Exact<{
  paginate?: InputMaybe<Paginate>
  job_filters?: InputMaybe<JobFiltersInput>
}>

export type JobListQuery = JobListQuery_Query

export type JobQuery_job_Job_tasks_Task_details_TaskDetails_assignee_User = {
  __typename: 'User'
  id: string
  email?: string | null
}

export type JobQuery_job_Job_tasks_Task_details_TaskDetails_task_codes_details_TaskCodeItem = {
  __typename: 'TaskCodeItem'
  code: string
  reason: TaskCodeReason
}

export type JobQuery_job_Job_tasks_Task_details_TaskDetails = {
  __typename: 'TaskDetails'
  title?: string | null
  status?: TaskStatusEnum | null
  description?: string | null
  notes?: string | null
  assignee?: JobQuery_job_Job_tasks_Task_details_TaskDetails_assignee_User | null
  task_codes_details?: Array<JobQuery_job_Job_tasks_Task_details_TaskDetails_task_codes_details_TaskCodeItem> | null
}

export type JobQuery_job_Job_tasks_Task = {
  __typename: 'Task'
  uuid: string
  details?: JobQuery_job_Job_tasks_Task_details_TaskDetails | null
}

export type JobQuery_job_Job_justifications_Justification = {
  __typename: 'Justification'
  justification_id: string
  description: string
}

export type JobQuery_job_Job_attempts_Attempt_assignee_User = {
  __typename: 'User'
  email?: string | null
}

export type JobQuery_job_Job_attempts_Attempt = {
  __typename: 'Attempt'
  created_at: any
  assignee: JobQuery_job_Job_attempts_Attempt_assignee_User
}

export type JobQuery_job_Job = {
  __typename: 'Job'
  uuid: string
  tasks?: Array<JobQuery_job_Job_tasks_Task> | null
  justifications?: Array<JobQuery_job_Job_justifications_Justification> | null
  attempts?: Array<JobQuery_job_Job_attempts_Attempt> | null
  timestamps?: JobListQuery_table_JobConnection_nodes_Job_timestamps_JobTimestamps | null
  details: JobListQuery_table_JobConnection_nodes_Job_details_JobDetails
  vehicle?: JobListQuery_table_JobConnection_nodes_Job_vehicle_Vehicle | null
}

export type JobQuery_Query = {
  __typename: 'Query'
  job?: JobQuery_job_Job | null
}

export type JobQueryVariables = Exact<{
  job_id: Scalars['ID']['input']
}>

export type JobQuery = JobQuery_Query

export type ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob_details_ArchiveJobDetails_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
  name: string
}

export type ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob_details_ArchiveJobDetails_assignee_User = {
  __typename: 'User'
  id: string
  email?: string | null
}

export type ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob_details_ArchiveJobDetails = {
  __typename: 'ArchiveJobDetails'
  taskId: string
  notes?: string | null
  status: JobStatusEnum
  isJustified: boolean
  scheme?: ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob_details_ArchiveJobDetails_scheme_Scheme | null
  assignee?: ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob_details_ArchiveJobDetails_assignee_User | null
}

export type ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob_timestamps_JobTimestamps = {
  __typename: 'JobTimestamps'
  created_at: any
  updated_at: any
}

export type ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob = {
  __typename: 'ArchiveJob'
  sys_id: string
  uuid: string
  details: ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob_details_ArchiveJobDetails
  timestamps: ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob_timestamps_JobTimestamps
}

export type ArchiveJobListQuery_table_ArchiveJobConnection_pagination_Pagination = {
  __typename: 'Pagination'
  total: number
  current: number
  pageSize: number
}

export type ArchiveJobListQuery_table_ArchiveJobConnection = {
  __typename: 'ArchiveJobConnection'
  nodes: Array<ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob>
  pagination: ArchiveJobListQuery_table_ArchiveJobConnection_pagination_Pagination
}

export type ArchiveJobListQuery_Query = {
  __typename: 'Query'
  table: ArchiveJobListQuery_table_ArchiveJobConnection
}

export type ArchiveJobListQueryVariables = Exact<{
  paginate?: InputMaybe<Paginate>
  asset_tag: Scalars['ID']['input']
}>

export type ArchiveJobListQuery = ArchiveJobListQuery_Query

export type JobAutomationsQuery_automations_Automation_jobs_AutomationJob = {
  __typename: 'AutomationJob'
  uuid: string
  frame_id: string
  scheme_id: number
  created_at?: any | null
}

export type JobAutomationsQuery_automations_Automation = {
  __typename: 'Automation'
  justification_id: string
  description: string
  taskId: string
  side_effects: Array<string | null>
  jobs: Array<JobAutomationsQuery_automations_Automation_jobs_AutomationJob | null>
}

export type JobAutomationsQuery_Query = {
  __typename: 'Query'
  automations?: Array<JobAutomationsQuery_automations_Automation | null> | null
}

export type JobAutomationsQueryVariables = Exact<{
  filters?: InputMaybe<AutomationFilters>
}>

export type JobAutomationsQuery = JobAutomationsQuery_Query

export type UnarchiveJobMutation_Mutation = {
  __typename: 'Mutation'
  archive_restore: boolean
}

export type UnarchiveJobMutationVariables = Exact<{
  sys_id: Scalars['ID']['input']
}>

export type UnarchiveJobMutation = UnarchiveJobMutation_Mutation

export type TriggerJobAutomationMutation_Mutation = {
  __typename: 'Mutation'
  job_automation?: boolean | null
}

export type TriggerJobAutomationMutationVariables = Exact<{
  justification_id: Scalars['String']['input']
  description: Scalars['String']['input']
  taskId: Scalars['String']['input']
  side_effects:
    | Array<InputMaybe<JobAutomationSideEffect>>
    | InputMaybe<JobAutomationSideEffect>
  assets: Array<Scalars['String']['input']> | Scalars['String']['input']
  dry_run: Scalars['Boolean']['input']
}>

export type TriggerJobAutomationMutation = TriggerJobAutomationMutation_Mutation

export type JobBasicFragment = {
  __typename: 'Job'
  uuid: string
  timestamps?: JobListQuery_table_JobConnection_nodes_Job_timestamps_JobTimestamps | null
  details: JobListQuery_table_JobConnection_nodes_Job_details_JobDetails
  vehicle?: JobListQuery_table_JobConnection_nodes_Job_vehicle_Vehicle | null
}

export type JobDetailedFragment = {
  __typename: 'Job'
  uuid: string
  tasks?: Array<JobQuery_job_Job_tasks_Task> | null
  justifications?: Array<JobQuery_job_Job_justifications_Justification> | null
  attempts?: Array<JobQuery_job_Job_attempts_Attempt> | null
  timestamps?: JobListQuery_table_JobConnection_nodes_Job_timestamps_JobTimestamps | null
  details: JobListQuery_table_JobConnection_nodes_Job_details_JobDetails
  vehicle?: JobListQuery_table_JobConnection_nodes_Job_vehicle_Vehicle | null
}

export type ArchiveJobBasicFragment = {
  __typename: 'ArchiveJob'
  sys_id: string
  uuid: string
  details: ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob_details_ArchiveJobDetails
  timestamps: ArchiveJobListQuery_table_ArchiveJobConnection_nodes_ArchiveJob_timestamps_JobTimestamps
}

export type JourneyListQuery_table_JourneyConnection_nodes_Journey_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
  name: string
}

export type JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_module_state_ModuleState = {
  __typename: 'ModuleState'
  assist_config?: AssistConfig | null
  id: string
}

export type JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_module_state_ScooterModuleState = {
  __typename: 'ScooterModuleState'
  id: string
}

export type JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_module_state =
  | JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_module_state_ModuleState
  | JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_module_state_ScooterModuleState

export type JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_bike_Bike_frame_Frame = {
  __typename: 'Frame'
  id: string
}

export type JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_bike_Bike_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
}

export type JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_bike_Bike = {
  __typename: 'Bike'
  vehicle_id: string
  frame: JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_bike_Bike_frame_Frame
  scheme: JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_bike_Bike_scheme_Scheme
}

export type JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module = {
  __typename: 'Module'
  id: string
  hardware_type: ModuleHardware
  module_state?: JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_module_state | null
  bike?: JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module_bike_Bike | null
}

export type JourneyListQuery_table_JourneyConnection_nodes_Journey_user_User = {
  __typename: 'User'
  id: string
  email?: string | null
  deleted?: boolean | null
}

export type JourneyListQuery_table_JourneyConnection_nodes_Journey_state_JourneyEvent = {
  __typename: 'JourneyEvent'
  id: string
  time?: any
  message: string
}

export type JourneyListQuery_table_JourneyConnection_nodes_Journey = {
  __typename: 'Journey'
  id: string
  is_complete?: boolean
  scheme?: JourneyListQuery_table_JourneyConnection_nodes_Journey_scheme_Scheme | null
  module: JourneyListQuery_table_JourneyConnection_nodes_Journey_module_Module
  user?: JourneyListQuery_table_JourneyConnection_nodes_Journey_user_User | null
  state: JourneyListQuery_table_JourneyConnection_nodes_Journey_state_JourneyEvent
}

export type JourneyListQuery_table_JourneyConnection_pagination_Pagination = {
  __typename: 'Pagination'
  total: number
  current: number
  pageSize: number
}

export type JourneyListQuery_table_JourneyConnection = {
  __typename: 'JourneyConnection'
  nodes: Array<JourneyListQuery_table_JourneyConnection_nodes_Journey>
  pagination: JourneyListQuery_table_JourneyConnection_pagination_Pagination
}

export type JourneyListQuery_Query = {
  __typename: 'Query'
  table: JourneyListQuery_table_JourneyConnection
}

export type JourneyListQueryVariables = Exact<{
  module_id?: InputMaybe<Scalars['ID']['input']>
  user_id?: InputMaybe<Scalars['ID']['input']>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  paginate?: InputMaybe<Paginate>
  is_mobile: Scalars['Boolean']['input']
  omit_user: Scalars['Boolean']['input']
  where?: InputMaybe<Array<InputMaybe<JourneyWhere>> | InputMaybe<JourneyWhere>>
}>

export type JourneyListQuery = JourneyListQuery_Query

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_lock_state_LockEvent = {
  __typename: 'LockEvent'
  id: string
  overall_state?: LockState | null
  vehicle_lock_state?: VehicleLockState | null
}

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_vehicle_Vehicle_detail_VehicleDetail_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
}

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_vehicle_Vehicle_detail_VehicleDetail = {
  __typename: 'VehicleDetail'
  lock_configuration: LockConfiguration
  vehicle_unlock_id?: string | null
  scheme?: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_vehicle_Vehicle_detail_VehicleDetail_scheme_Scheme | null
}

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_vehicle_Vehicle = {
  __typename: 'Vehicle'
  id: any
  frame_id: string
  frame_type: string
  detail: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_vehicle_Vehicle_detail_VehicleDetail
}

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_iot_state_IotState = {
  __typename: 'IotState'
  power_mode?: string | null
  firmware_version?: string | null
  id: string
}

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_telemetry_Telemetry_drive_Drive = {
  __typename: 'Drive'
  battery_percent?: number | null
  estimated_range?: number | null
}

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_telemetry_Telemetry = {
  __typename: 'Telemetry'
  id: string
  battery_percent: number
  fix_invalid: boolean
  drive?: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_telemetry_Telemetry_drive_Drive | null
}

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_availability_Availability = {
  __typename: 'Availability'
  id: string
  status: AvailabilityStatus
}

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_connectivity_ModuleConnectivity = {
  __typename: 'ModuleConnectivity'
  is_online: boolean
}

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module = {
  __typename: 'Module'
  id: string
  unlock_id?: string | null
  hardware_type: ModuleHardware
  is_present: boolean
  lock_state?: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_lock_state_LockEvent | null
  vehicle?: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_vehicle_Vehicle | null
  iot_state?: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_iot_state_IotState | null
  telemetry?: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_telemetry_Telemetry | null
  availability?: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_availability_Availability | null
  connectivity?: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module_connectivity_ModuleConnectivity | null
}

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection_pagination_Pagination = {
  __typename: 'Pagination'
  total: number
  current: number
  pageSize: number
}

export type ZoneModulesListQuery_zone_Zone_table_ModuleConnection = {
  __typename: 'ModuleConnection'
  nodes: Array<ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module>
  pagination: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_pagination_Pagination
}

export type ZoneModulesListQuery_zone_Zone = {
  __typename: 'Zone'
  id: string
  table?: ZoneModulesListQuery_zone_Zone_table_ModuleConnection | null
}

export type ZoneModulesListQuery_Query = {
  __typename: 'Query'
  zone?: ZoneModulesListQuery_zone_Zone | null
}

export type ZoneModulesListQueryVariables = Exact<{
  zone_id: Scalars['ID']['input']
  paginate?: InputMaybe<Paginate>
  is_mobile: Scalars['Boolean']['input']
  lean_query: Scalars['Boolean']['input']
}>

export type ZoneModulesListQuery = ZoneModulesListQuery_Query

export type ModulesListQuery_table_ModuleConnection = {
  __typename: 'ModuleConnection'
  nodes: Array<ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module>
  pagination: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_pagination_Pagination
}

export type ModulesListQuery_Query = {
  __typename: 'Query'
  table: ModulesListQuery_table_ModuleConnection
}

export type ModulesListQueryVariables = Exact<{
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  paginate?: InputMaybe<Paginate>
  where?: InputMaybe<Array<InputMaybe<ModuleWhere>> | InputMaybe<ModuleWhere>>
  order_by?: InputMaybe<
    Array<InputMaybe<ModuleOrderby>> | InputMaybe<ModuleOrderby>
  >
  is_mobile: Scalars['Boolean']['input']
  lean_query: Scalars['Boolean']['input']
}>

export type ModulesListQuery = ModulesListQuery_Query

export type ModuleVehicleCommissionedQuery_module_Module_vehicle_Vehicle_detail_VehicleDetail_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
  name: string
}

export type ModuleVehicleCommissionedQuery_module_Module_vehicle_Vehicle_detail_VehicleDetail = {
  __typename: 'VehicleDetail'
  lock_configuration: LockConfiguration
  vehicle_unlock_id?: string | null
  scheme?: ModuleVehicleCommissionedQuery_module_Module_vehicle_Vehicle_detail_VehicleDetail_scheme_Scheme | null
}

export type ModuleVehicleCommissionedQuery_module_Module_vehicle_Vehicle = {
  __typename: 'Vehicle'
  id: any
  frame_id: string
  frame_type: string
  detail: ModuleVehicleCommissionedQuery_module_Module_vehicle_Vehicle_detail_VehicleDetail
}

export type ModuleVehicleCommissionedQuery_module_Module = {
  __typename: 'Module'
  hardware_type: ModuleHardware
  id: string
  vehicle?: ModuleVehicleCommissionedQuery_module_Module_vehicle_Vehicle | null
}

export type ModuleVehicleCommissionedQuery_Query = {
  __typename: 'Query'
  module?: ModuleVehicleCommissionedQuery_module_Module | null
}

export type ModuleVehicleCommissionedQueryVariables = Exact<{
  module_id: Scalars['ID']['input']
}>

export type ModuleVehicleCommissionedQuery = ModuleVehicleCommissionedQuery_Query

export type ModuleVehicleQuery_module_Module_vehicle_Vehicle_detail_VehicleDetail_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
  name: string
}

export type ModuleVehicleQuery_module_Module_vehicle_Vehicle_detail_VehicleDetail_module_Module = {
  __typename: 'Module'
  id: string
}

export type ModuleVehicleQuery_module_Module_vehicle_Vehicle_detail_VehicleDetail = {
  __typename: 'VehicleDetail'
  scheme?: ModuleVehicleQuery_module_Module_vehicle_Vehicle_detail_VehicleDetail_scheme_Scheme | null
  module?: ModuleVehicleQuery_module_Module_vehicle_Vehicle_detail_VehicleDetail_module_Module | null
}

export type ModuleVehicleQuery_module_Module_vehicle_Vehicle = {
  __typename: 'Vehicle'
  id: any
  frame_id: string
  frame_type: string
  hardware_type: ModuleHardware
  detail: ModuleVehicleQuery_module_Module_vehicle_Vehicle_detail_VehicleDetail
}

export type ModuleVehicleQuery_module_Module = {
  __typename: 'Module'
  vehicle?: ModuleVehicleQuery_module_Module_vehicle_Vehicle | null
}

export type ModuleVehicleQuery_Query = {
  __typename: 'Query'
  module?: ModuleVehicleQuery_module_Module | null
}

export type ModuleVehicleQueryVariables = Exact<{
  module_id: Scalars['ID']['input']
}>

export type ModuleVehicleQuery = ModuleVehicleQuery_Query

export type CommissionVehicleMutation_commission_V2_Vehicle_detail_VehicleDetail_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
}

export type CommissionVehicleMutation_commission_V2_Vehicle_detail_VehicleDetail = {
  __typename: 'VehicleDetail'
  lock_configuration: LockConfiguration
  vehicle_unlock_id?: string | null
  scheme?: CommissionVehicleMutation_commission_V2_Vehicle_detail_VehicleDetail_scheme_Scheme | null
}

export type CommissionVehicleMutation_commission_V2_Vehicle = {
  __typename: 'Vehicle'
  id: any
  frame_id: string
  detail: CommissionVehicleMutation_commission_V2_Vehicle_detail_VehicleDetail
}

export type CommissionVehicleMutation_Mutation = {
  __typename: 'Mutation'
  commission_V2: CommissionVehicleMutation_commission_V2_Vehicle
}

export type CommissionVehicleMutationVariables = Exact<{
  module_id: Scalars['ID']['input']
  frame_id: Scalars['ID']['input']
  scheme_id: Scalars['ID']['input']
  lock_configuration: LockConfiguration
  frame_type: FrameType
  vehicle_unlock_id?: InputMaybe<Scalars['ID']['input']>
}>

export type CommissionVehicleMutation = CommissionVehicleMutation_Mutation

export type ModuleMapQuery_module_Module_location_Location = {
  __typename: 'Location'
  id: string
  latitude: number
  longitude: number
  max_error?: number | null
  time: any
}

export type ModuleMapQuery_module_Module_lock_state_LockEvent = {
  __typename: 'LockEvent'
  id: string
  time: any
  overall_state?: LockState | null
  vehicle_lock_state?: VehicleLockState | null
}

export type ModuleMapQuery_module_Module_vehicle_Vehicle_rewards_VehicleReward = {
  __typename: 'VehicleReward'
  type: VehicleRewardType
}

export type ModuleMapQuery_module_Module_vehicle_Vehicle = {
  __typename: 'Vehicle'
  id: any
  frame_type: string
  rewards: Array<ModuleMapQuery_module_Module_vehicle_Vehicle_rewards_VehicleReward>
}

export type ModuleMapQuery_module_Module = {
  __typename: 'Module'
  id: string
  hardware_type: ModuleHardware
  is_present: boolean
  location?: ModuleMapQuery_module_Module_location_Location | null
  lock_state?: ModuleMapQuery_module_Module_lock_state_LockEvent | null
  vehicle?: ModuleMapQuery_module_Module_vehicle_Vehicle | null
}

export type ModuleMapQuery_modules_ModuleConnection_nodes_Module_location_Location = {
  __typename: 'Location'
  id: string
  latitude: number
  longitude: number
  max_error?: number | null
  time: any
}

export type ModuleMapQuery_modules_ModuleConnection_nodes_Module_lock_state_LockEvent = {
  __typename: 'LockEvent'
  id: string
  time: any
  overall_state?: LockState | null
  vehicle_lock_state?: VehicleLockState | null
}

export type ModuleMapQuery_modules_ModuleConnection_nodes_Module_vehicle_Vehicle_rewards_VehicleReward = {
  __typename: 'VehicleReward'
  type: VehicleRewardType
}

export type ModuleMapQuery_modules_ModuleConnection_nodes_Module_vehicle_Vehicle = {
  __typename: 'Vehicle'
  id: any
  frame_type: string
  rewards: Array<ModuleMapQuery_modules_ModuleConnection_nodes_Module_vehicle_Vehicle_rewards_VehicleReward>
}

export type ModuleMapQuery_modules_ModuleConnection_nodes_Module = {
  __typename: 'Module'
  id: string
  hardware_type: ModuleHardware
  is_present: boolean
  location?: ModuleMapQuery_modules_ModuleConnection_nodes_Module_location_Location | null
  lock_state?: ModuleMapQuery_modules_ModuleConnection_nodes_Module_lock_state_LockEvent | null
  vehicle?: ModuleMapQuery_modules_ModuleConnection_nodes_Module_vehicle_Vehicle | null
}

export type ModuleMapQuery_modules_ModuleConnection = {
  __typename: 'ModuleConnection'
  nodes: Array<ModuleMapQuery_modules_ModuleConnection_nodes_Module>
}

export type ModuleMapQuery_zones_ZoneConnection_nodes_Zone = {
  __typename: 'Zone'
  id: string
  name: string
  centre: any
  capacity: number
  geom: any
  is_suspended: boolean
  zone_type: ZoneType
  bikes: number
  ebikes: number
  scooters: number
}

export type ModuleMapQuery_zones_ZoneConnection = {
  __typename: 'ZoneConnection'
  nodes: Array<ModuleMapQuery_zones_ZoneConnection_nodes_Zone>
}

export type ModuleMapQuery_Query = {
  __typename: 'Query'
  module?: ModuleMapQuery_module_Module | null
  modules?: ModuleMapQuery_modules_ModuleConnection
  zones?: ModuleMapQuery_zones_ZoneConnection
}

export type ModuleMapQueryVariables = Exact<{
  id: Scalars['ID']['input']
  hasLocation: Scalars['Boolean']['input']
  location?: InputMaybe<LocationAreaInput>
}>

export type ModuleMapQuery = ModuleMapQuery_Query

export type ModulesMapQuery_modules_ModuleConnection = {
  __typename: 'ModuleConnection'
  nodes: Array<ModuleMapQuery_modules_ModuleConnection_nodes_Module>
}

export type ModulesMapQuery_all_zones_ZoneConnection_nodes_Zone = {
  __typename: 'Zone'
  id: string
  name: string
  centre: any
  capacity: number
  geom: any
  is_suspended: boolean
  zone_type: ZoneType
  bikes: number
  ebikes: number
  scooters: number
}

export type ModulesMapQuery_all_zones_ZoneConnection = {
  __typename: 'ZoneConnection'
  nodes: Array<ModulesMapQuery_all_zones_ZoneConnection_nodes_Zone>
}

export type ModulesMapQuery_Query = {
  __typename: 'Query'
  modules: ModulesMapQuery_modules_ModuleConnection
  all_zones: ModulesMapQuery_all_zones_ZoneConnection
}

export type ModulesMapQueryVariables = Exact<{
  location?: InputMaybe<LocationAreaInput>
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  in_zone?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<Array<InputMaybe<ModuleWhere>> | InputMaybe<ModuleWhere>>
  suspended?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ModulesMapQuery = ModulesMapQuery_Query

export type ModuleNodeFragment = {
  __typename: 'Module'
  id: string
  hardware_type: ModuleHardware
  is_present: boolean
  location?: ModuleMapQuery_module_Module_location_Location | null
  lock_state?: ModuleMapQuery_module_Module_lock_state_LockEvent | null
  vehicle?: ModuleMapQuery_module_Module_vehicle_Vehicle | null
}

export type ModulesNodesFragment = {
  __typename: 'ModuleConnection'
  nodes: Array<ModuleMapQuery_modules_ModuleConnection_nodes_Module>
}

export type ZoneNodeFragment = {
  __typename: 'Zone'
  id: string
  name: string
  centre: any
  capacity: number
  geom: any
  is_suspended: boolean
  zone_type: ZoneType
  bikes: number
  ebikes: number
  scooters: number
}

export type DecommissionVehicleMutation_Mutation = {
  __typename: 'Mutation'
  decommission?: boolean | null
}

export type DecommissionVehicleMutationVariables = Exact<{
  module_id: Scalars['ID']['input']
}>

export type DecommissionVehicleMutation = DecommissionVehicleMutation_Mutation

export type MoveSchemeMutation_move_scheme_Vehicle = {
  __typename: 'Vehicle'
  id: any
}

export type MoveSchemeMutation_Mutation = {
  __typename: 'Mutation'
  move_scheme: MoveSchemeMutation_move_scheme_Vehicle
}

export type MoveSchemeMutationVariables = Exact<{
  module_id: Scalars['ID']['input']
  scheme_id: Scalars['ID']['input']
}>

export type MoveSchemeMutation = MoveSchemeMutation_Mutation

export type ModuleListFragment = {
  __typename: 'ModuleConnection'
  nodes: Array<ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module>
  pagination: ZoneModulesListQuery_zone_Zone_table_ModuleConnection_pagination_Pagination
}

export type SchemePickerQuery_schemes_SchemeConnection_nodes_Scheme = {
  __typename: 'Scheme'
  id: string
  name: string
}

export type SchemePickerQuery_schemes_SchemeConnection = {
  __typename: 'SchemeConnection'
  nodes: Array<SchemePickerQuery_schemes_SchemeConnection_nodes_Scheme>
}

export type SchemePickerQuery_Query = {
  __typename: 'Query'
  schemes?: SchemePickerQuery_schemes_SchemeConnection | null
}

export type SchemePickerQueryVariables = Exact<{ [key: string]: never }>

export type SchemePickerQuery = SchemePickerQuery_Query

export type SchemePickerFieldsFragment = {
  __typename: 'Scheme'
  id: string
  name: string
}

export type SchemeServiceAreaQuery_scheme_Scheme_service_areas_ServiceAreaConnection = {
  __typename: 'ServiceAreaConnection'
  geojson: any
}

export type SchemeServiceAreaQuery_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
  service_areas: SchemeServiceAreaQuery_scheme_Scheme_service_areas_ServiceAreaConnection
}

export type SchemeServiceAreaQuery_Query = {
  __typename: 'Query'
  scheme?: SchemeServiceAreaQuery_scheme_Scheme | null
}

export type SchemeServiceAreaQueryVariables = Exact<{
  schemeId: Scalars['ID']['input']
}>

export type SchemeServiceAreaQuery = SchemeServiceAreaQuery_Query

export type UserSummaryQuery_user_User_charge_exemptions_ChargeExemption = {
  __typename: 'ChargeExemption'
  charge_type: ChargeType
}

export type UserSummaryQuery_user_User_onfido_applicant_OnfidoApplicant = {
  __typename: 'OnfidoApplicant'
  id: string
}

export type UserSummaryQuery_user_User_driving_licence_check_DrivingLicenceCheck = {
  __typename: 'DrivingLicenceCheck'
  id: string
}

export type UserSummaryQuery_user_User_roles_Role_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
  name: string
}

export type UserSummaryQuery_user_User_roles_Role_org_Org = {
  __typename: 'Org'
  id: string
  name: string
}

export type UserSummaryQuery_user_User_roles_Role_type_RoleType = {
  __typename: 'RoleType'
  id: string
  description?: string | null
}

export type UserSummaryQuery_user_User_roles_Role = {
  __typename: 'Role'
  id: string
  scheme?: UserSummaryQuery_user_User_roles_Role_scheme_Scheme | null
  org?: UserSummaryQuery_user_User_roles_Role_org_Org | null
  type: UserSummaryQuery_user_User_roles_Role_type_RoleType
}

export type UserSummaryQuery_user_User = {
  __typename: 'User'
  id: string
  email?: string | null
  phone_number?: string | null
  has_valid_licence: boolean
  is_over_18: boolean
  has_birthdate: boolean
  identity_checked_at?: any | null
  deleted?: boolean | null
  is_blocked: boolean
  persona_enrolled: boolean
  persona_enrolled_device: boolean
  charge_exemptions: Array<UserSummaryQuery_user_User_charge_exemptions_ChargeExemption>
  onfido_applicant?: UserSummaryQuery_user_User_onfido_applicant_OnfidoApplicant | null
  driving_licence_check?: UserSummaryQuery_user_User_driving_licence_check_DrivingLicenceCheck | null
  roles?: Array<UserSummaryQuery_user_User_roles_Role | null> | null
}

export type UserSummaryQuery_Query = {
  __typename: 'Query'
  user?: UserSummaryQuery_user_User | null
}

export type UserSummaryQueryVariables = Exact<{
  id: Scalars['ID']['input']
  schemeId: Scalars['ID']['input']
}>

export type UserSummaryQuery = UserSummaryQuery_Query

export type UserActionsQuery_user_User_roles_Role_scheme_Scheme = {
  __typename: 'Scheme'
  id: string
  name: string
}

export type UserActionsQuery_user_User_roles_Role_org_Org = {
  __typename: 'Org'
  id: string
  name: string
}

export type UserActionsQuery_user_User_roles_Role_type_RoleType = {
  __typename: 'RoleType'
  id: string
  description?: string | null
}

export type UserActionsQuery_user_User_roles_Role = {
  __typename: 'Role'
  id: string
  scheme?: UserActionsQuery_user_User_roles_Role_scheme_Scheme | null
  org?: UserActionsQuery_user_User_roles_Role_org_Org | null
  type: UserActionsQuery_user_User_roles_Role_type_RoleType
}

export type UserActionsQuery_user_User = {
  __typename: 'User'
  id: string
  email?: string | null
  is_blocked: boolean
  persona_enrolled: boolean
  phone_number?: string | null
  roles?: Array<UserActionsQuery_user_User_roles_Role | null> | null
}

export type UserActionsQuery_Query = {
  __typename: 'Query'
  user?: UserActionsQuery_user_User | null
}

export type UserActionsQueryVariables = Exact<{
  user_id: Scalars['ID']['input']
}>

export type UserActionsQuery = UserActionsQuery_Query

export type UserSchemesQuery_user_User_user_schemes_UserScheme_scheme_Scheme = {
  __typename: 'Scheme'
  name: string
}

export type UserSchemesQuery_user_User_user_schemes_UserScheme = {
  __typename: 'UserScheme'
  scheme_id: string
  scheme?: UserSchemesQuery_user_User_user_schemes_UserScheme_scheme_Scheme | null
}

export type UserSchemesQuery_user_User = {
  __typename: 'User'
  id: string
  user_schemes: Array<UserSchemesQuery_user_User_user_schemes_UserScheme>
}

export type UserSchemesQuery_Query = {
  __typename: 'Query'
  user?: UserSchemesQuery_user_User | null
}

export type UserSchemesQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type UserSchemesQuery = UserSchemesQuery_Query

export type UserSummaryFieldsFragment = {
  __typename: 'User'
  id: string
  email?: string | null
  phone_number?: string | null
  has_valid_licence: boolean
  is_over_18: boolean
  has_birthdate: boolean
  identity_checked_at?: any | null
  deleted?: boolean | null
  is_blocked: boolean
  persona_enrolled: boolean
  persona_enrolled_device: boolean
  onfido_applicant?: UserSummaryQuery_user_User_onfido_applicant_OnfidoApplicant | null
  driving_licence_check?: UserSummaryQuery_user_User_driving_licence_check_DrivingLicenceCheck | null
  roles?: Array<UserSummaryQuery_user_User_roles_Role | null> | null
}

export type BlockUserMutation_Mutation = {
  __typename: 'Mutation'
  block_user_identity_platform_account: boolean
}

export type BlockUserMutationVariables = Exact<{
  user_id: Scalars['ID']['input']
}>

export type BlockUserMutation = BlockUserMutation_Mutation

export type UnBlockUserMutation_Mutation = {
  __typename: 'Mutation'
  unblock_user_identity_platform_account: boolean
}

export type UnBlockUserMutationVariables = Exact<{
  user_id: Scalars['ID']['input']
}>

export type UnBlockUserMutation = UnBlockUserMutation_Mutation

export type BlockUserCardFingerprintMutation_Mutation = {
  __typename: 'Mutation'
  block_user_card_fingerprint: boolean
}

export type BlockUserCardFingerprintMutationVariables = Exact<{
  user_id: Scalars['ID']['input']
}>

export type BlockUserCardFingerprintMutation = BlockUserCardFingerprintMutation_Mutation

export type UnBlockUserCardFingerptintMutation_Mutation = {
  __typename: 'Mutation'
  unblock_user_card_fingerprint: boolean
}

export type UnBlockUserCardFingerptintMutationVariables = Exact<{
  user_id: Scalars['ID']['input']
}>

export type UnBlockUserCardFingerptintMutation = UnBlockUserCardFingerptintMutation_Mutation

export type ForceAddUserPhoneNumberMutation_Mutation = {
  __typename: 'Mutation'
  force_add_user_phone_number: boolean
}

export type ForceAddUserPhoneNumberMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  phoneNumber: Scalars['String']['input']
}>

export type ForceAddUserPhoneNumberMutation = ForceAddUserPhoneNumberMutation_Mutation

export type GetAllVehiclesQuery_vehicles_VehicleConnection_nodes_Vehicle = {
  __typename: 'Vehicle'
  frame_id: string
}

export type GetAllVehiclesQuery_vehicles_VehicleConnection_pagination_Pagination = {
  __typename: 'Pagination'
  total: number
  page: number
  pages: number
}

export type GetAllVehiclesQuery_vehicles_VehicleConnection = {
  __typename: 'VehicleConnection'
  nodes: Array<GetAllVehiclesQuery_vehicles_VehicleConnection_nodes_Vehicle>
  pagination: GetAllVehiclesQuery_vehicles_VehicleConnection_pagination_Pagination
}

export type GetAllVehiclesQuery_Query = {
  __typename: 'Query'
  vehicles: GetAllVehiclesQuery_vehicles_VehicleConnection
}

export type GetAllVehiclesQueryVariables = Exact<{ [key: string]: never }>

export type GetAllVehiclesQuery = GetAllVehiclesQuery_Query

export type ZonesMapQuery_all_zones_ZoneConnection_nodes_Zone = {
  __typename: 'Zone'
  id: string
  name: string
  centre: any
  capacity: number
  geom: any
  is_suspended: boolean
  zone_type: ZoneType
  bikes: number
  ebikes: number
  scooters: number
}

export type ZonesMapQuery_all_zones_ZoneConnection = {
  __typename: 'ZoneConnection'
  nodes: Array<ZonesMapQuery_all_zones_ZoneConnection_nodes_Zone>
}

export type ZonesMapQuery_Query = {
  __typename: 'Query'
  all_zones: ZonesMapQuery_all_zones_ZoneConnection
}

export type ZonesMapQueryVariables = Exact<{
  scheme_id?: InputMaybe<Scalars['ID']['input']>
  suspended?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ZonesMapQuery = ZonesMapQuery_Query

export const AvailabilityRestrictionDataFragmentDoc = gql`
  fragment AvailabilityRestrictionData on SchemeAvailabilityRestriction {
    id
    vehicle_type
    frame_type
    propulsion_type
    start_time
    end_time
    last_status_change_time
  }
`
export const FormattedAmountFragmentDoc = gql`
  fragment FormattedAmount on FormattedAmount {
    unit
    amount
    formatted_amount
  }
`
export const JobBasicFragmentDoc = gql`
  fragment JobBasic on Job {
    uuid
    timestamps {
      created_at
      updated_at
    }
    details {
      assignee {
        id
        email
      }
      priority
      assetTag
      assetName
      assetCategory
      scheme {
        id
        name
      }
      taskId
      description
      status
      role
      notes
      location
      reportedLocation
      locationDescription
      isJustified
      isUnlocked
      createdBy {
        id
        email
      }
      updatedBy {
        id
        email
      }
    }
    vehicle {
      id
      frame_id
      detail {
        module {
          id
          hardware_type
          location {
            id
            latitude
            longitude
            time
            max_error
          }
        }
      }
    }
  }
`
export const JobDetailedFragmentDoc = gql`
  fragment JobDetailed on Job {
    ...JobBasic
    tasks {
      uuid
      details {
        title
        status
        description
        assignee {
          id
          email
        }
        notes
        task_codes_details {
          code
          reason
        }
      }
    }
    justifications {
      justification_id
      description
    }
    attempts {
      assignee {
        email
      }
      created_at
    }
  }
  ${JobBasicFragmentDoc}
`
export const ArchiveJobBasicFragmentDoc = gql`
  fragment ArchiveJobBasic on ArchiveJob {
    sys_id
    uuid
    details {
      taskId
      scheme {
        id
        name
      }
      notes
      status
      assignee {
        id
        email
      }
      isJustified
    }
    timestamps {
      created_at
      updated_at
    }
  }
`
export const ModuleNodeFragmentDoc = gql`
  fragment ModuleNode on Module {
    id
    hardware_type
    is_present
    location {
      id
      latitude
      longitude
      max_error
      time
    }
    lock_state {
      id
      time
      overall_state
      vehicle_lock_state
    }
    vehicle {
      id
      frame_type
      rewards {
        type
      }
    }
  }
`
export const ModulesNodesFragmentDoc = gql`
  fragment ModulesNodes on ModuleConnection {
    nodes {
      id
      hardware_type
      is_present
      location {
        id
        latitude
        longitude
        max_error
        time
      }
      lock_state {
        id
        time
        overall_state
        vehicle_lock_state
      }
      vehicle {
        id
        frame_type
        rewards {
          type
        }
      }
    }
  }
`
export const ZoneNodeFragmentDoc = gql`
  fragment ZoneNode on Zone {
    id
    name
    centre
    capacity
    geom
    is_suspended
    zone_type
    bikes: all_commissioned_modules_count(hardware_type: beryl_bike)
    ebikes: all_commissioned_modules_count(hardware_type: bbe)
    scooters: all_commissioned_modules_count(hardware_type: scooter)
  }
`
export const ModuleListFragmentDoc = gql`
  fragment ModuleList on ModuleConnection {
    nodes {
      id
      unlock_id
      hardware_type
      is_present
      lock_state {
        id
        overall_state
        vehicle_lock_state
      }
      vehicle {
        id
        frame_id
        frame_type
        detail {
          lock_configuration
          scheme {
            id
          }
          vehicle_unlock_id
        }
      }
      iot_state @skip(if: $lean_query) {
        power_mode
        firmware_version
        id
      }
      telemetry @skip(if: $is_mobile) {
        id
        battery_percent
        fix_invalid
        drive {
          battery_percent
          estimated_range
        }
      }
      availability @skip(if: $is_mobile) {
        id
        status
      }
      connectivity @skip(if: $lean_query) {
        is_online
      }
    }
    pagination {
      current: page
      pageSize: per_page
      total
    }
  }
`
export const SchemePickerFieldsFragmentDoc = gql`
  fragment SchemePickerFields on Scheme {
    id
    name
  }
`
export const UserSummaryFieldsFragmentDoc = gql`
  fragment UserSummaryFields on User {
    id
    email
    phone_number
    has_valid_licence
    is_over_18
    has_birthdate
    identity_checked_at
    deleted
    is_blocked
    persona_enrolled
    persona_enrolled_device
    onfido_applicant {
      id
    }
    driving_licence_check {
      id
    }
    roles {
      id
      scheme {
        id
        name
      }
      org {
        id
        name
      }
      type {
        id
        description
      }
    }
  }
`
export const SchemeAvailabilityRestrictionsDocument = gql`
  query SchemeAvailabilityRestrictions($schemeId: ID!) {
    scheme(scheme_id: $schemeId) {
      id
      availability_restriction {
        nodes {
          ...AvailabilityRestrictionData
        }
      }
    }
  }
  ${AvailabilityRestrictionDataFragmentDoc}
`

/**
 * __useSchemeAvailabilityRestrictionsQuery__
 *
 * To run a query within a React component, call `useSchemeAvailabilityRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemeAvailabilityRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemeAvailabilityRestrictionsQuery({
 *   variables: {
 *      schemeId: // value for 'schemeId'
 *   },
 * });
 */
export function useSchemeAvailabilityRestrictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SchemeAvailabilityRestrictionsQuery,
    SchemeAvailabilityRestrictionsQueryVariables
  > &
    (
      | {
          variables: SchemeAvailabilityRestrictionsQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SchemeAvailabilityRestrictionsQuery,
    SchemeAvailabilityRestrictionsQueryVariables
  >(SchemeAvailabilityRestrictionsDocument, options)
}
export function useSchemeAvailabilityRestrictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchemeAvailabilityRestrictionsQuery,
    SchemeAvailabilityRestrictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchemeAvailabilityRestrictionsQuery,
    SchemeAvailabilityRestrictionsQueryVariables
  >(SchemeAvailabilityRestrictionsDocument, options)
}
export function useSchemeAvailabilityRestrictionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SchemeAvailabilityRestrictionsQuery,
    SchemeAvailabilityRestrictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SchemeAvailabilityRestrictionsQuery,
    SchemeAvailabilityRestrictionsQueryVariables
  >(SchemeAvailabilityRestrictionsDocument, options)
}
export type SchemeAvailabilityRestrictionsQueryHookResult = ReturnType<
  typeof useSchemeAvailabilityRestrictionsQuery
>
export type SchemeAvailabilityRestrictionsLazyQueryHookResult = ReturnType<
  typeof useSchemeAvailabilityRestrictionsLazyQuery
>
export type SchemeAvailabilityRestrictionsSuspenseQueryHookResult = ReturnType<
  typeof useSchemeAvailabilityRestrictionsSuspenseQuery
>
export type SchemeAvailabilityRestrictionsQueryResult = Apollo.QueryResult<
  SchemeAvailabilityRestrictionsQuery,
  SchemeAvailabilityRestrictionsQueryVariables
>
export const AddSchemeAvailabilityRestrictionDocument = gql`
  mutation AddSchemeAvailabilityRestriction(
    $scheme_availability_restriction: SchemeAvailabilityRestrictionInput!
  ) {
    add_scheme_availability_restriction(
      scheme_availability_restriction: $scheme_availability_restriction
    ) {
      ...AvailabilityRestrictionData
    }
  }
  ${AvailabilityRestrictionDataFragmentDoc}
`
export type AddSchemeAvailabilityRestrictionMutationFn = Apollo.MutationFunction<
  AddSchemeAvailabilityRestrictionMutation,
  AddSchemeAvailabilityRestrictionMutationVariables
>

/**
 * __useAddSchemeAvailabilityRestrictionMutation__
 *
 * To run a mutation, you first call `useAddSchemeAvailabilityRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSchemeAvailabilityRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSchemeAvailabilityRestrictionMutation, { data, loading, error }] = useAddSchemeAvailabilityRestrictionMutation({
 *   variables: {
 *      scheme_availability_restriction: // value for 'scheme_availability_restriction'
 *   },
 * });
 */
export function useAddSchemeAvailabilityRestrictionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSchemeAvailabilityRestrictionMutation,
    AddSchemeAvailabilityRestrictionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddSchemeAvailabilityRestrictionMutation,
    AddSchemeAvailabilityRestrictionMutationVariables
  >(AddSchemeAvailabilityRestrictionDocument, options)
}
export type AddSchemeAvailabilityRestrictionMutationHookResult = ReturnType<
  typeof useAddSchemeAvailabilityRestrictionMutation
>
export type AddSchemeAvailabilityRestrictionMutationResult = Apollo.MutationResult<AddSchemeAvailabilityRestrictionMutation>
export type AddSchemeAvailabilityRestrictionMutationOptions = Apollo.BaseMutationOptions<
  AddSchemeAvailabilityRestrictionMutation,
  AddSchemeAvailabilityRestrictionMutationVariables
>
export const UpdateSchemeAvailabilityRestrictionDocument = gql`
  mutation UpdateSchemeAvailabilityRestriction(
    $id: ID!
    $scheme_availability_restriction: SchemeAvailabilityRestrictionInput!
  ) {
    update_scheme_availability_restriction(
      scheme_availability_restriction_id: $id
      scheme_availability_restriction: $scheme_availability_restriction
    ) {
      ...AvailabilityRestrictionData
    }
  }
  ${AvailabilityRestrictionDataFragmentDoc}
`
export type UpdateSchemeAvailabilityRestrictionMutationFn = Apollo.MutationFunction<
  UpdateSchemeAvailabilityRestrictionMutation,
  UpdateSchemeAvailabilityRestrictionMutationVariables
>

/**
 * __useUpdateSchemeAvailabilityRestrictionMutation__
 *
 * To run a mutation, you first call `useUpdateSchemeAvailabilityRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchemeAvailabilityRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchemeAvailabilityRestrictionMutation, { data, loading, error }] = useUpdateSchemeAvailabilityRestrictionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      scheme_availability_restriction: // value for 'scheme_availability_restriction'
 *   },
 * });
 */
export function useUpdateSchemeAvailabilityRestrictionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSchemeAvailabilityRestrictionMutation,
    UpdateSchemeAvailabilityRestrictionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSchemeAvailabilityRestrictionMutation,
    UpdateSchemeAvailabilityRestrictionMutationVariables
  >(UpdateSchemeAvailabilityRestrictionDocument, options)
}
export type UpdateSchemeAvailabilityRestrictionMutationHookResult = ReturnType<
  typeof useUpdateSchemeAvailabilityRestrictionMutation
>
export type UpdateSchemeAvailabilityRestrictionMutationResult = Apollo.MutationResult<UpdateSchemeAvailabilityRestrictionMutation>
export type UpdateSchemeAvailabilityRestrictionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSchemeAvailabilityRestrictionMutation,
  UpdateSchemeAvailabilityRestrictionMutationVariables
>
export const RemoveSchemeAvailabilityRestrictionDocument = gql`
  mutation RemoveSchemeAvailabilityRestriction($id: ID!) {
    remove_scheme_availability_restriction(
      scheme_availability_restriction_id: $id
    )
  }
`
export type RemoveSchemeAvailabilityRestrictionMutationFn = Apollo.MutationFunction<
  RemoveSchemeAvailabilityRestrictionMutation,
  RemoveSchemeAvailabilityRestrictionMutationVariables
>

/**
 * __useRemoveSchemeAvailabilityRestrictionMutation__
 *
 * To run a mutation, you first call `useRemoveSchemeAvailabilityRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSchemeAvailabilityRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSchemeAvailabilityRestrictionMutation, { data, loading, error }] = useRemoveSchemeAvailabilityRestrictionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSchemeAvailabilityRestrictionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSchemeAvailabilityRestrictionMutation,
    RemoveSchemeAvailabilityRestrictionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveSchemeAvailabilityRestrictionMutation,
    RemoveSchemeAvailabilityRestrictionMutationVariables
  >(RemoveSchemeAvailabilityRestrictionDocument, options)
}
export type RemoveSchemeAvailabilityRestrictionMutationHookResult = ReturnType<
  typeof useRemoveSchemeAvailabilityRestrictionMutation
>
export type RemoveSchemeAvailabilityRestrictionMutationResult = Apollo.MutationResult<RemoveSchemeAvailabilityRestrictionMutation>
export type RemoveSchemeAvailabilityRestrictionMutationOptions = Apollo.BaseMutationOptions<
  RemoveSchemeAvailabilityRestrictionMutation,
  RemoveSchemeAvailabilityRestrictionMutationVariables
>
export const DeploymentListDocument = gql`
  query DeploymentList($paginate: Paginate) {
    table: deployments(paginate: $paginate) {
      nodes {
        id
        created_at
        version {
          name
          formatted_size
        }
      }
      pagination {
        current: page
        pageSize: per_page
        total
      }
    }
  }
`

/**
 * __useDeploymentListQuery__
 *
 * To run a query within a React component, call `useDeploymentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentListQuery({
 *   variables: {
 *      paginate: // value for 'paginate'
 *   },
 * });
 */
export function useDeploymentListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DeploymentListQuery,
    DeploymentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DeploymentListQuery, DeploymentListQueryVariables>(
    DeploymentListDocument,
    options,
  )
}
export function useDeploymentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeploymentListQuery,
    DeploymentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DeploymentListQuery, DeploymentListQueryVariables>(
    DeploymentListDocument,
    options,
  )
}
export function useDeploymentListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DeploymentListQuery,
    DeploymentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    DeploymentListQuery,
    DeploymentListQueryVariables
  >(DeploymentListDocument, options)
}
export type DeploymentListQueryHookResult = ReturnType<
  typeof useDeploymentListQuery
>
export type DeploymentListLazyQueryHookResult = ReturnType<
  typeof useDeploymentListLazyQuery
>
export type DeploymentListSuspenseQueryHookResult = ReturnType<
  typeof useDeploymentListSuspenseQuery
>
export type DeploymentListQueryResult = Apollo.QueryResult<
  DeploymentListQuery,
  DeploymentListQueryVariables
>
export const AllVersionsDocument = gql`
  query AllVersions {
    firmware {
      id
      name
      supported_hardware
    }
  }
`

/**
 * __useAllVersionsQuery__
 *
 * To run a query within a React component, call `useAllVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVersionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllVersionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllVersionsQuery,
    AllVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllVersionsQuery, AllVersionsQueryVariables>(
    AllVersionsDocument,
    options,
  )
}
export function useAllVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllVersionsQuery,
    AllVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllVersionsQuery, AllVersionsQueryVariables>(
    AllVersionsDocument,
    options,
  )
}
export function useAllVersionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllVersionsQuery,
    AllVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AllVersionsQuery, AllVersionsQueryVariables>(
    AllVersionsDocument,
    options,
  )
}
export type AllVersionsQueryHookResult = ReturnType<typeof useAllVersionsQuery>
export type AllVersionsLazyQueryHookResult = ReturnType<
  typeof useAllVersionsLazyQuery
>
export type AllVersionsSuspenseQueryHookResult = ReturnType<
  typeof useAllVersionsSuspenseQuery
>
export type AllVersionsQueryResult = Apollo.QueryResult<
  AllVersionsQuery,
  AllVersionsQueryVariables
>
export const DeploymentDocument = gql`
  query Deployment($id: ID!, $paginate: Paginate) {
    deployment(deployment_id: $id) {
      id
      created_at
      version {
        name
        size
        created_at
      }
      modules(paginate: $paginate) {
        nodes {
          id
          created_at
          state
          module {
            id
          }
        }
        pagination {
          current: page
          pageSize: per_page
          total
        }
      }
    }
  }
`

/**
 * __useDeploymentQuery__
 *
 * To run a query within a React component, call `useDeploymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      paginate: // value for 'paginate'
 *   },
 * });
 */
export function useDeploymentQuery(
  baseOptions: Apollo.QueryHookOptions<
    DeploymentQuery,
    DeploymentQueryVariables
  > &
    (
      | { variables: DeploymentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DeploymentQuery, DeploymentQueryVariables>(
    DeploymentDocument,
    options,
  )
}
export function useDeploymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeploymentQuery,
    DeploymentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DeploymentQuery, DeploymentQueryVariables>(
    DeploymentDocument,
    options,
  )
}
export function useDeploymentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DeploymentQuery,
    DeploymentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DeploymentQuery, DeploymentQueryVariables>(
    DeploymentDocument,
    options,
  )
}
export type DeploymentQueryHookResult = ReturnType<typeof useDeploymentQuery>
export type DeploymentLazyQueryHookResult = ReturnType<
  typeof useDeploymentLazyQuery
>
export type DeploymentSuspenseQueryHookResult = ReturnType<
  typeof useDeploymentSuspenseQuery
>
export type DeploymentQueryResult = Apollo.QueryResult<
  DeploymentQuery,
  DeploymentQueryVariables
>
export const InvoiceDocument = gql`
  query Invoice($id: ID!) {
    invoice(invoice_id: $id) {
      __typename
      id
      created_at
      description
      status
      ... on JourneyInvoice {
        journey {
          id
          initiator
          duration
          scheme {
            id
          }
        }
        minute_refundable {
          ...FormattedAmount
        }
      }
      scheme_product {
        id
        name
        scheme_accounting {
          currency
        }
      }
      user {
        id
        email
        deleted
      }
      minute_debit {
        ...FormattedAmount
      }
      currency_debit {
        ...FormattedAmount
      }
      currency_refundable {
        ...FormattedAmount
      }
      currency_balance {
        ...FormattedAmount
      }
      transactions {
        nodes {
          id
          created_at
          description
          category
          result {
            status
            failure_message
          }
          debit_amount {
            ...FormattedAmount
          }
          credit_amount {
            ...FormattedAmount
          }
          provider_type
          void
          type
          meta {
            descriptor
            failure_reason
            requester_id
            refund_description
            transaction_label
            charge_type
            minutes_charged
            time_increment_cost
          }
          refund_requester {
            id
            email
          }
          result {
            status
          }
          amount_refundable {
            ...FormattedAmount
          }
          amount_refunded {
            ...FormattedAmount
          }
        }
      }
      ... on ProductInvoice {
        minute_refund_calcs {
          currency_amount {
            amount
            formatted_amount
          }
          minute_adjustment_quantity
        }
      }
    }
  }
  ${FormattedAmountFragmentDoc}
`

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables> &
    ({ variables: InvoiceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(
    InvoiceDocument,
    options,
  )
}
export function useInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoiceQuery,
    InvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(
    InvoiceDocument,
    options,
  )
}
export function useInvoiceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InvoiceQuery,
    InvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<InvoiceQuery, InvoiceQueryVariables>(
    InvoiceDocument,
    options,
  )
}
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>
export type InvoiceSuspenseQueryHookResult = ReturnType<
  typeof useInvoiceSuspenseQuery
>
export type InvoiceQueryResult = Apollo.QueryResult<
  InvoiceQuery,
  InvoiceQueryVariables
>
export const RefundInvoiceDocument = gql`
  mutation RefundInvoice(
    $id: ID!
    $description: String!
    $reason: String!
    $revoke_product: Boolean!
    $refund_transactions: [RefundInvoiceV2InputTransaction!]!
  ) {
    refund_invoice_v2(
      invoice_id: $id
      refund_invoice: {
        reason: $reason
        description: $description
        revoke_product: $revoke_product
        refund_transactions: $refund_transactions
      }
    ) {
      currency {
        amount
        status
        message
      }
      minutes {
        amount
        status
        message
      }
    }
  }
`
export type RefundInvoiceMutationFn = Apollo.MutationFunction<
  RefundInvoiceMutation,
  RefundInvoiceMutationVariables
>

/**
 * __useRefundInvoiceMutation__
 *
 * To run a mutation, you first call `useRefundInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundInvoiceMutation, { data, loading, error }] = useRefundInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      reason: // value for 'reason'
 *      revoke_product: // value for 'revoke_product'
 *      refund_transactions: // value for 'refund_transactions'
 *   },
 * });
 */
export function useRefundInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefundInvoiceMutation,
    RefundInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RefundInvoiceMutation,
    RefundInvoiceMutationVariables
  >(RefundInvoiceDocument, options)
}
export type RefundInvoiceMutationHookResult = ReturnType<
  typeof useRefundInvoiceMutation
>
export type RefundInvoiceMutationResult = Apollo.MutationResult<RefundInvoiceMutation>
export type RefundInvoiceMutationOptions = Apollo.BaseMutationOptions<
  RefundInvoiceMutation,
  RefundInvoiceMutationVariables
>
export const ReverseCancelledJourneyDocument = gql`
  mutation ReverseCancelledJourney(
    $id: ID!
    $description: String!
    $amendedDuration: Int
  ) {
    reverse_cancelled_journey(
      invoice_id: $id
      refund_request: {
        description: $description
        amended_duration: $amendedDuration
      }
    ) {
      currency {
        amount
        status
        message
      }
    }
  }
`
export type ReverseCancelledJourneyMutationFn = Apollo.MutationFunction<
  ReverseCancelledJourneyMutation,
  ReverseCancelledJourneyMutationVariables
>

/**
 * __useReverseCancelledJourneyMutation__
 *
 * To run a mutation, you first call `useReverseCancelledJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverseCancelledJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverseCancelledJourneyMutation, { data, loading, error }] = useReverseCancelledJourneyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      amendedDuration: // value for 'amendedDuration'
 *   },
 * });
 */
export function useReverseCancelledJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReverseCancelledJourneyMutation,
    ReverseCancelledJourneyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReverseCancelledJourneyMutation,
    ReverseCancelledJourneyMutationVariables
  >(ReverseCancelledJourneyDocument, options)
}
export type ReverseCancelledJourneyMutationHookResult = ReturnType<
  typeof useReverseCancelledJourneyMutation
>
export type ReverseCancelledJourneyMutationResult = Apollo.MutationResult<ReverseCancelledJourneyMutation>
export type ReverseCancelledJourneyMutationOptions = Apollo.BaseMutationOptions<
  ReverseCancelledJourneyMutation,
  ReverseCancelledJourneyMutationVariables
>
export const SchemeJobAssetsDocument = gql`
  query SchemeJobAssets($scheme_id: ID!) {
    scheme_job_assets(scheme_id: $scheme_id) {
      assetTag
      assetName
      assetCategory
    }
  }
`

/**
 * __useSchemeJobAssetsQuery__
 *
 * To run a query within a React component, call `useSchemeJobAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemeJobAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemeJobAssetsQuery({
 *   variables: {
 *      scheme_id: // value for 'scheme_id'
 *   },
 * });
 */
export function useSchemeJobAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SchemeJobAssetsQuery,
    SchemeJobAssetsQueryVariables
  > &
    (
      | { variables: SchemeJobAssetsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SchemeJobAssetsQuery, SchemeJobAssetsQueryVariables>(
    SchemeJobAssetsDocument,
    options,
  )
}
export function useSchemeJobAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchemeJobAssetsQuery,
    SchemeJobAssetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchemeJobAssetsQuery,
    SchemeJobAssetsQueryVariables
  >(SchemeJobAssetsDocument, options)
}
export function useSchemeJobAssetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SchemeJobAssetsQuery,
    SchemeJobAssetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SchemeJobAssetsQuery,
    SchemeJobAssetsQueryVariables
  >(SchemeJobAssetsDocument, options)
}
export type SchemeJobAssetsQueryHookResult = ReturnType<
  typeof useSchemeJobAssetsQuery
>
export type SchemeJobAssetsLazyQueryHookResult = ReturnType<
  typeof useSchemeJobAssetsLazyQuery
>
export type SchemeJobAssetsSuspenseQueryHookResult = ReturnType<
  typeof useSchemeJobAssetsSuspenseQuery
>
export type SchemeJobAssetsQueryResult = Apollo.QueryResult<
  SchemeJobAssetsQuery,
  SchemeJobAssetsQueryVariables
>
export const JobListDocument = gql`
  query JobList($paginate: Paginate, $job_filters: JobFiltersInput) {
    table: jobs_v2(paginate: $paginate, job_filters: $job_filters) {
      nodes {
        ...JobBasic
      }
      pagination {
        current: page
        pageSize: per_page
        total
      }
    }
  }
  ${JobBasicFragmentDoc}
`

/**
 * __useJobListQuery__
 *
 * To run a query within a React component, call `useJobListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobListQuery({
 *   variables: {
 *      paginate: // value for 'paginate'
 *      job_filters: // value for 'job_filters'
 *   },
 * });
 */
export function useJobListQuery(
  baseOptions?: Apollo.QueryHookOptions<JobListQuery, JobListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobListQuery, JobListQueryVariables>(
    JobListDocument,
    options,
  )
}
export function useJobListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobListQuery,
    JobListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobListQuery, JobListQueryVariables>(
    JobListDocument,
    options,
  )
}
export function useJobListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    JobListQuery,
    JobListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<JobListQuery, JobListQueryVariables>(
    JobListDocument,
    options,
  )
}
export type JobListQueryHookResult = ReturnType<typeof useJobListQuery>
export type JobListLazyQueryHookResult = ReturnType<typeof useJobListLazyQuery>
export type JobListSuspenseQueryHookResult = ReturnType<
  typeof useJobListSuspenseQuery
>
export type JobListQueryResult = Apollo.QueryResult<
  JobListQuery,
  JobListQueryVariables
>
export const JobDocument = gql`
  query Job($job_id: ID!) {
    job(job_uuid: $job_id) {
      ...JobDetailed
    }
  }
  ${JobDetailedFragmentDoc}
`

/**
 * __useJobQuery__
 *
 * To run a query within a React component, call `useJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuery({
 *   variables: {
 *      job_id: // value for 'job_id'
 *   },
 * });
 */
export function useJobQuery(
  baseOptions: Apollo.QueryHookOptions<JobQuery, JobQueryVariables> &
    ({ variables: JobQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobQuery, JobQueryVariables>(JobDocument, options)
}
export function useJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobQuery, JobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobQuery, JobQueryVariables>(JobDocument, options)
}
export function useJobSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<JobQuery, JobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<JobQuery, JobQueryVariables>(
    JobDocument,
    options,
  )
}
export type JobQueryHookResult = ReturnType<typeof useJobQuery>
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>
export type JobSuspenseQueryHookResult = ReturnType<typeof useJobSuspenseQuery>
export type JobQueryResult = Apollo.QueryResult<JobQuery, JobQueryVariables>
export const ArchiveJobListDocument = gql`
  query ArchiveJobList($paginate: Paginate, $asset_tag: ID!) {
    table: archive_jobs(paginate: $paginate, asset_tag: $asset_tag) {
      nodes {
        ...ArchiveJobBasic
      }
      pagination {
        current: page
        pageSize: per_page
        total
      }
    }
  }
  ${ArchiveJobBasicFragmentDoc}
`

/**
 * __useArchiveJobListQuery__
 *
 * To run a query within a React component, call `useArchiveJobListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveJobListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveJobListQuery({
 *   variables: {
 *      paginate: // value for 'paginate'
 *      asset_tag: // value for 'asset_tag'
 *   },
 * });
 */
export function useArchiveJobListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArchiveJobListQuery,
    ArchiveJobListQueryVariables
  > &
    (
      | { variables: ArchiveJobListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArchiveJobListQuery, ArchiveJobListQueryVariables>(
    ArchiveJobListDocument,
    options,
  )
}
export function useArchiveJobListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArchiveJobListQuery,
    ArchiveJobListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArchiveJobListQuery, ArchiveJobListQueryVariables>(
    ArchiveJobListDocument,
    options,
  )
}
export function useArchiveJobListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ArchiveJobListQuery,
    ArchiveJobListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ArchiveJobListQuery,
    ArchiveJobListQueryVariables
  >(ArchiveJobListDocument, options)
}
export type ArchiveJobListQueryHookResult = ReturnType<
  typeof useArchiveJobListQuery
>
export type ArchiveJobListLazyQueryHookResult = ReturnType<
  typeof useArchiveJobListLazyQuery
>
export type ArchiveJobListSuspenseQueryHookResult = ReturnType<
  typeof useArchiveJobListSuspenseQuery
>
export type ArchiveJobListQueryResult = Apollo.QueryResult<
  ArchiveJobListQuery,
  ArchiveJobListQueryVariables
>
export const JobAutomationsDocument = gql`
  query JobAutomations($filters: AutomationFilters) {
    automations(filters: $filters) {
      justification_id
      description
      taskId
      side_effects
      jobs {
        uuid
        frame_id
        scheme_id
        created_at
      }
    }
  }
`

/**
 * __useJobAutomationsQuery__
 *
 * To run a query within a React component, call `useJobAutomationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobAutomationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobAutomationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useJobAutomationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobAutomationsQuery,
    JobAutomationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobAutomationsQuery, JobAutomationsQueryVariables>(
    JobAutomationsDocument,
    options,
  )
}
export function useJobAutomationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobAutomationsQuery,
    JobAutomationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobAutomationsQuery, JobAutomationsQueryVariables>(
    JobAutomationsDocument,
    options,
  )
}
export function useJobAutomationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    JobAutomationsQuery,
    JobAutomationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    JobAutomationsQuery,
    JobAutomationsQueryVariables
  >(JobAutomationsDocument, options)
}
export type JobAutomationsQueryHookResult = ReturnType<
  typeof useJobAutomationsQuery
>
export type JobAutomationsLazyQueryHookResult = ReturnType<
  typeof useJobAutomationsLazyQuery
>
export type JobAutomationsSuspenseQueryHookResult = ReturnType<
  typeof useJobAutomationsSuspenseQuery
>
export type JobAutomationsQueryResult = Apollo.QueryResult<
  JobAutomationsQuery,
  JobAutomationsQueryVariables
>
export const UnarchiveJobDocument = gql`
  mutation UnarchiveJob($sys_id: ID!) {
    archive_restore(sys_id: $sys_id)
  }
`
export type UnarchiveJobMutationFn = Apollo.MutationFunction<
  UnarchiveJobMutation,
  UnarchiveJobMutationVariables
>

/**
 * __useUnarchiveJobMutation__
 *
 * To run a mutation, you first call `useUnarchiveJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveJobMutation, { data, loading, error }] = useUnarchiveJobMutation({
 *   variables: {
 *      sys_id: // value for 'sys_id'
 *   },
 * });
 */
export function useUnarchiveJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveJobMutation,
    UnarchiveJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnarchiveJobMutation,
    UnarchiveJobMutationVariables
  >(UnarchiveJobDocument, options)
}
export type UnarchiveJobMutationHookResult = ReturnType<
  typeof useUnarchiveJobMutation
>
export type UnarchiveJobMutationResult = Apollo.MutationResult<UnarchiveJobMutation>
export type UnarchiveJobMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveJobMutation,
  UnarchiveJobMutationVariables
>
export const TriggerJobAutomationDocument = gql`
  mutation TriggerJobAutomation(
    $justification_id: String!
    $description: String!
    $taskId: String!
    $side_effects: [JobAutomationSideEffect]!
    $assets: [String!]!
    $dry_run: Boolean!
  ) {
    job_automation(
      justification_id: $justification_id
      description: $description
      taskId: $taskId
      side_effects: $side_effects
      assets: $assets
      dry_run: $dry_run
    )
  }
`
export type TriggerJobAutomationMutationFn = Apollo.MutationFunction<
  TriggerJobAutomationMutation,
  TriggerJobAutomationMutationVariables
>

/**
 * __useTriggerJobAutomationMutation__
 *
 * To run a mutation, you first call `useTriggerJobAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerJobAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerJobAutomationMutation, { data, loading, error }] = useTriggerJobAutomationMutation({
 *   variables: {
 *      justification_id: // value for 'justification_id'
 *      description: // value for 'description'
 *      taskId: // value for 'taskId'
 *      side_effects: // value for 'side_effects'
 *      assets: // value for 'assets'
 *      dry_run: // value for 'dry_run'
 *   },
 * });
 */
export function useTriggerJobAutomationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerJobAutomationMutation,
    TriggerJobAutomationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TriggerJobAutomationMutation,
    TriggerJobAutomationMutationVariables
  >(TriggerJobAutomationDocument, options)
}
export type TriggerJobAutomationMutationHookResult = ReturnType<
  typeof useTriggerJobAutomationMutation
>
export type TriggerJobAutomationMutationResult = Apollo.MutationResult<TriggerJobAutomationMutation>
export type TriggerJobAutomationMutationOptions = Apollo.BaseMutationOptions<
  TriggerJobAutomationMutation,
  TriggerJobAutomationMutationVariables
>
export const JourneyListDocument = gql`
  query JourneyList(
    $module_id: ID
    $user_id: ID
    $scheme_id: ID
    $paginate: Paginate
    $is_mobile: Boolean!
    $omit_user: Boolean!
    $where: [JourneyWhere]
  ) {
    table: journeys(
      module_id: $module_id
      user_id: $user_id
      scheme_id: $scheme_id
      where: $where
      paginate: $paginate
    ) {
      nodes {
        id
        scheme {
          id
          name
        }
        module {
          id
          hardware_type
          module_state {
            id
            ... on ModuleState {
              assist_config
            }
          }
          bike {
            vehicle_id
            frame {
              id
            }
            scheme {
              id
            }
          }
        }
        user @skip(if: $omit_user) {
          id
          email
          deleted
        }
        is_complete @skip(if: $is_mobile)
        state {
          id
          time @skip(if: $is_mobile)
          message
        }
      }
      pagination {
        current: page
        pageSize: per_page
        total
      }
    }
  }
`

/**
 * __useJourneyListQuery__
 *
 * To run a query within a React component, call `useJourneyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJourneyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJourneyListQuery({
 *   variables: {
 *      module_id: // value for 'module_id'
 *      user_id: // value for 'user_id'
 *      scheme_id: // value for 'scheme_id'
 *      paginate: // value for 'paginate'
 *      is_mobile: // value for 'is_mobile'
 *      omit_user: // value for 'omit_user'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useJourneyListQuery(
  baseOptions: Apollo.QueryHookOptions<
    JourneyListQuery,
    JourneyListQueryVariables
  > &
    (
      | { variables: JourneyListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JourneyListQuery, JourneyListQueryVariables>(
    JourneyListDocument,
    options,
  )
}
export function useJourneyListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JourneyListQuery,
    JourneyListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JourneyListQuery, JourneyListQueryVariables>(
    JourneyListDocument,
    options,
  )
}
export function useJourneyListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    JourneyListQuery,
    JourneyListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<JourneyListQuery, JourneyListQueryVariables>(
    JourneyListDocument,
    options,
  )
}
export type JourneyListQueryHookResult = ReturnType<typeof useJourneyListQuery>
export type JourneyListLazyQueryHookResult = ReturnType<
  typeof useJourneyListLazyQuery
>
export type JourneyListSuspenseQueryHookResult = ReturnType<
  typeof useJourneyListSuspenseQuery
>
export type JourneyListQueryResult = Apollo.QueryResult<
  JourneyListQuery,
  JourneyListQueryVariables
>
export const ZoneModulesListDocument = gql`
  query ZoneModulesList(
    $zone_id: ID!
    $paginate: Paginate
    $is_mobile: Boolean!
    $lean_query: Boolean!
  ) {
    zone(zone_id: $zone_id) {
      id
      table: modules(paginate: $paginate) {
        ...ModuleList
      }
    }
  }
  ${ModuleListFragmentDoc}
`

/**
 * __useZoneModulesListQuery__
 *
 * To run a query within a React component, call `useZoneModulesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneModulesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneModulesListQuery({
 *   variables: {
 *      zone_id: // value for 'zone_id'
 *      paginate: // value for 'paginate'
 *      is_mobile: // value for 'is_mobile'
 *      lean_query: // value for 'lean_query'
 *   },
 * });
 */
export function useZoneModulesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ZoneModulesListQuery,
    ZoneModulesListQueryVariables
  > &
    (
      | { variables: ZoneModulesListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ZoneModulesListQuery, ZoneModulesListQueryVariables>(
    ZoneModulesListDocument,
    options,
  )
}
export function useZoneModulesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoneModulesListQuery,
    ZoneModulesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ZoneModulesListQuery,
    ZoneModulesListQueryVariables
  >(ZoneModulesListDocument, options)
}
export function useZoneModulesListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ZoneModulesListQuery,
    ZoneModulesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ZoneModulesListQuery,
    ZoneModulesListQueryVariables
  >(ZoneModulesListDocument, options)
}
export type ZoneModulesListQueryHookResult = ReturnType<
  typeof useZoneModulesListQuery
>
export type ZoneModulesListLazyQueryHookResult = ReturnType<
  typeof useZoneModulesListLazyQuery
>
export type ZoneModulesListSuspenseQueryHookResult = ReturnType<
  typeof useZoneModulesListSuspenseQuery
>
export type ZoneModulesListQueryResult = Apollo.QueryResult<
  ZoneModulesListQuery,
  ZoneModulesListQueryVariables
>
export const ModulesListDocument = gql`
  query ModulesList(
    $scheme_id: ID
    $paginate: Paginate
    $where: [ModuleWhere]
    $order_by: [ModuleOrderby]
    $is_mobile: Boolean!
    $lean_query: Boolean!
  ) {
    table: modules(
      scheme_id: $scheme_id
      paginate: $paginate
      where: $where
      order_by: $order_by
    ) {
      ...ModuleList
    }
  }
  ${ModuleListFragmentDoc}
`

/**
 * __useModulesListQuery__
 *
 * To run a query within a React component, call `useModulesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useModulesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModulesListQuery({
 *   variables: {
 *      scheme_id: // value for 'scheme_id'
 *      paginate: // value for 'paginate'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      is_mobile: // value for 'is_mobile'
 *      lean_query: // value for 'lean_query'
 *   },
 * });
 */
export function useModulesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModulesListQuery,
    ModulesListQueryVariables
  > &
    (
      | { variables: ModulesListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ModulesListQuery, ModulesListQueryVariables>(
    ModulesListDocument,
    options,
  )
}
export function useModulesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModulesListQuery,
    ModulesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ModulesListQuery, ModulesListQueryVariables>(
    ModulesListDocument,
    options,
  )
}
export function useModulesListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ModulesListQuery,
    ModulesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ModulesListQuery, ModulesListQueryVariables>(
    ModulesListDocument,
    options,
  )
}
export type ModulesListQueryHookResult = ReturnType<typeof useModulesListQuery>
export type ModulesListLazyQueryHookResult = ReturnType<
  typeof useModulesListLazyQuery
>
export type ModulesListSuspenseQueryHookResult = ReturnType<
  typeof useModulesListSuspenseQuery
>
export type ModulesListQueryResult = Apollo.QueryResult<
  ModulesListQuery,
  ModulesListQueryVariables
>
export const ModuleVehicleCommissionedDocument = gql`
  query ModuleVehicleCommissioned($module_id: ID!) {
    module(module_id: $module_id) {
      hardware_type
      id
      vehicle {
        id
        frame_id
        frame_type
        detail {
          lock_configuration
          vehicle_unlock_id
          scheme {
            id
            name
          }
        }
      }
    }
  }
`

/**
 * __useModuleVehicleCommissionedQuery__
 *
 * To run a query within a React component, call `useModuleVehicleCommissionedQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleVehicleCommissionedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleVehicleCommissionedQuery({
 *   variables: {
 *      module_id: // value for 'module_id'
 *   },
 * });
 */
export function useModuleVehicleCommissionedQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModuleVehicleCommissionedQuery,
    ModuleVehicleCommissionedQueryVariables
  > &
    (
      | { variables: ModuleVehicleCommissionedQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ModuleVehicleCommissionedQuery,
    ModuleVehicleCommissionedQueryVariables
  >(ModuleVehicleCommissionedDocument, options)
}
export function useModuleVehicleCommissionedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModuleVehicleCommissionedQuery,
    ModuleVehicleCommissionedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ModuleVehicleCommissionedQuery,
    ModuleVehicleCommissionedQueryVariables
  >(ModuleVehicleCommissionedDocument, options)
}
export function useModuleVehicleCommissionedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ModuleVehicleCommissionedQuery,
    ModuleVehicleCommissionedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ModuleVehicleCommissionedQuery,
    ModuleVehicleCommissionedQueryVariables
  >(ModuleVehicleCommissionedDocument, options)
}
export type ModuleVehicleCommissionedQueryHookResult = ReturnType<
  typeof useModuleVehicleCommissionedQuery
>
export type ModuleVehicleCommissionedLazyQueryHookResult = ReturnType<
  typeof useModuleVehicleCommissionedLazyQuery
>
export type ModuleVehicleCommissionedSuspenseQueryHookResult = ReturnType<
  typeof useModuleVehicleCommissionedSuspenseQuery
>
export type ModuleVehicleCommissionedQueryResult = Apollo.QueryResult<
  ModuleVehicleCommissionedQuery,
  ModuleVehicleCommissionedQueryVariables
>
export const ModuleVehicleDocument = gql`
  query ModuleVehicle($module_id: ID!) {
    module(module_id: $module_id) {
      vehicle {
        id
        frame_id
        frame_type
        hardware_type
        detail {
          scheme {
            id
            name
          }
          module {
            id
          }
        }
      }
    }
  }
`

/**
 * __useModuleVehicleQuery__
 *
 * To run a query within a React component, call `useModuleVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleVehicleQuery({
 *   variables: {
 *      module_id: // value for 'module_id'
 *   },
 * });
 */
export function useModuleVehicleQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModuleVehicleQuery,
    ModuleVehicleQueryVariables
  > &
    (
      | { variables: ModuleVehicleQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ModuleVehicleQuery, ModuleVehicleQueryVariables>(
    ModuleVehicleDocument,
    options,
  )
}
export function useModuleVehicleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModuleVehicleQuery,
    ModuleVehicleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ModuleVehicleQuery, ModuleVehicleQueryVariables>(
    ModuleVehicleDocument,
    options,
  )
}
export function useModuleVehicleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ModuleVehicleQuery,
    ModuleVehicleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ModuleVehicleQuery,
    ModuleVehicleQueryVariables
  >(ModuleVehicleDocument, options)
}
export type ModuleVehicleQueryHookResult = ReturnType<
  typeof useModuleVehicleQuery
>
export type ModuleVehicleLazyQueryHookResult = ReturnType<
  typeof useModuleVehicleLazyQuery
>
export type ModuleVehicleSuspenseQueryHookResult = ReturnType<
  typeof useModuleVehicleSuspenseQuery
>
export type ModuleVehicleQueryResult = Apollo.QueryResult<
  ModuleVehicleQuery,
  ModuleVehicleQueryVariables
>
export const CommissionVehicleDocument = gql`
  mutation CommissionVehicle(
    $module_id: ID!
    $frame_id: ID!
    $scheme_id: ID!
    $lock_configuration: LockConfiguration!
    $frame_type: FrameType!
    $vehicle_unlock_id: ID
  ) {
    commission_V2(
      module_id: $module_id
      frame_id: $frame_id
      scheme_id: $scheme_id
      lock_configuration: $lock_configuration
      frame_type: $frame_type
      vehicle_unlock_id: $vehicle_unlock_id
    ) {
      id
      detail {
        lock_configuration
        vehicle_unlock_id
        scheme {
          id
        }
      }
      frame_id
    }
  }
`
export type CommissionVehicleMutationFn = Apollo.MutationFunction<
  CommissionVehicleMutation,
  CommissionVehicleMutationVariables
>

/**
 * __useCommissionVehicleMutation__
 *
 * To run a mutation, you first call `useCommissionVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommissionVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commissionVehicleMutation, { data, loading, error }] = useCommissionVehicleMutation({
 *   variables: {
 *      module_id: // value for 'module_id'
 *      frame_id: // value for 'frame_id'
 *      scheme_id: // value for 'scheme_id'
 *      lock_configuration: // value for 'lock_configuration'
 *      frame_type: // value for 'frame_type'
 *      vehicle_unlock_id: // value for 'vehicle_unlock_id'
 *   },
 * });
 */
export function useCommissionVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CommissionVehicleMutation,
    CommissionVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CommissionVehicleMutation,
    CommissionVehicleMutationVariables
  >(CommissionVehicleDocument, options)
}
export type CommissionVehicleMutationHookResult = ReturnType<
  typeof useCommissionVehicleMutation
>
export type CommissionVehicleMutationResult = Apollo.MutationResult<CommissionVehicleMutation>
export type CommissionVehicleMutationOptions = Apollo.BaseMutationOptions<
  CommissionVehicleMutation,
  CommissionVehicleMutationVariables
>
export const ModuleMapDocument = gql`
  query ModuleMap(
    $id: ID!
    $hasLocation: Boolean!
    $location: LocationAreaInput
  ) {
    module(module_id: $id) {
      ...ModuleNode
    }
    modules(location: $location) @include(if: $hasLocation) {
      ...ModulesNodes
    }
    zones(location: $location) @include(if: $hasLocation) {
      nodes {
        ...ZoneNode
      }
    }
  }
  ${ModuleNodeFragmentDoc}
  ${ModulesNodesFragmentDoc}
  ${ZoneNodeFragmentDoc}
`

/**
 * __useModuleMapQuery__
 *
 * To run a query within a React component, call `useModuleMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleMapQuery({
 *   variables: {
 *      id: // value for 'id'
 *      hasLocation: // value for 'hasLocation'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useModuleMapQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModuleMapQuery,
    ModuleMapQueryVariables
  > &
    (
      | { variables: ModuleMapQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ModuleMapQuery, ModuleMapQueryVariables>(
    ModuleMapDocument,
    options,
  )
}
export function useModuleMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModuleMapQuery,
    ModuleMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ModuleMapQuery, ModuleMapQueryVariables>(
    ModuleMapDocument,
    options,
  )
}
export function useModuleMapSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ModuleMapQuery,
    ModuleMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ModuleMapQuery, ModuleMapQueryVariables>(
    ModuleMapDocument,
    options,
  )
}
export type ModuleMapQueryHookResult = ReturnType<typeof useModuleMapQuery>
export type ModuleMapLazyQueryHookResult = ReturnType<
  typeof useModuleMapLazyQuery
>
export type ModuleMapSuspenseQueryHookResult = ReturnType<
  typeof useModuleMapSuspenseQuery
>
export type ModuleMapQueryResult = Apollo.QueryResult<
  ModuleMapQuery,
  ModuleMapQueryVariables
>
export const ModulesMapDocument = gql`
  query ModulesMap(
    $location: LocationAreaInput
    $scheme_id: ID
    $in_zone: Boolean
    $where: [ModuleWhere]
    $suspended: Boolean
  ) {
    modules(
      location: $location
      scheme_id: $scheme_id
      in_zone: $in_zone
      where: $where
    ) {
      ...ModulesNodes
    }
    all_zones(
      location: $location
      scheme_id: $scheme_id
      is_suspended: $suspended
      paginate: { page: 1, per_page: 800 }
    ) {
      nodes {
        ...ZoneNode
      }
    }
  }
  ${ModulesNodesFragmentDoc}
  ${ZoneNodeFragmentDoc}
`

/**
 * __useModulesMapQuery__
 *
 * To run a query within a React component, call `useModulesMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useModulesMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModulesMapQuery({
 *   variables: {
 *      location: // value for 'location'
 *      scheme_id: // value for 'scheme_id'
 *      in_zone: // value for 'in_zone'
 *      where: // value for 'where'
 *      suspended: // value for 'suspended'
 *   },
 * });
 */
export function useModulesMapQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ModulesMapQuery,
    ModulesMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ModulesMapQuery, ModulesMapQueryVariables>(
    ModulesMapDocument,
    options,
  )
}
export function useModulesMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModulesMapQuery,
    ModulesMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ModulesMapQuery, ModulesMapQueryVariables>(
    ModulesMapDocument,
    options,
  )
}
export function useModulesMapSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ModulesMapQuery,
    ModulesMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ModulesMapQuery, ModulesMapQueryVariables>(
    ModulesMapDocument,
    options,
  )
}
export type ModulesMapQueryHookResult = ReturnType<typeof useModulesMapQuery>
export type ModulesMapLazyQueryHookResult = ReturnType<
  typeof useModulesMapLazyQuery
>
export type ModulesMapSuspenseQueryHookResult = ReturnType<
  typeof useModulesMapSuspenseQuery
>
export type ModulesMapQueryResult = Apollo.QueryResult<
  ModulesMapQuery,
  ModulesMapQueryVariables
>
export const DecommissionVehicleDocument = gql`
  mutation DecommissionVehicle($module_id: ID!) {
    decommission(module_id: $module_id)
  }
`
export type DecommissionVehicleMutationFn = Apollo.MutationFunction<
  DecommissionVehicleMutation,
  DecommissionVehicleMutationVariables
>

/**
 * __useDecommissionVehicleMutation__
 *
 * To run a mutation, you first call `useDecommissionVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecommissionVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decommissionVehicleMutation, { data, loading, error }] = useDecommissionVehicleMutation({
 *   variables: {
 *      module_id: // value for 'module_id'
 *   },
 * });
 */
export function useDecommissionVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DecommissionVehicleMutation,
    DecommissionVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DecommissionVehicleMutation,
    DecommissionVehicleMutationVariables
  >(DecommissionVehicleDocument, options)
}
export type DecommissionVehicleMutationHookResult = ReturnType<
  typeof useDecommissionVehicleMutation
>
export type DecommissionVehicleMutationResult = Apollo.MutationResult<DecommissionVehicleMutation>
export type DecommissionVehicleMutationOptions = Apollo.BaseMutationOptions<
  DecommissionVehicleMutation,
  DecommissionVehicleMutationVariables
>
export const MoveSchemeDocument = gql`
  mutation MoveScheme($module_id: ID!, $scheme_id: ID!) {
    move_scheme(module_id: $module_id, scheme_id: $scheme_id) {
      id
    }
  }
`
export type MoveSchemeMutationFn = Apollo.MutationFunction<
  MoveSchemeMutation,
  MoveSchemeMutationVariables
>

/**
 * __useMoveSchemeMutation__
 *
 * To run a mutation, you first call `useMoveSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveSchemeMutation, { data, loading, error }] = useMoveSchemeMutation({
 *   variables: {
 *      module_id: // value for 'module_id'
 *      scheme_id: // value for 'scheme_id'
 *   },
 * });
 */
export function useMoveSchemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveSchemeMutation,
    MoveSchemeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveSchemeMutation, MoveSchemeMutationVariables>(
    MoveSchemeDocument,
    options,
  )
}
export type MoveSchemeMutationHookResult = ReturnType<
  typeof useMoveSchemeMutation
>
export type MoveSchemeMutationResult = Apollo.MutationResult<MoveSchemeMutation>
export type MoveSchemeMutationOptions = Apollo.BaseMutationOptions<
  MoveSchemeMutation,
  MoveSchemeMutationVariables
>
export const SchemePickerDocument = gql`
  query SchemePicker {
    schemes(paginate: { per_page: 100 }) {
      nodes {
        ...SchemePickerFields
      }
    }
  }
  ${SchemePickerFieldsFragmentDoc}
`

/**
 * __useSchemePickerQuery__
 *
 * To run a query within a React component, call `useSchemePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemePickerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchemePickerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchemePickerQuery,
    SchemePickerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SchemePickerQuery, SchemePickerQueryVariables>(
    SchemePickerDocument,
    options,
  )
}
export function useSchemePickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchemePickerQuery,
    SchemePickerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SchemePickerQuery, SchemePickerQueryVariables>(
    SchemePickerDocument,
    options,
  )
}
export function useSchemePickerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SchemePickerQuery,
    SchemePickerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SchemePickerQuery, SchemePickerQueryVariables>(
    SchemePickerDocument,
    options,
  )
}
export type SchemePickerQueryHookResult = ReturnType<
  typeof useSchemePickerQuery
>
export type SchemePickerLazyQueryHookResult = ReturnType<
  typeof useSchemePickerLazyQuery
>
export type SchemePickerSuspenseQueryHookResult = ReturnType<
  typeof useSchemePickerSuspenseQuery
>
export type SchemePickerQueryResult = Apollo.QueryResult<
  SchemePickerQuery,
  SchemePickerQueryVariables
>
export const SchemeServiceAreaDocument = gql`
  query SchemeServiceArea($schemeId: ID!) {
    scheme(scheme_id: $schemeId) {
      id
      service_areas(paginate: { per_page: 200 }) {
        geojson
      }
    }
  }
`

/**
 * __useSchemeServiceAreaQuery__
 *
 * To run a query within a React component, call `useSchemeServiceAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemeServiceAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemeServiceAreaQuery({
 *   variables: {
 *      schemeId: // value for 'schemeId'
 *   },
 * });
 */
export function useSchemeServiceAreaQuery(
  baseOptions: Apollo.QueryHookOptions<
    SchemeServiceAreaQuery,
    SchemeServiceAreaQueryVariables
  > &
    (
      | { variables: SchemeServiceAreaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SchemeServiceAreaQuery,
    SchemeServiceAreaQueryVariables
  >(SchemeServiceAreaDocument, options)
}
export function useSchemeServiceAreaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchemeServiceAreaQuery,
    SchemeServiceAreaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchemeServiceAreaQuery,
    SchemeServiceAreaQueryVariables
  >(SchemeServiceAreaDocument, options)
}
export function useSchemeServiceAreaSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SchemeServiceAreaQuery,
    SchemeServiceAreaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SchemeServiceAreaQuery,
    SchemeServiceAreaQueryVariables
  >(SchemeServiceAreaDocument, options)
}
export type SchemeServiceAreaQueryHookResult = ReturnType<
  typeof useSchemeServiceAreaQuery
>
export type SchemeServiceAreaLazyQueryHookResult = ReturnType<
  typeof useSchemeServiceAreaLazyQuery
>
export type SchemeServiceAreaSuspenseQueryHookResult = ReturnType<
  typeof useSchemeServiceAreaSuspenseQuery
>
export type SchemeServiceAreaQueryResult = Apollo.QueryResult<
  SchemeServiceAreaQuery,
  SchemeServiceAreaQueryVariables
>
export const UserSummaryDocument = gql`
  query UserSummary($id: ID!, $schemeId: ID!) {
    user(user_id: $id) {
      ...UserSummaryFields
      charge_exemptions(scheme_id: $schemeId) {
        charge_type
      }
    }
  }
  ${UserSummaryFieldsFragmentDoc}
`

/**
 * __useUserSummaryQuery__
 *
 * To run a query within a React component, call `useUserSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      schemeId: // value for 'schemeId'
 *   },
 * });
 */
export function useUserSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserSummaryQuery,
    UserSummaryQueryVariables
  > &
    (
      | { variables: UserSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserSummaryQuery, UserSummaryQueryVariables>(
    UserSummaryDocument,
    options,
  )
}
export function useUserSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSummaryQuery,
    UserSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserSummaryQuery, UserSummaryQueryVariables>(
    UserSummaryDocument,
    options,
  )
}
export function useUserSummarySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserSummaryQuery,
    UserSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UserSummaryQuery, UserSummaryQueryVariables>(
    UserSummaryDocument,
    options,
  )
}
export type UserSummaryQueryHookResult = ReturnType<typeof useUserSummaryQuery>
export type UserSummaryLazyQueryHookResult = ReturnType<
  typeof useUserSummaryLazyQuery
>
export type UserSummarySuspenseQueryHookResult = ReturnType<
  typeof useUserSummarySuspenseQuery
>
export type UserSummaryQueryResult = Apollo.QueryResult<
  UserSummaryQuery,
  UserSummaryQueryVariables
>
export const UserActionsDocument = gql`
  query UserActions($user_id: ID!) {
    user(user_id: $user_id) {
      id
      email
      is_blocked
      persona_enrolled
      phone_number
      roles {
        id
        scheme {
          id
          name
        }
        org {
          id
          name
        }
        type {
          id
          description
        }
      }
    }
  }
`

/**
 * __useUserActionsQuery__
 *
 * To run a query within a React component, call `useUserActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActionsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUserActionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserActionsQuery,
    UserActionsQueryVariables
  > &
    (
      | { variables: UserActionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserActionsQuery, UserActionsQueryVariables>(
    UserActionsDocument,
    options,
  )
}
export function useUserActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserActionsQuery,
    UserActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserActionsQuery, UserActionsQueryVariables>(
    UserActionsDocument,
    options,
  )
}
export function useUserActionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserActionsQuery,
    UserActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UserActionsQuery, UserActionsQueryVariables>(
    UserActionsDocument,
    options,
  )
}
export type UserActionsQueryHookResult = ReturnType<typeof useUserActionsQuery>
export type UserActionsLazyQueryHookResult = ReturnType<
  typeof useUserActionsLazyQuery
>
export type UserActionsSuspenseQueryHookResult = ReturnType<
  typeof useUserActionsSuspenseQuery
>
export type UserActionsQueryResult = Apollo.QueryResult<
  UserActionsQuery,
  UserActionsQueryVariables
>
export const UserSchemesDocument = gql`
  query UserSchemes($userId: ID!) {
    user(user_id: $userId) {
      id
      user_schemes {
        scheme_id
        scheme {
          name
        }
      }
    }
  }
`

/**
 * __useUserSchemesQuery__
 *
 * To run a query within a React component, call `useUserSchemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSchemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSchemesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserSchemesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserSchemesQuery,
    UserSchemesQueryVariables
  > &
    (
      | { variables: UserSchemesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserSchemesQuery, UserSchemesQueryVariables>(
    UserSchemesDocument,
    options,
  )
}
export function useUserSchemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSchemesQuery,
    UserSchemesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserSchemesQuery, UserSchemesQueryVariables>(
    UserSchemesDocument,
    options,
  )
}
export function useUserSchemesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserSchemesQuery,
    UserSchemesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UserSchemesQuery, UserSchemesQueryVariables>(
    UserSchemesDocument,
    options,
  )
}
export type UserSchemesQueryHookResult = ReturnType<typeof useUserSchemesQuery>
export type UserSchemesLazyQueryHookResult = ReturnType<
  typeof useUserSchemesLazyQuery
>
export type UserSchemesSuspenseQueryHookResult = ReturnType<
  typeof useUserSchemesSuspenseQuery
>
export type UserSchemesQueryResult = Apollo.QueryResult<
  UserSchemesQuery,
  UserSchemesQueryVariables
>
export const BlockUserDocument = gql`
  mutation BlockUser($user_id: ID!) {
    block_user_identity_platform_account(user_id: $user_id)
  }
`
export type BlockUserMutationFn = Apollo.MutationFunction<
  BlockUserMutation,
  BlockUserMutationVariables
>

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useBlockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlockUserMutation,
    BlockUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(
    BlockUserDocument,
    options,
  )
}
export type BlockUserMutationHookResult = ReturnType<
  typeof useBlockUserMutation
>
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<
  BlockUserMutation,
  BlockUserMutationVariables
>
export const UnBlockUserDocument = gql`
  mutation UnBlockUser($user_id: ID!) {
    unblock_user_identity_platform_account(user_id: $user_id)
  }
`
export type UnBlockUserMutationFn = Apollo.MutationFunction<
  UnBlockUserMutation,
  UnBlockUserMutationVariables
>

/**
 * __useUnBlockUserMutation__
 *
 * To run a mutation, you first call `useUnBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unBlockUserMutation, { data, loading, error }] = useUnBlockUserMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUnBlockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnBlockUserMutation,
    UnBlockUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnBlockUserMutation, UnBlockUserMutationVariables>(
    UnBlockUserDocument,
    options,
  )
}
export type UnBlockUserMutationHookResult = ReturnType<
  typeof useUnBlockUserMutation
>
export type UnBlockUserMutationResult = Apollo.MutationResult<UnBlockUserMutation>
export type UnBlockUserMutationOptions = Apollo.BaseMutationOptions<
  UnBlockUserMutation,
  UnBlockUserMutationVariables
>
export const BlockUserCardFingerprintDocument = gql`
  mutation BlockUserCardFingerprint($user_id: ID!) {
    block_user_card_fingerprint(user_id: $user_id)
  }
`
export type BlockUserCardFingerprintMutationFn = Apollo.MutationFunction<
  BlockUserCardFingerprintMutation,
  BlockUserCardFingerprintMutationVariables
>

/**
 * __useBlockUserCardFingerprintMutation__
 *
 * To run a mutation, you first call `useBlockUserCardFingerprintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserCardFingerprintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserCardFingerprintMutation, { data, loading, error }] = useBlockUserCardFingerprintMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useBlockUserCardFingerprintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlockUserCardFingerprintMutation,
    BlockUserCardFingerprintMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BlockUserCardFingerprintMutation,
    BlockUserCardFingerprintMutationVariables
  >(BlockUserCardFingerprintDocument, options)
}
export type BlockUserCardFingerprintMutationHookResult = ReturnType<
  typeof useBlockUserCardFingerprintMutation
>
export type BlockUserCardFingerprintMutationResult = Apollo.MutationResult<BlockUserCardFingerprintMutation>
export type BlockUserCardFingerprintMutationOptions = Apollo.BaseMutationOptions<
  BlockUserCardFingerprintMutation,
  BlockUserCardFingerprintMutationVariables
>
export const UnBlockUserCardFingerptintDocument = gql`
  mutation UnBlockUserCardFingerptint($user_id: ID!) {
    unblock_user_card_fingerprint(user_id: $user_id)
  }
`
export type UnBlockUserCardFingerptintMutationFn = Apollo.MutationFunction<
  UnBlockUserCardFingerptintMutation,
  UnBlockUserCardFingerptintMutationVariables
>

/**
 * __useUnBlockUserCardFingerptintMutation__
 *
 * To run a mutation, you first call `useUnBlockUserCardFingerptintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnBlockUserCardFingerptintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unBlockUserCardFingerptintMutation, { data, loading, error }] = useUnBlockUserCardFingerptintMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUnBlockUserCardFingerptintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnBlockUserCardFingerptintMutation,
    UnBlockUserCardFingerptintMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnBlockUserCardFingerptintMutation,
    UnBlockUserCardFingerptintMutationVariables
  >(UnBlockUserCardFingerptintDocument, options)
}
export type UnBlockUserCardFingerptintMutationHookResult = ReturnType<
  typeof useUnBlockUserCardFingerptintMutation
>
export type UnBlockUserCardFingerptintMutationResult = Apollo.MutationResult<UnBlockUserCardFingerptintMutation>
export type UnBlockUserCardFingerptintMutationOptions = Apollo.BaseMutationOptions<
  UnBlockUserCardFingerptintMutation,
  UnBlockUserCardFingerptintMutationVariables
>
export const ForceAddUserPhoneNumberDocument = gql`
  mutation ForceAddUserPhoneNumber($userId: ID!, $phoneNumber: String!) {
    force_add_user_phone_number(user_id: $userId, phone_number: $phoneNumber)
  }
`
export type ForceAddUserPhoneNumberMutationFn = Apollo.MutationFunction<
  ForceAddUserPhoneNumberMutation,
  ForceAddUserPhoneNumberMutationVariables
>

/**
 * __useForceAddUserPhoneNumberMutation__
 *
 * To run a mutation, you first call `useForceAddUserPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceAddUserPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceAddUserPhoneNumberMutation, { data, loading, error }] = useForceAddUserPhoneNumberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useForceAddUserPhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForceAddUserPhoneNumberMutation,
    ForceAddUserPhoneNumberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ForceAddUserPhoneNumberMutation,
    ForceAddUserPhoneNumberMutationVariables
  >(ForceAddUserPhoneNumberDocument, options)
}
export type ForceAddUserPhoneNumberMutationHookResult = ReturnType<
  typeof useForceAddUserPhoneNumberMutation
>
export type ForceAddUserPhoneNumberMutationResult = Apollo.MutationResult<ForceAddUserPhoneNumberMutation>
export type ForceAddUserPhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  ForceAddUserPhoneNumberMutation,
  ForceAddUserPhoneNumberMutationVariables
>
export const GetAllVehiclesDocument = gql`
  query GetAllVehicles {
    vehicles(vehicle_type: scooter, paginate: { page: 1, per_page: 5000 }) {
      nodes {
        frame_id
      }
      pagination {
        total
        page
        pages
      }
    }
  }
`

/**
 * __useGetAllVehiclesQuery__
 *
 * To run a query within a React component, call `useGetAllVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllVehiclesQuery,
    GetAllVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllVehiclesQuery, GetAllVehiclesQueryVariables>(
    GetAllVehiclesDocument,
    options,
  )
}
export function useGetAllVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllVehiclesQuery,
    GetAllVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllVehiclesQuery, GetAllVehiclesQueryVariables>(
    GetAllVehiclesDocument,
    options,
  )
}
export function useGetAllVehiclesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllVehiclesQuery,
    GetAllVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetAllVehiclesQuery,
    GetAllVehiclesQueryVariables
  >(GetAllVehiclesDocument, options)
}
export type GetAllVehiclesQueryHookResult = ReturnType<
  typeof useGetAllVehiclesQuery
>
export type GetAllVehiclesLazyQueryHookResult = ReturnType<
  typeof useGetAllVehiclesLazyQuery
>
export type GetAllVehiclesSuspenseQueryHookResult = ReturnType<
  typeof useGetAllVehiclesSuspenseQuery
>
export type GetAllVehiclesQueryResult = Apollo.QueryResult<
  GetAllVehiclesQuery,
  GetAllVehiclesQueryVariables
>
export const ZonesMapDocument = gql`
  query ZonesMap($scheme_id: ID, $suspended: Boolean) {
    all_zones(
      scheme_id: $scheme_id
      is_suspended: $suspended
      paginate: { page: 1, per_page: 800 }
    ) {
      nodes {
        ...ZoneNode
      }
    }
  }
  ${ZoneNodeFragmentDoc}
`

/**
 * __useZonesMapQuery__
 *
 * To run a query within a React component, call `useZonesMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useZonesMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZonesMapQuery({
 *   variables: {
 *      scheme_id: // value for 'scheme_id'
 *      suspended: // value for 'suspended'
 *   },
 * });
 */
export function useZonesMapQuery(
  baseOptions?: Apollo.QueryHookOptions<ZonesMapQuery, ZonesMapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ZonesMapQuery, ZonesMapQueryVariables>(
    ZonesMapDocument,
    options,
  )
}
export function useZonesMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZonesMapQuery,
    ZonesMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ZonesMapQuery, ZonesMapQueryVariables>(
    ZonesMapDocument,
    options,
  )
}
export function useZonesMapSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ZonesMapQuery,
    ZonesMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ZonesMapQuery, ZonesMapQueryVariables>(
    ZonesMapDocument,
    options,
  )
}
export type ZonesMapQueryHookResult = ReturnType<typeof useZonesMapQuery>
export type ZonesMapLazyQueryHookResult = ReturnType<
  typeof useZonesMapLazyQuery
>
export type ZonesMapSuspenseQueryHookResult = ReturnType<
  typeof useZonesMapSuspenseQuery
>
export type ZonesMapQueryResult = Apollo.QueryResult<
  ZonesMapQuery,
  ZonesMapQueryVariables
>
