import { useState } from 'react'
import { gql, ApolloQueryResult } from '@apollo/client'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import {
  CloseOutlined,
  SearchOutlined,
  ThunderboltFilled,
} from '@ant-design/icons'
import { Input } from 'antd'
import { Card, Heading } from '@weareberyl/design-system'

import Query from 'components/Query'
import { colorByOnlineStatus, colorByFixInvalid, iconByFixInvalid } from 'utils'
import { isPhone } from 'utils/mobileDetect'
import Table from 'components/Table'
import { useQueryParams } from 'utils/useQueryParams'
import ModuleLink from 'components/ModuleLink'

import {
  SOAK_TEST_LIST,
  SOAK_TEST_LIST_table_nodes as SoakTestItem,
} from './__generated__/SOAK_TEST_LIST'
import HeadTitle from 'components/HeadTitle'

const SOAK_TEST_LIST_QUERY = gql`
  query SOAK_TEST_LIST(
    $paginate: Paginate
    $where: [ModuleWhere]
    $order_by: [ModuleOrderby]
    $is_mobile: Boolean!
  ) {
    table: modules(paginate: $paginate, where: $where, order_by: $order_by) {
      nodes {
        id
        unlock_id
        iot_state @skip(if: $is_mobile) {
          power_mode
          firmware_version
          id
        }
        telemetry @skip(if: $is_mobile) {
          id
          time
          battery_percent
          battery_voltage
          fix_invalid
        }
        connectivity {
          is_online
        }
        bike {
          vehicle_id
          scheme {
            id
          }
        }
      }
      pagination {
        current: page
        pageSize: per_page
        total
      }
    }
  }
`

const SearchText = ({ text }: { text: string }) => (
  <div style={{ marginBottom: 12 }}>Search text: "{text}"</div>
)

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    sortFunction: direction => ({ id: direction }),
    render: (module_id: string, { bike }) => (
      <ModuleLink id={module_id} bikeScheme={bike?.scheme} />
    ),
  },
  {
    title: 'Unlock ID',
    dataIndex: 'unlock_id',
    render: num => <span style={{ wordBreak: 'normal' }}>{num}</span>,
  },
  {
    title: 'Soak test',
    className: 'xs-hide',
    align: 'center',
    render: ({ telemetry }: SoakTestItem) => {
      const batteryCharged = telemetry?.battery_voltage || 0 > 3.8
      const fixValid = !(telemetry?.fix_invalid ?? true)

      if (fixValid) {
        if (batteryCharged) {
          return (
            <span style={{ wordBreak: 'normal', color: 'green' }}>PASS</span>
          )
        } else {
          return (
            <span style={{ wordBreak: 'normal', color: 'orange' }}>
              BATTERY {'<'} 3.8V
            </span>
          )
        }
      } else {
        return <span style={{ wordBreak: 'normal', color: 'red' }}>FAIL</span>
      }
    },
  },
  {
    title: 'Battery level',
    className: 'xs-hide',
    sorter: true,
    sortFunction: direction => ({
      telemetry: {
        battery_percent: direction,
      },
    }),
    render: ({ telemetry }: SoakTestItem) => {
      const batteryPercent = telemetry?.battery_percent

      if (batteryPercent) {
        return <span style={{ wordBreak: 'normal' }}>{batteryPercent}%</span>
      } else {
        return <span />
      }
    },
  },
  {
    title: 'Battery voltage',
    className: 'xs-hide',
    sorter: true,
    sortFunction: direction => ({
      telemetry: {
        battery_voltage: direction,
      },
    }),
    render: ({ telemetry }: SoakTestItem) => {
      const batteryVoltage = telemetry?.battery_voltage

      if (batteryVoltage) {
        return <span style={{ wordBreak: 'normal' }}>{batteryVoltage}V</span>
      } else {
        return <span />
      }
    },
  },
  {
    title: 'GNSS fix',
    className: 'xs-hide',
    dataIndex: ['telemetry', 'fix_invalid'],
    align: 'center',
    key: 'fix_invalid',
    render: fix_invalid => (
      <span>
        <LegacyIcon
          type={iconByFixInvalid(fix_invalid)}
          style={{
            fontSize: 22,
            color: colorByFixInvalid(fix_invalid),
          }}
        />
      </span>
    ),
  },
  {
    title: 'Connectivity',
    dataIndex: ['connectivity', 'is_online'],
    align: 'center',
    render: isOnline => (
      <span>
        <ThunderboltFilled
          style={{ fontSize: 22, color: colorByOnlineStatus(isOnline) }}
        />
      </span>
    ),
  },
  {
    title: 'Power mode',
    className: 'xs-hide',
    dataIndex: ['iot_state', 'power_mode'],
  },
  {
    title: 'Firmware',
    className: 'xs-hide',
    dataIndex: ['iot_state', 'firmware_version'],
  },
]

const id = 'soak-test-table'

const SoakTestList = () => {
  const [inputValue, setInputValue] = useState('')
  const [
    { current, pageSize, searchValue },
    setQueryParams,
  ] = useQueryParams(id, { searchValue: '' })

  const closeIcon = () => (
    <CloseOutlined
      onClick={() => {
        setInputValue('')
        setQueryParams({ searchValue: '' })
      }}
    />
  )

  return (
    <>
      <HeadTitle pageTitle="Soak Test" />

      <Card p={5}>
        <Heading mb={5} variant="callout">
          Soak test
        </Heading>

        <Input
          id="soak-test-search-input"
          placeholder="Search by unlock ID"
          onChange={e => setInputValue(e.currentTarget.value.trim())}
          onPressEnter={e =>
            setQueryParams({
              searchValue: e.currentTarget.value.trim(),
              current: 1,
            })
          }
          value={inputValue}
          prefix={<SearchOutlined />}
          suffix={inputValue && closeIcon()}
        />
        {searchValue && <SearchText text={searchValue} />}
        <Query
          query={SOAK_TEST_LIST_QUERY}
          variables={{
            // Pass empty list of filters if no search value
            where: searchValue
              ? [{ unlock_id: { like: `%${searchValue}%` } }]
              : [],
            is_mobile: isPhone(),
            paginate: {
              page: current,
              per_page: pageSize,
            },
          }}
          pollInterval={0}
          waitFor="data.table.nodes"
          opts={{ showRefetchLoader: true }}
        >
          {(props: ApolloQueryResult<SOAK_TEST_LIST>) => (
            <Table<ApolloQueryResult<SOAK_TEST_LIST>['data']['table']['nodes']>
              id={id}
              onChange={({ current, pageSize }) =>
                setQueryParams({ current, pageSize })
              }
              // @ts-ignore See Product/List/index
              columns={columns}
              {...props}
            />
          )}
        </Query>
      </Card>
    </>
  )
}

export default SoakTestList
