import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Flex, Box, Card, Heading } from '@weareberyl/design-system'
import SuspendButton from 'components/Zone/SuspendButton'
import TabTitle from 'components/shared/TabTitle'
import CardSection from 'components/shared/CardSection'
import {
  getPositionLinkFromCoordinates,
  capitalize,
  VehicleIcon,
  isPromotionalZoneScheme,
} from 'utils'
import { isDockedScheme } from 'utils'

import { ZONE_SUMMARY } from './__generated__/ZONE_SUMMARY'
import PromotionalButton from './PromotionalButton'
import ZoneNotes from './ZoneNotes'
import ZoneTypeStatus from './ZoneTypeStatus'
import { ZoneType } from '__generated__/globalTypes'
import Loading from 'components/Loading'
import { message } from 'antd'
import { getDerivedZone } from 'utils/derived'

const getHiringOrParkingString = (status: string | undefined) =>
  status ? capitalize(status.split('_')[0]) : undefined

const ZONE_SUMMARY_QUERY = gql`
  query ZONE_SUMMARY($id: ID!) {
    zone(zone_id: $id) {
      id
      name
      is_suspended
      is_promotional
      centre
      capacity
      notes
      hireable_modules_count
      hireable_bikes: hireable_modules_count(hardware_type: beryl_bike)
      hireable_ebikes: hireable_modules_count(hardware_type: bbe)
      hireable_scooters: hireable_modules_count(hardware_type: scooter)
      all_commissioned_modules_count
      bikes: all_commissioned_modules_count(hardware_type: beryl_bike)
      ebikes: all_commissioned_modules_count(hardware_type: bbe)
      scooters: all_commissioned_modules_count(hardware_type: scooter)
      parking_capacity_status
      hiring_capacity_status
      permitted_vehicles
      docked_vehicles_count
      zone_type
      scheme {
        id
        name
      }
    }
  }
`

const Summary = ({ id }) => {
  const { data, error, loading } = useQuery<ZONE_SUMMARY>(ZONE_SUMMARY_QUERY, {
    variables: { id },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    message.error(error.message)
    return null
  }

  const zone = getDerivedZone(data?.zone, data?.zone?.scheme?.id)
  const isSuspended = zone?.is_suspended
  const zoneType = zone?.zone_type ?? ZoneType.normal
  const isPromotional = zone?.is_promotional
  const showDockCount = zone?.scheme?.id && isDockedScheme(zone?.scheme?.id)
  const showPromotional =
    zone?.scheme?.id && isPromotionalZoneScheme(zone?.scheme?.id)
  const centreAndStatusList = [
    {
      key: 'Centre',
      value: getPositionLinkFromCoordinates(zone?.centre?.coordinates),
    },
    {
      key: 'Hiring status',
      value: getHiringOrParkingString(zone?.hiring_capacity_status),
    },
    {
      key: 'Parking status',
      value: getHiringOrParkingString(zone?.parking_capacity_status),
    },
  ]

  if (showPromotional) {
    centreAndStatusList.push({
      key: 'Promotional status',
      value: zone?.is_promotional ? 'Promotional' : 'Not Promotional',
    })
  }

  return (
    <Card id="zone-summary-card" mb={3} p={5}>
      <Flex
        justifyContent="space-between"
        alignItems="right"
        width="100%"
        mb={2}
      >
        <Box flex="1">
          <Flex flexDirection="row">
            <ZoneTypeStatus zoneType={zoneType} />
            <Heading variant="h1" pl={2}>
              {zone?.name}
            </Heading>
          </Flex>
        </Box>
        {showPromotional && isPromotional !== undefined && (
          <Box mr={2}>
            <PromotionalButton id={id} isPromotional={isPromotional} />
          </Box>
        )}
        {isSuspended !== undefined && (
          <SuspendButton id={id} isSuspended={isSuspended} />
        )}
      </Flex>
      <TabTitle
        tabText={isSuspended ? 'Suspended' : 'Enabled'}
        icon={isSuspended ? 'cross' : 'tick'}
      />
      <ZoneNotes zone={zone} />
      <Flex justifyContent="space-between" width="100%" mt={2}>
        <Box flex="1" mr={5}>
          <Flex>
            <CardSection
              title="Total vehicles"
              titleValue={zone?.all_commissioned_modules_count.toString()}
              items={[]}
            />
          </Flex>
          <Flex>
            <CardSection
              title="Capacity"
              titleValue={zone?.capacity.toString()}
              titleStyle={{ mt: 1 }}
              items={[]}
            />
          </Flex>
          {showDockCount && (
            <Flex>
              <CardSection
                title="Docked vehicles"
                titleValue={zone?.docked_vehicles_count.toString()}
                titleStyle={{ mt: 1 }}
                items={[]}
              />
            </Flex>
          )}
          <Flex>
            <CardSection
              title="Permitted vehicles"
              titleValue={
                <Flex>
                  {zone?.permitted_vehicles.map(vehicle => (
                    <VehicleIcon key={vehicle} vehicle={vehicle} size={21} />
                  ))}
                </Flex>
              }
              titleStyle={{ mt: 1 }}
              items={[]}
            />
          </Flex>
        </Box>
        <Box flex="1">
          <Flex>
            <CardSection
              title="Hireable"
              titleValue={zone?.hireable_modules_count.toString()}
              items={[
                { key: 'Bikes', value: zone?.hireable_bikes },
                { key: 'E-Bikes', value: zone?.hireable_ebikes },
                {
                  key: 'E-Scooters',
                  value: zone?.hireable_scooters,
                },
              ]}
            />
          </Flex>
        </Box>
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-end" width="100%">
        <Box flex="1" mr={5}>
          <Flex>
            <CardSection items={centreAndStatusList} />
          </Flex>
        </Box>
        <Box flex="1">
          <Flex mt={3}>
            <CardSection
              title="Not hireable"
              titleValue={(
                (zone?.all_commissioned_modules_count ?? 0) -
                (zone?.hireable_modules_count ?? 0)
              ).toString()}
              items={[
                {
                  key: 'Bikes',
                  value: (zone?.bikes ?? 0) - (zone?.hireable_bikes ?? 0),
                },
                {
                  key: 'E-Bikes',
                  value: (zone?.ebikes ?? 0) - (zone?.hireable_ebikes ?? 0),
                },
                {
                  key: 'E-Scooters',
                  value: (zone?.scooters ?? 0) - (zone?.hireable_scooters ?? 0),
                },
              ]}
            />
          </Flex>
        </Box>
      </Flex>
    </Card>
  )
}

export default Summary
