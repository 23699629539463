import { Fragment, useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { intersection, some } from 'lodash'
import { Tooltip } from 'antd'
import { Box, Card, Flex, Heading, Icon } from '@weareberyl/design-system'
import type { ColumnProps } from 'antd/lib/table'
import type { TableRowSelection } from 'antd/lib/table/interface'

import Table from 'components/Table'
import Query from 'components/Query'
import UserLink from 'components/UserLink'
import { EmptyComponent } from 'components/Query/utils'
import { formatDatetime } from 'utils'

import ClearFailedPayments from './ClearFailedPayments'
import InvoiceHeader from './InvoiceHeader'
import Refund from './Refund'
import ReverseUnlockedBikeFine from './ReverseUnlockedBikeFine'
import TransactionStatusLabel from './TransactionStatusLabel'
import { readableProviderType } from './formatters'
import {
  InvoiceDocument,
  InvoiceQuery,
  InvoiceQueryVariables,
  InvoiceQuery_invoice,
  InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection,
  InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes,
  TransactionCategory,
  TransactionMetaChargeType,
  TransactionResultType,
} from 'gql/generated/graphql'
import HeadTitle from 'components/HeadTitle'

type Transaction = InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes

const voidableCategories = [
  TransactionCategory.generic,
  TransactionCategory.discount,
]
const refundCategories = [
  TransactionCategory.refund,
  TransactionCategory.reversal,
]

type TransactionsNodes = InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection_nodes

type TransactionsData = InvoiceQuery_invoice_JourneyInvoice_transactions_TransactionConnection

type TransactionAction = 'void' | 'unvoid' | 'refund' | 'reverse'

const columns: ColumnProps<TransactionsNodes>[] = [
  {
    title: 'Date/Time',
    dataIndex: 'created_at',
    render: (timeString: string) => formatDatetime(timeString),
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
  {
    title: 'Status',
    dataIndex: 'result',
    render: (result: TransactionsNodes['result']) => {
      const status = result?.status
      return (
        <>
          {status && (
            <Tooltip title={result?.failure_message}>
              <Flex>
                <TransactionStatusLabel text={status} icon={status} />
              </Flex>
            </Tooltip>
          )}
        </>
      )
    },
  },
  {
    title: 'Category',
    dataIndex: 'category',
  },
  {
    title: 'Type',
    dataIndex: ['meta', 'transaction_label'],
  },
  {
    title: 'Provider',
    dataIndex: 'provider_type',
    render: readableProviderType,
  },
  {
    title: 'Debit',
    dataIndex: ['debit_amount', 'formatted_amount'],
  },
  {
    title: 'Credit',
    dataIndex: ['credit_amount', 'formatted_amount'],
  },
  {
    title: '',
    dataIndex: 'id',
    render: id => (
      <Tooltip title={id} placement="left">
        <Icon
          color="grape"
          height={12}
          resizeMode="contain"
          type="information"
          width={12}
          mt={1}
        />
      </Tooltip>
    ),
  },
]

const refundColumns: ColumnProps<TransactionsNodes>[] = [
  {
    title: 'Requester',
    dataIndex: 'refund_requester',
    render: user => <UserLink user={user} />,
  },
  {
    title: 'Date/Time',
    dataIndex: 'created_at',
    render: t => formatDatetime(t),
  },
  {
    title: 'Refund Description',
    dataIndex: ['meta', 'refund_description'],
  },
  {
    title: 'Failure Reason',
    dataIndex: ['meta', 'failure_reason'],
  },
  {
    title: 'Status',
    dataIndex: 'result',
    render: result => {
      const status = result?.status
      return (
        <>
          {status && (
            <Tooltip title={result?.failure_message}>
              <Flex>
                <TransactionStatusLabel text={status} icon={status} />
              </Flex>
            </Tooltip>
          )}
        </>
      )
    },
  },
  {
    title: 'Category',
    dataIndex: 'category',
  },
  {
    title: 'Credit',
    dataIndex: ['credit_amount', 'formatted_amount'],
  },
]

const isRefund = ({ category }: TransactionsNodes) =>
  refundCategories.includes(category)

const isTransactionRefundable = ({
  category,
  result,
  credit_amount,
  void: isVoid,
  amount_refundable,
}: TransactionsNodes) =>
  category === TransactionCategory.generic &&
  result?.status === TransactionResultType.succeeded &&
  credit_amount.amount === 0 &&
  !isVoid &&
  // this might not be here so only consider it if it is
  (amount_refundable?.amount ?? 1) > 0

const isTransactionFailed = ({ category, result, type }: Transaction) =>
  voidableCategories.includes(category) &&
  result?.status === TransactionResultType.failed &&
  type === 'currency_transaction'

const isTransactionVoidable = (transaction: Transaction) =>
  isTransactionFailed(transaction) && !transaction.void

const isTransactionUnVoidable = (transaction: Transaction) =>
  isTransactionFailed(transaction) && transaction.void

// Can the reverse cancelled journey penalty be run on this transaction?
const isReversableCancelJourneyPenalty = (transaction: Transaction) =>
  transaction.meta?.charge_type ===
    TransactionMetaChargeType.cancel_journey_penalty &&
  !transaction.void &&
  transaction.result?.status === TransactionResultType.succeeded

// Return a list of actions that can be performed on a transaction
const getTransactionActions = (invoice: InvoiceQuery_invoice) => (
  transaction: Transaction,
): TransactionAction[] => {
  // Can only refund if there is a refunable amount on the invoice
  const isCurrencyRefundable = (invoice.currency_refundable?.amount ?? 0) > 0
  const isMinuteRefundable =
    invoice.__typename === 'JourneyInvoice' &&
    (invoice.minute_refundable?.amount ?? 0) > 0

  const availableActions: TransactionAction[] = []
  if (isTransactionVoidable(transaction)) {
    availableActions.push('void')
  }
  if (isTransactionUnVoidable(transaction)) {
    availableActions.push('unvoid')
  }
  if (
    isTransactionRefundable(transaction) &&
    (isCurrencyRefundable || isMinuteRefundable)
  ) {
    availableActions.push('refund')
  }
  if (isReversableCancelJourneyPenalty(transaction)) {
    availableActions.push('reverse')
  }

  return availableActions
}

// If the transaction is eliglble for any actions, let it be selected
const getIsSelectable = (invoice: InvoiceQuery_invoice) => (
  transaction: Transaction,
) => {
  return getTransactionActions(invoice)(transaction).length > 0
}

type TransactionsProps = {
  transactions: TransactionsData
  setSelectedTransactionIds: React.Dispatch<React.SetStateAction<string[]>>
  selectedTransactionIds: string[]
  isSelectable: (transaction: Transaction) => boolean
}
const TransactionsTable = ({
  transactions,
  setSelectedTransactionIds,
  selectedTransactionIds,
  isSelectable,
}: TransactionsProps) => {
  const selectableIds = transactions.nodes
    .filter(isSelectable)
    .map(({ id }) => id)

  const onSelectChange = (newIds: string[]) => {
    const ids = intersection(newIds, selectableIds)
    setSelectedTransactionIds(ids)
  }

  const rowSelection: TableRowSelection<TransactionsNodes> = {
    selectedRowKeys: selectedTransactionIds,
    onChange: onSelectChange,
  }

  return (
    <Table
      // Use this to hide select-all as the property isn't supported in this version of antd
      className="hide-select-all"
      rowClassName={(record: TransactionsNodes) => {
        const isGenericCategory = record.category === 'generic'
        let className = isSelectable(record) ? '' : 'hide-select'
        if (record.void && isGenericCategory) {
          className += ' line-through'
        }
        return className
      }}
      columns={columns}
      data={{
        table: transactions,
      }}
      bordered={false}
      rowSelection={rowSelection}
      hideProgress
    />
  )
}

const RefundTransactions = ({
  transactions,
}: {
  transactions: TransactionsData
}) => {
  return (
    <Table
      rowClassName={(record: TransactionsNodes) =>
        record.void && record.category === 'generic' ? 'line-through' : ''
      }
      columns={refundColumns}
      data={{
        table: transactions,
      }}
      bordered={false}
      hideProgress
    />
  )
}

const Invoice = ({ id }: InvoiceQueryVariables) => {
  const [selectedTransactionIds, setSelectedTransactionIds] = useState<
    string[]
  >([])

  const hasSelected = selectedTransactionIds.length > 0
  return (
    <Query
      waitFor="data.invoice"
      pollInterval={0}
      query={InvoiceDocument}
      variables={{ id }}
    >
      {({ data }: ApolloQueryResult<InvoiceQuery>) => {
        const { invoice } = data
        if (!invoice) {
          return <EmptyComponent />
        }
        const { transactions } = invoice

        const refundTransactionsNodes: TransactionsNodes[] =
          transactions?.nodes.filter(isRefund) ?? []

        const failedTransactionIds: TransactionsNodes['id'][] =
          transactions?.nodes
            .filter(isTransactionVoidable)
            .map(transaction => transaction.id) ?? []

        const voidedTransactioIds: TransactionsNodes['id'][] =
          transactions?.nodes
            .filter(isTransactionUnVoidable)
            .map(transaction => transaction.id) ?? []

        const refundableInvoiceIds: TransactionsNodes['id'][] =
          transactions?.nodes
            .filter(isTransactionRefundable)
            .map(transaction => transaction.id) ?? []

        const selectedVoidableTransactionIds = intersection(
          failedTransactionIds,
          selectedTransactionIds,
        )
        const selectedUnVoidableTransactionIds = intersection(
          voidedTransactioIds,
          selectedTransactionIds,
        )

        const selectedRefundableTransactionIds = intersection(
          refundableInvoiceIds,
          selectedTransactionIds,
        )

        // List of actions for each selected transaction
        const actionsForSelectedTransactions = (
          transactions?.nodes.filter((transaction: Transaction) =>
            selectedTransactionIds.includes(transaction.id),
          ) ?? []
        ).map(getTransactionActions(invoice))

        // What bulk operations can be performed on ALL selected transactions?
        const availableBulkActions = intersection(
          ...actionsForSelectedTransactions,
        )

        // Check if any of the provided action or actions can be performed on all of the selected transactions
        // if not, then return false as the button shouldn't be enabled if incompatible transactions are selected
        const canActOnAllSelectedTransactions = (
          ...actions: TransactionAction[]
        ) => {
          return some(
            actions.map(action => availableBulkActions.includes(action)),
          )
        }

        // Get selectable checker function but give it invoice for the refund check
        const isSelectable = getIsSelectable(invoice)

        const isReversableCancelledJourney =
          invoice.__typename === 'JourneyInvoice' &&
          canActOnAllSelectedTransactions('reverse')

        return (
          <>
            <HeadTitle pageTitle={`Invoice ${invoice.id}`} />
            <Card p={6}>
              <InvoiceHeader invoice={invoice} />
              <Flex mb={3}>
                <Heading variant="h3">Transactions</Heading>
              </Flex>
              {transactions && (
                <>
                  <TransactionsTable
                    transactions={transactions}
                    selectedTransactionIds={selectedTransactionIds}
                    setSelectedTransactionIds={setSelectedTransactionIds}
                    isSelectable={isSelectable}
                  />

                  <Flex
                    height="56px"
                    mt={2}
                    alignItems="center"
                    key={selectedTransactionIds.join('-')} // This is here to force a re-render (to reset state) of the refund modals if the selection changes
                  >
                    {canActOnAllSelectedTransactions('void', 'unvoid') && (
                      <ClearFailedPayments
                        idsToVoid={selectedVoidableTransactionIds}
                        idsToUnvoid={selectedUnVoidableTransactionIds}
                        onComplete={() => {
                          setSelectedTransactionIds([])
                        }}
                      />
                    )}
                    {isReversableCancelledJourney ? (
                      <ReverseUnlockedBikeFine
                        invoice={invoice}
                        onComplete={() => {
                          setSelectedTransactionIds([])
                        }}
                      />
                    ) : (
                      canActOnAllSelectedTransactions('refund') && (
                        <Refund
                          invoice={invoice}
                          idsToRefund={selectedRefundableTransactionIds}
                          onComplete={() => {
                            setSelectedTransactionIds([])
                          }}
                        />
                      )
                    )}
                    <Box>
                      {hasSelected && (
                        <span>
                          {availableBulkActions.length > 0
                            ? `Selected ${selectedTransactionIds.length} transactions`
                            : `No bulk actions for all ${selectedTransactionIds.length} selected transactions`}
                        </span>
                      )}
                    </Box>
                  </Flex>
                </>
              )}
              {refundTransactionsNodes.length > 0 && (
                <Fragment>
                  <Flex mb={3} mt={5}>
                    <Heading variant="h3">Refund Summary</Heading>
                  </Flex>
                  <RefundTransactions
                    transactions={{
                      nodes: refundTransactionsNodes,
                      __typename: 'TransactionConnection',
                    }}
                  />
                </Fragment>
              )}
            </Card>
          </>
        )
      }}
    </Query>
  )
}

export default Invoice
