import { Alert, Button, Form, Input, Switch, message } from 'antd'
import { Flex, Heading } from '@weareberyl/design-system'

import ActionModal, { ActionModalProps } from './ActionModal'
import {
  UserSummaryDocument,
  useForceAddUserPhoneNumberMutation,
} from 'gql/generated/graphql'

const MSISDN_PATTERN = /^\+([1-9][0-9]{10,14})$/

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
}

const tailLayout = {
  wrapperCol: { offset: 7 },
}

type Props = ActionModalProps & { userId: string }
type FormValues = {
  phoneNumber: string
  verified: boolean
}

const PhoneNumberModal = ({ visible, close, userId }: Props) => {
  const [form] = Form.useForm()
  const [
    setPhoneNumber,
    { loading: saving, error },
  ] = useForceAddUserPhoneNumberMutation()

  const onFinish = async ({ phoneNumber }: FormValues) => {
    try {
      await setPhoneNumber({
        variables: { userId, phoneNumber },
        refetchQueries: [UserSummaryDocument],
      })
    } catch {
      // Error will be displayed by the hook value
      message.error('Number could not be added')
      return
    }
    message.info('Phone number added')
    close()
  }

  return (
    <ActionModal visible={visible} close={close}>
      <Flex pt={4} px={6} style={{ gap: '20px' }}>
        <Heading variant="callout" pt={2} pb={4}>
          Set phone number
        </Heading>
        {error && (
          <Alert
            type="error"
            message="Oh dear"
            description={error.message}
            showIcon
          />
        )}
        <Alert
          message="This is a workaround"
          type="warning"
          description={
            <>
              <p>
                Users should be able to add their phone number via the app or
                website. If they can't do that, you can set it here.
              </p>
              <p>
                <strong>
                  But please verify that they own the number first.
                </strong>
              </p>
            </>
          }
          showIcon
        />
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          layout="horizontal"
          name="phoneNumberForm"
          style={{ flex: 1 }}
        >
          <Form.Item
            name="phoneNumber"
            label="Phone number"
            rules={[
              { required: true, message: 'Missing phone number' },
              {
                pattern: MSISDN_PATTERN,
                message: 'Please use format: +447000000000',
              },
            ]}
          >
            <Input placeholder="+447000000000" />
          </Form.Item>

          <Form.Item
            name="verified"
            label="Verified"
            valuePropName="checked"
            extra="I have verified that the user owns this number"
            rules={[
              () => ({
                validator(_, value) {
                  if (value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error('Please verify that the user owns this number'),
                  )
                },
              }),
            ]}
          >
            <Switch />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={saving}>
              Set phone number
            </Button>
          </Form.Item>
        </Form>
      </Flex>
    </ActionModal>
  )
}

export default PhoneNumberModal
