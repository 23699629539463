import React from 'react'
import { gql, useQuery } from '@apollo/client'
import withSchemeServiceAreas, {
  SERVICE_AREAS_GEOJSON,
} from 'components/Scheme/withSchemeServiceAreas'
import Map, { ViewState } from 'components/Map'
import {
  ServiceAreaGeoJsonLayer,
  ModuleIconLayer,
  ZoneIconLayers,
  ZonePolygonLayer,
} from 'components/Map/layers'

import { SCHEME } from 'types'
import { ZONE_MAP } from './__generated__/ZONE_MAP'
import { LocationAreaInput } from '../../__generated__/globalTypes'
import Legend, { LegendProps } from 'components/Map/Legend'
import Loading from 'components/Loading'
import { message } from 'antd'
import { getDerivedModule, getDerivedZone } from 'utils/derived'

const ZONE_MAP_QUERY = gql`
  query ZONE_MAP(
    $id: ID!
    $zone_id: String!
    $hasLocation: Boolean!
    $location: LocationAreaInput
  ) {
    zone(zone_id: $id) {
      ...ZoneNode
      modules {
        ...ModulesNodes
      }
    }
    zones(location: $location, where: { id: { ne: $zone_id } })
      @include(if: $hasLocation) {
      nodes {
        ...ZoneNode
      }
    }
  }
  ${ModuleIconLayer.fragments.modules}
  ${ZoneIconLayers.fragments.zone}
`

type ZoneMapProps = {
  id: string
  scheme: SCHEME
  serviceAreas: SERVICE_AREAS_GEOJSON
}

type ZoneMapState = {
  bounds: LocationAreaInput | null
  legendProps: LegendProps | null
}

const initialState: ZoneMapState = {
  bounds: null,
  legendProps: null,
}

const ZoneMap = ({ id, serviceAreas, scheme }: ZoneMapProps) => {
  const [state, setState] = React.useState<ZoneMapState>(initialState)
  const _setState = (newState: Partial<ZoneMapState>) =>
    setState({ ...state, ...newState })

  const { legendProps, bounds } = state

  const { loading, error, data, previousData } = useQuery<ZONE_MAP>(
    ZONE_MAP_QUERY,
    {
      variables: {
        id,
        zone_id: id,
        hasLocation: bounds !== null,
        location: bounds,
      },
    },
  )

  if (loading && !previousData) {
    return <Loading />
  }

  if (error) {
    message.error(error.message)
    return null
  }

  const _onViewStateChange = (viewState: ViewState) => {
    _setState({
      bounds: {
        longitude_min: viewState.bounds._ne.lng,
        longitude_max: viewState.bounds._sw.lng,
        latitude_min: viewState.bounds._ne.lat,
        latitude_max: viewState.bounds._sw.lat,
      },
    })
  }

  const _setLegend = (legendProps: ZoneMapState['legendProps']) => {
    _setState({
      legendProps,
    })
  }

  const displayedData = data ?? previousData

  const currentZone = displayedData?.zone
  const modules = (displayedData?.zone?.modules?.nodes ?? []).map(
    getDerivedModule,
  )
  const zones = (displayedData?.zones?.nodes ?? []).map(z =>
    getDerivedZone(z, scheme.id),
  )

  return (
    <Map
      latitude={displayedData?.zone?.centre?.coordinates[1]}
      longitude={displayedData?.zone?.centre?.coordinates[0]}
      zoom={17}
      layers={[
        ServiceAreaGeoJsonLayer({
          serviceAreas,
          layerId: 'service-area-layer',
          layerProps: {
            onClick: ({ object: { properties } }) =>
              _setLegend({
                type: 'ServiceArea',
                service_area: properties,
              }),
          },
        }),
        ZonePolygonLayer({
          zones: currentZone ? [currentZone] : [],
          layerId: 'current-zone-polygon-layer',
          visible: true,
          visibleZoneId: id,
        }),
        ModuleIconLayer({
          modules,
          layerId: 'modules-icon-layer',
          layerProps: {
            getSize: 50,
            onClick: ({ object }) =>
              _setLegend({ type: 'Module', module_id: object.id }),
          },
        }),
        ...ZoneIconLayers({
          zones,
          layerId: 'zone-layer',
          iconLayerProps: {
            onClick: ({ object }) =>
              _setLegend({ type: 'Zone', zone_id: object.id }),
          },
        }),
        ZoneIconLayers({
          zones: currentZone ? [currentZone] : [],
          layerId: 'current-zone-layer',
          iconLayerProps: {
            onClick: ({ object }) =>
              _setLegend({ type: 'Zone', zone_id: object.id }),
          },
          scheme_id: scheme.id,
        }),
      ]}
      onViewStateChange={_onViewStateChange}
    >
      {legendProps && (
        <Legend onClose={() => _setLegend(null)} {...legendProps} />
      )}
    </Map>
  )
}

export default withSchemeServiceAreas(ZoneMap)
