import { Box, Button } from '@weareberyl/design-system'
import { message } from 'antd'
import { useSetZonePromotionalMutation } from 'components/Zone/utils'

type PromotionalButtonProps = {
  id: string
  isPromotional: boolean
}

const onError = () =>
  message.error(
    'Something went wrong when trying to change the promotional status',
  )

const PromotionalButton = ({ id, isPromotional }: PromotionalButtonProps) => {
  const [
    unsetZone,
    { loading: suspendLoading },
  ] = useSetZonePromotionalMutation({
    variables: { id, is_promotional: false },
    onCompleted: () => message.success('Bay set to not promotional'),
    onError,
    refetchQueries: ['ZONE_SUMMARY'],
  })

  const [setZone, { loading: enableLoading }] = useSetZonePromotionalMutation({
    variables: { id, is_promotional: true },
    onCompleted: () => message.success('Bay set to promotional'),
    onError,
    refetchQueries: ['ZONE_SUMMARY'],
  })

  return (
    <Box>
      <Button
        loading={suspendLoading || enableLoading}
        onPress={isPromotional ? unsetZone : setZone}
        title={isPromotional ? 'Unset Promotional' : 'Set Promotional'}
        variant={isPromotional ? 'primary' : 'outline'}
        width={170}
        testID="bay-promotional-btn"
      />
    </Box>
  )
}

export default PromotionalButton
