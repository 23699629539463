import React, { Fragment } from 'react'
import Denied from './Denied'
import withUser from './withUser'
import { withScheme } from 'components/Scheme'
import {
  IRole,
  isSuperAdmin,
  isSuperWorkshop,
  hasRoleForScheme,
  isSchemeStaff,
} from 'utils/firebase/auth'
import { SCHEME, CURRENT_USER } from 'types'
import { OFFICE_ROLE_TYPES } from 'constants/roles'

type ConditionFn = ({
  user,
  scheme,
  permission,
}: {
  user: CURRENT_USER
  scheme: SCHEME
  permission: string
}) => boolean

type PermissionHOC = React.SFC<any> & { Forbidden?: any }

const shield = (condition: ConditionFn) => ({
  user,
  scheme,
  permission,
  children,
  denied,
}) => {
  if (condition({ user, scheme, permission })) {
    return <Fragment>{children}</Fragment>
  } else {
    return denied || null
  }
}

const withForbidden = Permisssion => props => (
  <Permisssion {...props} denied={<Denied />} />
)

export const AuthOnly: PermissionHOC = withUser(shield(({ user }) => !!user.id))

AuthOnly.Forbidden = withForbidden(AuthOnly)

export const AdminOnly: PermissionHOC = withScheme(
  withUser(
    shield(({ user, scheme }) => {
      if (user.is_super_admin) {
        return true
      }

      if (
        user.scheme_admin &&
        user.scheme_admin.includes(parseInt(scheme.id))
      ) {
        return true
      }
      return false
    }),
  ),
)

AdminOnly.Forbidden = withForbidden(AdminOnly)

// skipcq JS-R1005
export const hasPermission = (
  roles: IRole[],
  permission: string,
  scheme_id: number,
) => {
  switch (permission) {
    case 'map.view':
    case 'bike.view':
    case 'zone.view':
    case 'zone.edit':
    case 'service_area.view':
    case 'service_area.edit':
    case 'journey.view':
    case 'job.view':
    case 'vehicle_curfew.view':
      return isSchemeStaff(roles, scheme_id)
    case 'vehicle_curfew.edit':
      return (
        isSuperAdmin(roles) ||
        hasRoleForScheme(roles, 'operations_lead', scheme_id) ||
        hasRoleForScheme(roles, 'admin', scheme_id)
      )

    case 'invoice.view':
    case 'user.view':
    case 'product.view':
      return (
        isSuperAdmin(roles) ||
        OFFICE_ROLE_TYPES.some(role => hasRoleForScheme(roles, role, scheme_id))
      )

    case 'journey.start':
      return (
        isSuperAdmin(roles) ||
        hasRoleForScheme(roles, 'operations_lead', scheme_id) ||
        hasRoleForScheme(roles, 'admin', scheme_id)
      )

    case 'module.actions':
      return (
        isSuperAdmin(roles) ||
        isSuperWorkshop(roles) ||
        (isSchemeStaff(roles, scheme_id) &&
          !hasRoleForScheme(roles, 'read_only', scheme_id))
      )

    case 'provision.view':
      return isSuperAdmin(roles) || hasRoleForScheme(roles, 'provisioner', null)

    case 'whitelist.view':
    case 'deployment.view':
      return isSuperAdmin(roles)

    case 'module.all':
    case 'job.all':
      return (
        isSuperAdmin(roles) ||
        hasRoleForScheme(roles, 'second_line_support', null)
      )

    case 'super_admin':
      return isSuperAdmin(roles)

    default:
      return false
  }
}

export const PermissionOnly: PermissionHOC = withScheme(
  withUser(
    shield(({ user, scheme, permission }) => {
      return hasPermission(user.roles, permission, parseInt(scheme.id))
    }),
  ),
)

PermissionOnly.Forbidden = withForbidden(PermissionOnly)

export const SuperAdminOnly: PermissionHOC = withUser(
  shield(({ user }) => user.is_super_admin),
)

SuperAdminOnly.Forbidden = withForbidden(SuperAdminOnly)

export { withUser }
