import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'

const assignJobsMutation = gql`
  mutation setZoneNotes($zone_id: ID!, $notes: String) {
    set_zone_notes(zone_id: $zone_id, notes: $notes) {
      id
    }
  }
`

const useZoneNotes = () => {
  return useMutation(assignJobsMutation, {
    refetchQueries: ['ZONE_SUMMARY'],
    errorPolicy: 'all',
  })
}

export default useZoneNotes
