import { Fragment, useState } from 'react'
import { withRouter } from 'react-router'
import Query from 'components/Query'
import { gql, ApolloQueryResult } from '@apollo/client'
import { Flex, Card, Heading, Button } from '@weareberyl/design-system'
import { message } from 'antd'
import { withScheme } from 'components/Scheme'
import Table from 'components/Table'
import { useQueryParams } from 'utils/useQueryParams'
import ZoneLink from 'components/ZoneLink'
import Search from 'components/Zone/Search'
import * as routes from 'constants/routes'
import TabTitle from 'components/shared/TabTitle'
import SuspendedStatus from 'components/Zone/SuspendedStatus'
import { EmptyComponent } from 'components/Query/utils'
import Tabs from 'components/shared/Tabs'
import { useBulkZoneStatusMutation } from 'components/Zone/utils'
import {
  ZONES_LIST,
  ZONES_LIST_table_nodes as ZONE,
  ZONES_LISTVariables,
} from './__generated__/ZONES_LIST'
import { VehicleIcon } from 'utils'
import ZoneTypeStatus from '../ZoneTypeStatus'
import HeadTitle from 'components/HeadTitle'

const { Tab } = Tabs
const id = 'zones-table'

const getColumns = (searchValue: string) => {
  const columns: any[] = [
    {
      title: 'Type',
      dataIndex: 'zone_type',
      render: zoneType => <ZoneTypeStatus zoneType={zoneType} />,
    },
    {
      title: 'Name',
      render: ({ id }) => <ZoneLink id={id} />,
    },
    {
      title: 'Hireable Vehicles',
      dataIndex: 'hireable_modules_count',
    },
    {
      title: 'Total Vehicles',
      dataIndex: 'all_commissioned_modules_count',
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
    },
    {
      title: 'Permitted vehicles',
      dataIndex: 'permitted_vehicles',
      render: permitted_vehicles => (
        <Flex>
          {permitted_vehicles.map(vehicle => (
            <VehicleIcon key={vehicle} vehicle={vehicle} size={21} />
          ))}
        </Flex>
      ),
    },
  ]

  if (searchValue) {
    columns.push({
      title: 'Status',
      dataIndex: 'is_suspended',
      render: isSuspended => <SuspendedStatus isSuspended={isSuspended} />,
    })
  }

  return columns
}

const ZONES_LIST_QUERY = gql`
  query ZONES_LIST(
    $scheme_id: ID
    $paginate: Paginate
    $where: [ZoneWhere]
    $is_suspended: Boolean
  ) {
    table: all_zones(
      scheme_id: $scheme_id
      paginate: $paginate
      where: $where
      is_suspended: $is_suspended
    ) {
      nodes {
        id
        name
        scheme {
          id
        }
        all_commissioned_modules_count
        hireable_modules_count
        capacity
        is_suspended
        permitted_vehicles
        zone_type
      }
      pagination {
        current: page
        pageSize: per_page
        total
      }
    }
    enabled_zones: all_zones(scheme_id: $scheme_id, is_suspended: false) {
      pagination {
        total
      }
    }
    suspended_zones: all_zones(scheme_id: $scheme_id, is_suspended: true) {
      pagination {
        total
      }
    }
  }
`

type ZoneTableProps = {
  searchValue: string
  onChange: (arg0: any) => void
  rowSelection?: {
    onChange: (selectedRowKeys: string[], zoneIds: ZONE[]) => void
    selectedRowKeys: string[]
  }
  props: ApolloQueryResult<ZONES_LIST>
}

const ZoneTable = ({
  searchValue,
  rowSelection,
  onChange,
  props,
}: ZoneTableProps) => {
  const isEmptyResult = (props.data.table?.nodes || []).length === 0

  if (isEmptyResult) {
    return <EmptyComponent />
  }

  return (
    <Table
      id={id}
      columns={getColumns(searchValue)}
      onChange={onChange}
      {...props}
      rowSelection={rowSelection ? { ...rowSelection } : undefined}
    />
  )
}

type tabOptions = 'suspended' | 'enabled'

const ZoneList = ({ scheme, history }) => {
  const [
    { currentTab, current, pageSize, searchValue },
    setQueryParams,
  ] = useQueryParams(id, {
    currentTab: 'enabled',
  })
  const viewingSuspended = currentTab === 'suspended'
  const [zoneIds, setZoneIds] = useState<string[]>([])

  const resetProperties = (
    currentTab: tabOptions = 'enabled',
    ids: string[] = [],
  ) => {
    setZoneIds(ids)
    setQueryParams({ currentTab })
  }

  const onSearch = (text: string) => {
    resetProperties('enabled')
    setQueryParams({ searchValue: text, current: 1 })
  }

  const onChangeSelection = (selectedRowKeys: string[], _) =>
    setZoneIds(selectedRowKeys)

  const [updateStatus, statusLoading] = useBulkZoneStatusMutation({
    suspend: viewingSuspended,
    zoneIds,
    onCompleted: (failedIds: string[]) => {
      if (failedIds.length === 1) {
        message.error(
          `Failed to update the status for bay with ID: '${failedIds[0]}'`,
        )
      } else if (failedIds.length !== 0) {
        message.error('Failed to update the status of multiple bays')
      } else {
        message.success('Bay statuses successfully updated')
      }

      resetProperties(currentTab, failedIds)
    },
    onError: err => message.error(err.message),
  })

  const variables: ZONES_LISTVariables = {
    scheme_id: scheme.id,
    where: searchValue ? [{ name: { like: `%${searchValue}%` } }] : [],
    paginate: {
      page: current,
      per_page: pageSize,
    },
  }

  if (!searchValue) {
    variables.is_suspended = viewingSuspended
  }

  const tableBorderProps = !viewingSuspended
    ? { borderTopLeftRadius: 0 }
    : { borderTopRightRadius: 0 }

  let suspendButtonTitle = 'Suspend'
  if (zoneIds.length !== 0) {
    suspendButtonTitle = `${viewingSuspended ? 'Enable' : 'Suspend'} (${
      zoneIds.length
    })`
  } else if (viewingSuspended) {
    suspendButtonTitle = 'Enable'
  }

  return (
    <>
      <HeadTitle pageTitle="Bays" />
      <Card p={5}>
        <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
          <Heading variant="callout">Bays</Heading>
          <Button
            title="Sync"
            onPress={() => {
              history.push(`/scheme/${scheme.id}${routes.ZONES_SYNC}`)
            }}
            variant="primary"
            width="260px"
          />
        </Flex>
        <Flex maxWidth="520px" mb={4}>
          <Search onSubmit={onSearch} />
        </Flex>
        <Query
          waitFor="data.table"
          pollInterval={0}
          variables={variables}
          query={ZONES_LIST_QUERY}
        >
          {(props: ApolloQueryResult<ZONES_LIST>) => {
            if (!searchValue) {
              return (
                <Card variant="gray" p={3}>
                  <Tabs>
                    <Tab
                      onPress={() => resetProperties('enabled')}
                      isSelected={!viewingSuspended}
                    >
                      <TabTitle tabText="Enabled" icon="tick" />
                      <Heading variant="callout">
                        {props.data?.enabled_zones.pagination.total || 0}
                      </Heading>
                    </Tab>
                    <Tab
                      onPress={() => resetProperties('suspended')}
                      isSelected={viewingSuspended}
                    >
                      <TabTitle tabText="Suspended" icon="cross" />
                      <Heading variant="callout">
                        {props.data?.suspended_zones.pagination.total || 0}
                      </Heading>
                    </Tab>
                  </Tabs>
                  <Card variant="borderless" {...tableBorderProps}>
                    <Button
                      title={suspendButtonTitle}
                      onPress={updateStatus}
                      loading={statusLoading}
                      variant={viewingSuspended ? 'primary' : 'outline'}
                      width="148px"
                      mt={2}
                    />
                    <ZoneTable
                      searchValue={searchValue}
                      rowSelection={{
                        selectedRowKeys: zoneIds,
                        onChange: onChangeSelection,
                      }}
                      onChange={({ current, pageSize }) =>
                        setQueryParams({ current, pageSize })
                      }
                      props={props}
                    />
                  </Card>
                </Card>
              )
            }

            return (
              <Fragment>
                <Heading variant="h1" my={2}>
                  Search results for: {searchValue}
                </Heading>
                <ZoneTable
                  searchValue={searchValue}
                  onChange={({ current, pageSize }) =>
                    setQueryParams({ current, pageSize })
                  }
                  props={props}
                />
              </Fragment>
            )
          }}
        </Query>
      </Card>
    </>
  )
}

export default withRouter(withScheme(ZoneList))
