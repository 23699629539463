import React from 'react'
import { Box, Button } from '@weareberyl/design-system'
import { message } from 'antd'
import {
  useEnableZoneMutation,
  useSuspendZoneMutation,
} from 'components/Zone/utils'

type SuspendButtonProps = {
  id: string
  isSuspended: boolean
}

const onError = () =>
  message.error(
    'Something went wrong when trying to change the suspended status',
  )

const SuspendButton = ({ id, isSuspended }: SuspendButtonProps) => {
  const [suspendZone, { loading: suspendLoading }] = useSuspendZoneMutation({
    variables: { id },
    onCompleted: () => message.success('Bay suspended'),
    onError,
    refetchQueries: ['ZONE_SUMMARY'],
  })

  const [enableZone, { loading: enableLoading }] = useEnableZoneMutation({
    variables: { id },
    onCompleted: () => message.success('Bay enabled'),
    onError,
    refetchQueries: ['ZONE_SUMMARY'],
  })

  return (
    <Box>
      <Button
        loading={suspendLoading || enableLoading}
        onPress={isSuspended ? enableZone : suspendZone}
        title={isSuspended ? 'Enable' : 'Suspend'}
        variant={isSuspended ? 'primary' : 'outline'}
        width={136}
        testID="bay-suspend-btn"
      />
    </Box>
  )
}

export default SuspendButton
