import { Box, Flex, Text, Touchable, colors } from '@weareberyl/design-system'
import { EditOutlined } from '@ant-design/icons'
import { Input, Modal, message } from 'antd'
import useZoneNotes from 'hooks/useZoneNotes'
import { useEffect, useState } from 'react'
import { ZONE_SUMMARY_zone } from './__generated__/ZONE_SUMMARY'
import { CURRENT_USER } from 'types'
import { SCHEME_PICKER_QUERY_schemes_nodes } from 'components/Scheme/__generated__/SCHEME_PICKER_QUERY'
import { hasRoleForScheme, isSuperAdmin } from 'utils/firebase/auth'
import { withUser } from 'components/Session'

interface IZoneNotes {
  zone: ZONE_SUMMARY_zone | null
}

const hasPermissionsToAddZoneNotes = (
  user?: CURRENT_USER,
  scheme?: SCHEME_PICKER_QUERY_schemes_nodes,
) => {
  if (!user || !scheme) return false
  const roles = user.roles
  const scheme_id = parseInt(scheme.id)
  return (
    isSuperAdmin(roles) ||
    hasRoleForScheme(roles, 'admin', scheme_id) ||
    hasRoleForScheme(roles, 'operations_lead', scheme_id) ||
    hasRoleForScheme(roles, 'second_line_support', scheme_id)
  )
}

const ZoneNotes: React.FC<IZoneNotes> = withUser(
  ({ zone, user }: IZoneNotes & { user: CURRENT_USER }) => {
    const [setZoneNotes] = useZoneNotes()
    const initNotes = zone?.notes ?? ''

    const [editing, setEditing] = useState(false)
    const [newNotes, setNewNotes] = useState(initNotes)
    const [error, setError] = useState<string | null>(null)

    const pendingChanges = newNotes !== initNotes
    const canEdit = hasPermissionsToAddZoneNotes(
      user,
      zone?.scheme as SCHEME_PICKER_QUERY_schemes_nodes,
    )

    useEffect(() => setNewNotes(initNotes), [initNotes])
    useEffect(() => {
      if (!error) return
      message.error(error)
      setError(null)
    }, [error])

    const _setNotes = async () => {
      if (!zone) return
      try {
        const { errors } = await setZoneNotes({
          variables: { zone_id: zone.id, notes: newNotes.trim() },
        })
        if (errors) {
          throw new Error(errors[0].message)
        }
      } catch (e) {
        setError(e.message)
      }

      setNewNotes('')
      setEditing(false)
    }

    const cancel = () => {
      setEditing(false)
    }

    const setNotes = () => {
      if (!pendingChanges) {
        cancel()
        return
      }
      Modal.confirm({
        title: 'Are you sure you want to update zone notes?',
        content: (
          <Text variant="small">
            {initNotes ? `Old notes: "${initNotes}"\n` : ''}
            {`New notes: "${newNotes}"`}
          </Text>
        ),
        onOk: _setNotes,
        onCancel: () => setEditing(true),
      })
    }

    if (editing) {
      return (
        <Flex alignItems="top" justifyContent="flex-start" flexDirection="row">
          <Input.TextArea
            placeholder="Notes"
            value={newNotes}
            onChange={e => setNewNotes(e.target.value)}
            onBlur={cancel}
            onPressEnter={setNotes}
            autoFocus
            autoSize
            allowClear
            maxLength={400}
            style={{ color: colors.grape }}
          />
          {pendingChanges && (
            <Text variant="small" color={colors.alert}>
              Press enter to save changes
            </Text>
          )}
        </Flex>
      )
    }

    const color = zone?.notes ? colors.grape : colors.berylGreen

    if (!initNotes && !canEdit) return null

    return (
      <Box mt={2} alignItems="flex-start" justifyContent="center">
        <Touchable
          onPress={() => setEditing(true)}
          style={{ width: '100%' }}
          disabled={!canEdit}
        >
          <Text color={color} autoSize>
            {initNotes || 'Add notes'}
            {canEdit && (
              <Box ml={1}>
                <EditOutlined style={{ height: '12px', color }} />
              </Box>
            )}
          </Text>
        </Touchable>
      </Box>
    )
  },
)

export default ZoneNotes
