import { auth, googleAuthProvider } from './firebase'
import history from 'client/history'
import * as routes from 'constants/routes'
import * as storageKeys from 'constants/localStorage'
import { CURRENT_USER_ROLES } from 'types'
import { STAFF_ROLE_TYPES } from 'constants/roles'
import { isNotNullOrUndefined } from '../types'

// This is the role type of the jwt not the API
export type IRole = CURRENT_USER_ROLES

export const sendSignInLinkToEmail = (email: string): Promise<void> => {
  window.localStorage.setItem(storageKeys.MAGIC_LINK_EMAIL_KEY, email)
  return auth.sendSignInLinkToEmail(email, {
    url: window.location.href,
    handleCodeInApp: true,
  })
}

export const signInWithEmailAndPassword = (
  email: string,
  password: string,
): Promise<firebase.auth.UserCredential> => {
  return auth.signInWithEmailAndPassword(email, password)
}

export const fetchSignInMethodsForEmail = (
  email: string,
): Promise<Array<string>> => {
  return auth.fetchSignInMethodsForEmail(email)
}

export const signInWithGoogleUsingPopup = (): Promise<firebase.auth.UserCredential> => {
  return auth.signInWithPopup(googleAuthProvider)
}

export const getMagicLinkEmail = () =>
  window.localStorage.getItem(storageKeys.MAGIC_LINK_EMAIL_KEY)

export const isMagicLink = (url: string): Promise<boolean> => {
  if (auth.isSignInWithEmailLink(url)) {
    const email = window.localStorage.getItem(storageKeys.MAGIC_LINK_EMAIL_KEY)
    if (!email) {
      throw new Error('MagicLinkLocalStorageError')
    }
    // The client SDK will parse the code from the link for you.
    return auth.signInWithEmailLink(email, url).then(_result => {
      window.localStorage.removeItem(storageKeys.MAGIC_LINK_EMAIL_KEY)
      return true
    })
  }
  return Promise.resolve(false)
}

// Sign out
export const doSignOut = () => {
  auth.signOut()
  Object.keys(storageKeys)
    .map(k => storageKeys[k])
    .forEach(val => {
      localStorage.removeItem(val)
    })
  history.push(routes.SIGN_IN)
}

export type CustomClaims = {
  user_id?: string | null
  email?: string | null
  roles?: IRole[]
  org_schemes?: { [key: string]: number[] }
}

export const customClaims = async (): Promise<CustomClaims> => {
  const emptyClaims = {}
  if (!auth.currentUser) {
    return emptyClaims
  }
  try {
    const token = await auth.currentUser.getIdToken()
    const payload = JSON.parse(atob(token.split('.')[1]))
    const claimsWithSchemeRoles = expandOrgRoles(payload)
    return claimsWithSchemeRoles as CustomClaims
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.error('Error parsing custom claims', err)
    return emptyClaims
  }
}

export const isSuperAdmin = (roles: IRole[]) => {
  if (roles?.find) {
    const superAdmin = !!roles.find(r => r.role === 'super_admin')
    return superAdmin
  } else {
    return false
  }
}

export const isSuperWorkshop = (roles: IRole[]) => {
  if (roles?.find) {
    const superWorkshop = !!roles.find(r => r.role === 'super_workshop')
    return superWorkshop
  } else {
    return false
  }
}

export const getSchemesForRole = (
  roles: IRole[],
  roleType: string,
): number[] => {
  // Return an array of schemes for which the user is of the specified role
  if (roles?.filter) {
    return roles
      .filter(role => role.role === roleType)
      .map(role => role.scheme_id)
      .filter(isNotNullOrUndefined)
  } else {
    return []
  }
}
export const hasRoleForScheme = (
  roles: IRole[],
  roleType: string,
  scheme_id: number | null,
): boolean => {
  // Return true if the user has the specified role for the scheme
  if (roles?.filter) {
    return (
      roles
        .filter(role => role.role === roleType)
        .filter(role => {
          // Handle the case where the scheme_id on the role hasn't been defined, and we're matching
          // against a null scheme_id
          if (!role.scheme_id && !scheme_id) {
            return true
          } else {
            return role.scheme_id === scheme_id
          }
        }).length > 0
    )
  } else {
    return false
  }
}

const getRoleTypesByScheme = (roles: IRole[], scheme_id: number): string[] => {
  // Return true if the user has the specified role for the scheme
  if (roles?.filter) {
    return roles
      .filter(role => role.scheme_id === scheme_id)
      .map(role => role.role)
  } else {
    return []
  }
}

export const isSchemeStaff = (roles: IRole[], scheme_id: number) => {
  const roleTypes = getRoleTypesByScheme(roles, scheme_id)

  return (
    isSuperAdmin(roles) ||
    isSuperWorkshop(roles) ||
    roleTypes.some(roleType => STAFF_ROLE_TYPES.includes(roleType))
  )
}

export const expandOrgRoles = (claims: CustomClaims) => {
  if (!claims.org_schemes || !claims.roles) {
    return claims
  }

  const expandedRoles = claims.roles.flatMap((role: IRole) => {
    const schemes = (role.org_id && claims.org_schemes?.[role.org_id]) || []

    const roles: IRole[] = schemes.map((schemeId: number) => ({
      role: role.role,
      scheme_id: schemeId,
      org_id: null,
    }))

    return roles
  })

  claims.roles = [...claims.roles, ...expandedRoles]
  return claims
}
